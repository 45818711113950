import { Component, Input, OnInit, inject } from '@angular/core';
import { IconName } from '@san/tools/models';
import { Utilisateur } from './../../../models/entity/Utilisateur';
import { Medecin } from './../../../models/entity/medecin';
import { RdvStep } from './../../../models/enum/rdv.enum';
import { NavigationService } from './../../../services/navigation.service';

@Component({
  selector: 'rdv-user-resume',
  templateUrl: './user-resume.component.html',
  styleUrls: ['./user-resume.component.scss']
})
export class UserResumeComponent implements OnInit {

  private navigationService = inject(NavigationService);

  @Input() pro: Utilisateur;
  @Input() showRdvButton: boolean = false;
  IconName = IconName;
  medecin: Medecin;

  ngOnInit(): void {
    this.medecin = { ...this.pro.medecin, utilisateur: { ...this.pro, medecin: null } };
  }

  openProfile() {
    this.navigationService.openInNewTab(`/home/doctors/${this.medecin?.id}`);
  }

  openBooking() {
    this.navigationService.openBooking(this.medecin, null, RdvStep.DATE);
  }
}
