import { inject } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { CanActivateFn } from '@angular/router';
import { filter, tap } from 'rxjs';
import { NavigationService } from '@shared/services/navigation.service';
import { UserService } from '@shared/services/user.service';
import { ApplicationService } from '@shared/services/application.service';
import { SsrService } from '@shared/services/ssr.service';

export const CanActivateAccountRoute: CanActivateFn = () => {
  const navigationService = inject(NavigationService);
  const userService = inject(UserService);
  const appService = inject(ApplicationService);
  const ssrService = inject(SsrService);
  if (ssrService.isBrowser()) {
    return toObservable(appService.initialized).pipe(
      filter((state) => state),
      tap(() => {
        if (!userService.connectedUser()) {
          return true;
        } else {
          navigationService.navigate(userService.dashboardLink());
          return false;
        }
      })
    );
  }
  return false;
};
