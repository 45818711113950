import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  inject,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { IconName } from '@san/tools/models';
import { SanButtonIconComponent } from '@san/tools/ui/button-icon';
import { BaseDialogComponent } from '@san/tools/ui/dialog';
import { SanInformationComponent } from '@san/tools/ui/information';
import { SanInputComponent } from '@san/tools/ui/input';
import { SanModalComponent } from '@san/tools/ui/modal';
import { filter, take } from 'rxjs';
import { CryptoService } from '@shared/services/crypto.service';
import { UserService } from '@shared/services/user.service';

@Component({
  selector: 'rdv-change-email',
  templateUrl: './change-email.component.html',
  styleUrls: ['./change-email.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    SanModalComponent,
    FormsModule,
    ReactiveFormsModule,
    SanButtonIconComponent,
    SanInformationComponent,
    SanInputComponent,
  ],
})
export class ChangeEmailComponent
  extends BaseDialogComponent
  implements OnInit
{
  private readonly userService = inject(UserService);

  protected readonly IconName: typeof IconName = IconName;
  protected emailForm: FormGroup;
  protected user = this.userService.connected();

  ngOnInit(): void {
    this.emailForm = new FormGroup({
      email: new FormControl(null, Validators.required),
      password: new FormControl(null, Validators.required),
    });
  }

  protected validate() {
    const { email, password } = this.emailForm.getRawValue();
    if (this.user.email !== email) {
      this.userService
        .updateEmail(this.userService.connectedUserType(), {
          email,
          password: CryptoService.toBase64(password),
          id: this.user.id,
        })
        .pipe(
          take(1),
          filter((resp) => resp.data === true)
        )
        .subscribe(() => {
          this.userService.notificationService.success(
            'core.user.message-change-mail-success',
            null,
            { disableTimeOut: true }
          );
          this.close({ content: true });
        });
    } else {
      this.userService.notificationService.warning(
        'core.user.message-change-mail-same'
      );
    }
  }
}
