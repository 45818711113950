<san-modal header="core.user.change-email-title" (closeModal)="close()">
  <div content>
    <form [formGroup]="emailForm">
      <p>
        <san-information message="core.user.change-email-desc"></san-information>
      </p>
      <san-input type="email" label="core.user.new-email" placeholder="core.user.new-email-pl" formControlName="email"
        [maxLength]="100" idControl="email-email">
      </san-input>
      <san-input type="password" label="core.user.mot-de-passe" placeholder="core.user.mot-de-passe-pl" [maxLength]="20"
        formControlName="password" idControl="email-password">
      </san-input>
    </form>
  </div>
  <div fxLayout="row" fxLayoutAlign="end center" footer>
    <san-button-icon color="warn" [icon]="IconName.CLOSE_CIRCLE" idControl="cancel-email" label="shared.annuler"
      (onclick)="close()"></san-button-icon>
    <san-button-icon [icon]="IconName.CHECK_CIRCLE" idControl="validate-email" [disabled]="emailForm.invalid"
      label="shared.valider" (onclick)="validate()"></san-button-icon>
  </div>
</san-modal>
