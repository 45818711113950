import { AfterViewInit, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IconName, ListModel } from '@san/tools/models';
import { BaseComponent } from '@san/tools/utils';
import * as _ from 'lodash';
import { PubliqueService } from './../../../api/publique.service';
import { Utilisateur } from './../../../models/entity/Utilisateur';
import { Couverture } from './../../../models/entity/couverture';
import { Diplome } from './../../../models/entity/diplome';
import { Moyen } from './../../../models/entity/moyen';
import { Prestation } from './../../../models/entity/prestation';
import { Specialite } from './../../../models/entity/specialite';
import { ReactiveService } from './../../../services/reactive.service';
import { UserService } from './../../../services/user.service';
import { ObjectUtility } from './../../../utils/object.utils';

@Component({
  selector: 'rdv-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent extends BaseComponent implements AfterViewInit {

  IconName = IconName;
  pro: Utilisateur;
  prestations: ListModel<string>[] = [];
  specialites: ListModel<number>[] = [];
  moyens: ListModel<number>[] = [];
  couvertures: ListModel<number>[] = [];
  diplomes: Diplome[] = [];
  isMedecin = this.userService.proIsConnected;

  constructor(private publiqueService: PubliqueService, private userService: UserService,
    private route: ActivatedRoute, public reactiveService: ReactiveService) {
    super();
  }

  ngAfterViewInit(): void {
    const id = this.route.snapshot.paramMap.get('id');
    if (id?.length) {
      this.reactiveService.call(this.publiqueService.findMedecin(id), res => {
        this.pro = res.data;
        this.prestations = this.getListItems(this.pro.etablissement.prestations);
        this.diplomes = this.pro.medecin?.diplomes;
        this.specialites = this.getListItems(this.pro.medecin.specialites);
        this.moyens = this.getListItems(this.pro.etablissement.moyens);
        this.couvertures = this.getListItems(this.pro.etablissement.couvertures);
      }, false);
    }

  }

  getListItems(items: (Specialite | Prestation | Moyen | Couverture)[]): ListModel<never>[] {
    return items?.map(it => ({
      label: _.has(it, 'tarif') ? `${it.libelle} - ${ObjectUtility.formatPrice(Number(_.get(it, 'tarif')))}` : it.libelle,
      value: it.id,
      data: it
    } as ListModel<never>));
  }
}
