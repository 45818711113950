import { Injectable } from '@angular/core';
import { NavigationExtras } from '@angular/router';
import { SanNavigationService } from '@san/tools/services';
import { Etablissement } from '../models/entity/etablissement';
import { Medecin } from '../models/entity/medecin';
import { Rdv } from '../models/entity/rdv';
import { RdvStep } from '../models/enum/rdv.enum';
import { AccountType } from '../models/enum/user-shared.enum';
@Injectable({
  providedIn: 'root',
})
export class NavigationService extends SanNavigationService {

  static getUserRouteExtra(accountType: AccountType): NavigationExtras {
    return { state: { accountType } };
  }

  get currentRoute() {
    return this.route;
  }

  get currentRouter() {
    return this.router;
  }

  getExtraData(key: string, defaultValue: unknown = null) {
    const state = this.currentRouter.getCurrentNavigation()?.extras?.state;
    return state && state[key] ? state[key] : defaultValue;
  }

  getAccountTypeFromUrl(): AccountType {
    const url = this.currentRouter.url.toLowerCase();
    if (url.includes(`/${AccountType.PRO.toLowerCase()}/`)) {
      return AccountType.PRO;
    } else if (url.includes(`/${AccountType.PATIENT.toLowerCase()}/`)) {
      return AccountType.PATIENT;
    }
    return null;
  }

  openInNewTab(url: string, extras?: NavigationExtras) {
    url = this.router.serializeUrl(this.router.createUrlTree([url], extras));
    window.open(url, '_blank');
  }

  openBooking(medecin: Medecin, etablissement: Etablissement = null, step: RdvStep = RdvStep.RAISON, request?: Partial<Rdv>, date?: Date) {
    this.navigate(`/home/rendez-vous/booking`, {
      state: { medecin, etablissement, date, step, request }
    });
  }

  openMedecinProfile(id: string) {
    this.openInNewTab(`/home/doctors/${id}`);
  }

}
