<div *ngIf="user" fxLayout="row wrap" fxLayoutAlign="start start" fxLayoutGap="16px">
  <div>
    <rdv-photo [user]="user" [isPatient]="false" [editable]="editable"></rdv-photo>
    <div class="info small-padding">
      <div><label>{{'account.name' | translate}}</label> : <span>{{user.nom | displayValue}}</span></div>
      <div><label>{{'account.firstname' | translate}}</label> : <span>{{user.prenoms | displayValue}}</span></div>
      <div><label>{{'account.sexe' | translate}}</label> : <span>{{user.sexe | genre}}</span></div>
      @if (editable) {
      <div><label>{{'account.email' | translate}}</label> : <span>{{user.email | displayValue}}</span></div>
      <div><label>{{'account.date-naissance' | translate}}</label> :
        <span>{{user.date_naissance | displayDate}}</span>
      </div>
      <div><label>{{'account.lieu-naissance' | translate}}</label> :
        <span>{{user.lieu_naissance | displayValue}}</span>
      </div>
      <div><label>{{'account.portable' | translate}}</label> : <span>{{user.portable | displayValue}}</span></div>
      <div><label>{{'account.telephone' | translate}}</label> : <span>{{user.telephone | displayValue}}</span>
      </div>
      }
      <div><label>{{'account.city' | translate}}</label> : <span>{{user.ville?.libelle | displayValue}}</span></div>
      <div><label>{{'account.commune' | translate}}</label> : <span>{{user.commune?.libelle | displayValue}}</span>
      </div>
      @if (editable) {
      <div> <label>{{'account.adresse' | translate}}</label> : <span> {{user.adresse | displayValue}}</span> </div>
      }
    </div>
  </div>
</div>
