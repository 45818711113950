import { Component, Injector, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IconName } from '@san/tools/models';
import { BaseDialogComponent } from '@san/tools/ui/dialog';
import { CommentParam } from '../../models/interfaces/comment.interface';
import { CommentType } from './../../models/interfaces/comment.interface';

@Component({
  selector: 'rdv-comment-dialog',
  templateUrl: './comment-dialog.component.html',
  styleUrls: ['./comment-dialog.component.scss']
})
export class CommentDialogComponent extends BaseDialogComponent implements OnInit {

  param: CommentParam;
  form: FormGroup;
  IconName = IconName;
  CommentType = CommentType;

  constructor(protected override injector: Injector) {
    super(injector);
    this.param = this.data.content;
  }

  ngOnInit(): void {
    this.form = new FormGroup({ 'comment': new FormControl('') });
    if (this.param.type === CommentType.REQUIRED) {
      this.commentControl.addValidators(Validators.required);
    }
  }

  get commentControl() {
    return this.form.get('comment');
  }

  get maxlength() {
    return this.param?.maxlength ?? 500;
  }

  get title() {
    return this.param?.titleKey ?? 'shared.comment';
  }

  get label() {
    return this.param?.labelKey ?? 'shared.comment';
  }

  get validationLabel() {
    return this.param?.validationLabelKey ?? 'shared.valider';
  }

  validate() {
    this.close({ content: this.commentControl.value });
  }

}
