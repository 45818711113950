<san-modal header="menu.support" (closeModal)="close()">
  <div content>
    <san-tabs>
      <san-tab label="core.about.contact" [icon]="IconName.EMAIL">
        <div content>
          <p>
            {{ 'core.user.email' | translate }} :
            <a
              href="mailto:{{ appConf.help.email }}"
              class="text-accent"
              target="_blank"
            >
              {{ appConf.help.email }}
            </a>
          </p>
          <p>
            {{ 'core.user.portable' | translate }} :
            <a
              href="tel:{{ appConf.help.phone }}"
              class="text-accent"
              target="_blank"
            >
              {{ appConf.help.phone }}
            </a>
          </p>
          <p>
            {{ 'core.user.telephone' | translate }} :
            <a
              href="tel:{{ appConf.help.contact }}"
              class="text-accent"
              target="_blank"
            >
              {{ appConf.help.contact }}
            </a>
          </p>
          <!--p>
            {{ 'core.user.messagerie' | translate }} :
            <a
              href="tel:{{ appConf.help.messagerie }}"
              class="text-accent"
              target="_blank"
            >
              {{ appConf.help.messagerie }}
            </a>
          </p-->
          <p>
            {{ 'shared.website' | translate }} :
            <a
              [href]="'https://' + appConf.help.web"
              class="text-accent"
              target="_blank"
            >
              {{ appConf.help.web }}
            </a>
          </p>
        </div>
      </san-tab>
      <san-tab label="core.about.legal" [icon]="IconName.BALANCE">
        <div content>
          <p>
            {{ 'shared.website' | translate }} :
            <a [href]="appConf.help.web" class="text-accent" target="_blank">
              {{ appConf.help.web }}
            </a>
          </p>
        </div>
      </san-tab>
      <san-tab label="core.about.apropos.title" [icon]="IconName.INFO">
        <div content>
          <div [innerHTML]="'core.about.apropos.desc' | translate"></div>
          <div
            class="contenu"
            fxLayout="column"
            fxLayoutAlign="start start"
            fxLayoutGap="3px"
          >
            <a
              *ngFor="let lib of libs"
              [href]="lib.repository"
              class="text-info"
              target="_blank"
            >
              {{ lib.fullName }}, {{ lib.publisher }} -
              {{ lib.licenses }}
            </a>
          </div>
        </div>
      </san-tab>
    </san-tabs>
  </div>
  <div fxLayout="row" fxLayoutAlign="end center" footer>
    <san-button
      color="warn"
      label="shared.fermer"
      (onclick)="close()"
    ></san-button>
  </div>
</san-modal>
