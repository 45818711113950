<san-bloc header="rdv.filter.titre" [retractable]="true" [icon]="IconName.SEARCH">
  <div content>
    <form [formGroup]="filterForm">
      <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap.gt-xs="5px">
        <san-select fxFlex.lt-sm="100%" label="shared.search-period.label" [options]="periods"
          idControl="stats-filter-period" name="stats-period" [manageObject]="false"
          formControlName="period"></san-select>

        <san-date fxFlex.lt-sm="100%" idControl="stats-filter-debut" label="rdv.filter.debut"
          formControlName="debut"></san-date>

        <san-date fxFlex.lt-sm="100%" idControl="stats-filter-fin" label="rdv.filter.fin"
          formControlName="fin"></san-date>

        <san-autocomplete idControl="stats-filter-medecin" label="rdv.medecin" placeholder="rdv.medecin-pl"
          formControlName="medecin" [clearable]="true" [nbResult]="20" [filterFn]="filterMedecins"
          [manageObject]="false"></san-autocomplete>

        <san-autocomplete idControl="stats-filter-patient" label="rdv.patient" placeholder="rdv.patient-pl"
          formControlName="patient" [clearable]="true" [nbResult]="20" [filterFn]="filterPatients"
          [manageObject]="false"></san-autocomplete>
      </div>
    </form>
  </div>
</san-bloc>