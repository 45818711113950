<san-modal [header]="'menu.support' | translate" (closeModal)="close()">
  <div content>
    <san-tabs>
      <san-tab label="{{'core.about.contact' | translate}}" [icon]="IconName.EMAIL">
        <div content>
          {{'core.about.contact' | translate}}
        </div>
      </san-tab>
      <san-tab label="{{'core.about.legal' | translate}}" [icon]="IconName.BALANCE">
        <div content>
          {{'core.about.legal' | translate}}
        </div>
      </san-tab>
      <san-tab label="{{'core.about.apropos' | translate}}" [icon]="IconName.INFO">
        <div content>
          {{'core.about.apropos' | translate}}
        </div>
      </san-tab>
    </san-tabs>
  </div>
  <div fxLayout="row" fxLayoutAlign="end center" footer>
    <san-button color="warn" [label]="'shared.fermer' | translate" (onclick)="close()"></san-button>
  </div>
</san-modal>
