import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { SearchParam } from '@san/tools/models';
import { Observable } from 'rxjs';
import { ApiResponse } from '../models/dto/response.dto';
import { Medecin } from '../models/entity/medecin';
import { Etat } from '../models/enum/state.enum';
import { FileDataResponse } from '../models/interfaces/file.interface';
import { EmailRequest, NotificationRequest, PasswordRequest } from "../models/interfaces/user.interfaces";
import { Utilisateur } from './../models/entity/Utilisateur';
import { ApiBaseService } from './api-base.service';

@Injectable({
  providedIn: 'root'
})
export class ProService extends ApiBaseService {

  constructor(override http: HttpClient) {
    super(http, 'users');
  }

  create(pro: Utilisateur): Observable<ApiResponse<Utilisateur>> {
    return this.http.post<ApiResponse<Utilisateur>>(this.baseUrl, pro);
  }

  /**
   * Modifie les infos d'un pro
   * @param pro
   * @returns
   */
  edit(pro: Utilisateur): Observable<ApiResponse<Utilisateur>> {
    return this.http.put<ApiResponse<Utilisateur>>(this.baseUrl, pro);
  }

  /**
   * Renvoie un pro
   * @param id
   * @param relations
   * @returns
   */
  get(id: string, relations?: string[]): Observable<ApiResponse<Utilisateur>> {
    return this.http.post<ApiResponse<Utilisateur>>(`${this.baseUrl}/find`, { id, relations });
  }

  /**
   * Pagine des pro
   * @param param
   * @returns
   */
  paginate(param?: SearchParam): Observable<ApiResponse<Utilisateur[]>> {
    return this.http.post<ApiResponse<Utilisateur[]>>(`${this.baseUrl}/list/paginate`, param);
  }

  /**
   * Recherche des pro
   * @param param
   * @returns
   */
  search(param?: SearchParam): Observable<ApiResponse<Utilisateur[]>> {
    return this.http.post<ApiResponse<Utilisateur[]>>(`${this.baseUrl}/list/full`, param);
  }

  /**
   * Modifie le mot de passe d'un pro
   * @param request
   * @returns
   */
  updatePassword(request: PasswordRequest): Observable<ApiResponse<boolean>> {
    return this.http.post<ApiResponse<boolean>>(`${this.baseUrl}/update-password`, request);
  }

  updateNotificationSettings(request: NotificationRequest): Observable<ApiResponse<boolean>> {
    return this.http.post<ApiResponse<boolean>>(`${this.baseUrl}/update-notification-settings`, request);
  }

  /**
   * Modifie le mail d'un pro
   * @param request
   * @returns
   */
  updateEmail(request: EmailRequest): Observable<ApiResponse<boolean>> {
    return this.http.post<ApiResponse<boolean>>(`${this.baseUrl}/update-email`, request);
  }

  sendVerificationMail(id: string): Observable<ApiResponse<boolean>> {
    return this.http.post<ApiResponse<boolean>>(`${this.baseUrl}/verification-mail`, { id });
  }

  /**
   * Change la photo de profil du pro
   * @param data
   * @returns
   */
  updatePhoto(data: FormData): Observable<ApiResponse<FileDataResponse>> {
    return this.http.post<ApiResponse<FileDataResponse>>(`${this.baseUrl}/update-photo`, data);
  }

  deletePhoto(id: string): Observable<ApiResponse<boolean>> {
    return this.http.delete<ApiResponse<boolean>>(`${this.baseUrl}/delete-photo/${id}`);
  }

  toggle(id: string, actif: boolean): Observable<ApiResponse<boolean>> {
    const state = actif ? Etat.ACTIF : Etat.INACTIF;
    return this.http.post<ApiResponse<boolean>>(`${this.baseUrl}/toggle/${id}/${state}`, null);
  }

  createMedecin(id: string): Observable<ApiResponse<Medecin>> {
    return this.http.post<ApiResponse<Medecin>>(`${this.baseUrl}/create-medecin`, { id });
  }

  toggleMedecin(id: string, actif: boolean): Observable<ApiResponse<boolean>> {
    const state = actif ? Etat.ACTIF : Etat.INACTIF;
    return this.http.post<ApiResponse<boolean>>(`${this.baseUrl}/medecin/toggle/${id}/${state}`, null);
  }
}
