import { Component, Injector, inject } from '@angular/core';
import { IconName } from '@san/tools/models';
import { BaseDialogComponent } from '@san/tools/ui/dialog';
import { Patient } from './../../../models/entity/patient';
import { UserService } from './../../../services/user.service';

@Component({
  selector: 'rdv-patient-detail',
  templateUrl: './patient-detail.component.html',
  styleUrls: ['./patient-detail.component.scss']
})
export class PatientDetailComponent extends BaseDialogComponent {

  private userService = inject(UserService);

  patient: Patient;
  IconName = IconName;

  constructor(protected override injector: Injector) {
    super(injector);
    this.patient = this.data?.content;
  }

  get editable() {
    return this.userService.proIsConnected;
  }
}
