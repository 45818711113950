import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { IconName } from '@san/tools/models';
import { BaseComponent } from '@san/tools/utils';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs';
import { SearchUserParam } from './../../../models/interfaces/search.interface';

@Component({
  selector: 'rdv-user-filter',
  templateUrl: './user-filter.component.html',
  styleUrls: ['./user-filter.component.scss']
})
export class UserFilterComponent extends BaseComponent implements OnInit {

  searchForm: FormGroup;
  IconName = IconName;
  today: Date = new Date();
  @Output() searching: EventEmitter<SearchUserParam> = new EventEmitter<SearchUserParam>();

  ngOnInit(): void {
    this.searchForm = new FormGroup({
      numero: new FormControl(),
      nom_complet: new FormControl(),
      date_naissance: new FormControl()
    });

    this.searchForm.valueChanges.pipe(debounceTime(1000), distinctUntilChanged(), takeUntil(this.destroy)).subscribe(() => this.search());
    this.search();
  }

  search() {
    this.searching.next(this.searchForm.getRawValue());
  }
}
