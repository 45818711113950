import { inject, Pipe, PipeTransform } from "@angular/core";
import { Utilisateur } from '../models/entity/Utilisateur';
import { Patient } from '../models/entity/patient';
import { TraductorService } from "../services/traductor.service";
import { UserService } from "../services/user.service";

@Pipe({ name: 'userIsConnected' })
export class UserIsConnectedPipe implements PipeTransform {

  private userService = inject(UserService);

  transform(): boolean {
    return !!this.userService.connectedUser;
  }
}

@Pipe({ name: 'medecinIsConnected' })
export class MedecinIsConnectedPipe implements PipeTransform {

  private userService = inject(UserService);

  transform(): boolean {
    return this.userService.medecinIsConnected;
  }
}

@Pipe({ name: 'patientIsConnected' })
export class PatientIsConnectedPipe implements PipeTransform {

  private userService = inject(UserService);

  transform(): boolean {
    return this.userService.patientIsConnected;
  }
}

@Pipe({ name: 'genre' })
export class GenrePipe implements PipeTransform {

  private traductor = inject(TraductorService);

  transform(sexe: string): string {
    return sexe ? this.traductor.translate(`core.user.${sexe}`) : '-';
  }

}

@Pipe({ name: 'fullName' })
export class FullNamePipe implements PipeTransform {

  transform(user: Utilisateur | Patient): string {
    return UserService.getFullName(user);
  }
}
