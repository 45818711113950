import { Component, inject } from '@angular/core';
import { IconName, MenuItem } from '@san/tools/models';
import { delay, filter, take } from 'rxjs';
import { ChangeEmailComponent } from '../change-email/change-email.component';
import { ChangePasswordComponent } from '../change-password/change-password.component';
import { NotificationSettingsComponent } from '../notification-settings/notification-settings.component';
import { CommonService } from './../../../shared/api/common.service';
import { AccountType } from './../../../shared/models/enum/user-shared.enum';
import { DialogService } from './../../../shared/services/dialog.service';
import { LoaderService } from './../../../shared/services/loader.service';
import { MenuService } from './../../../shared/services/menu.service';
import { NavigationService } from './../../../shared/services/navigation.service';
import { UserService } from './../../../shared/services/user.service';

@Component({
  selector: 'rdv-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {

  public commonService = inject(CommonService);
  public userService = inject(UserService);
  private readonly dialogService = inject(DialogService);
  private readonly loaderService = inject(LoaderService);
  private readonly navigationService = inject(NavigationService);

  menu: MenuItem[] = inject(MenuService).menu();
  IconName: typeof IconName = IconName;
  AccountType: typeof AccountType = AccountType;
  showMenu: boolean = false;

  profileMenu: MenuItem[] = [
    { label: 'menu.profil.changerMail', click: () => this.openEmailDialog() },
    { label: 'menu.profil.changerPass', click: () => this.openPasswordDialog() },
    { label: 'menu.profil.parametres-notif', click: () => this.openConfigDialog() },
    { label: 'menu.profil.deconnexion', click: () => this.logout() }
  ];

  openPasswordDialog() {
    this.dialogService.open(ChangePasswordComponent, { disableClose: true });
  }

  openEmailDialog() {
    this.dialogService.open(ChangeEmailComponent, { disableClose: true }).
      closed.pipe(filter(res => res?.content === true))
      .subscribe(() => this.logout());
  }

  openConfigDialog() {
    this.dialogService.open(NotificationSettingsComponent);
  }

  logout() {
    this.loaderService.toggleMainLoader.next(true);
    this.commonService.logout().pipe(delay(1000), take(1)).subscribe(() => {
      this.userService.logout();
      this.loaderService.toggleMainLoader.next(false);
      this.navigationService.goHome();
    });
  }

  login(type: AccountType = AccountType.PRO) {
    this.navigationService.navigate(`/account/${type}/login`, NavigationService.getUserRouteExtra(type));
  }
}
