import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiResponse } from '../models/dto/response.dto';
import { LibelleEntity } from '../models/entity/libelle.entity';
import { ApiBaseService } from './api-base.service';

@Injectable({
  providedIn: 'root'
})
export class CommonService extends ApiBaseService {

  constructor() {
    super('commons');
  }

  /**
   * Create
   * @param entity
   * @param request
   * @returns
   */
  create<T>(entity: string, request: LibelleEntity): Observable<ApiResponse<T>> {
    return this.http.post<ApiResponse<T>>(`${this.baseUrl}/create-simple?repo=${entity}`, request);
  }

  /**
  * Edit
  * @param entity
  * @param request
  * @returns
  */
  edit<T>(entity: string, request: LibelleEntity): Observable<ApiResponse<T>> {
    return this.http.post<ApiResponse<T>>(`${this.baseUrl}/edit-simple?repo=${entity}`, request);
  }

  /**
  * Disconnect from app
  */
  logout(): Observable<ApiResponse<boolean>> {
    return this.http.post<ApiResponse<boolean>>(`${this.baseUrl}/logout`, null);
  }

}
