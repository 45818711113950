import { Component, inject } from '@angular/core';
import { Utilisateur } from '../../models/entity/Utilisateur';
import { Patient } from '../../models/entity/patient';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'rdv-user-account',
  templateUrl: './user-account.component.html',
  styleUrls: ['./user-account.component.scss']
})
export class UserAccountComponent {

  private userService = inject(UserService);

  get user(): Utilisateur | Patient {
    return this.userService.connected;
  }

  isPro(user: Utilisateur | Patient): user is Utilisateur {
    return !!(user as Utilisateur)?.medecin?.id;
  }

  get isEntityManager() {
    return this.userService.isEntityManager;
  }

}
