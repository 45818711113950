{
  "@angular/animations@19.2.2": {
    "licenses": "MIT",
    "repository": "https://github.com/angular/angular",
    "publisher": "angular",
    "path": "/builds/santyteam/santyrdv/node_modules/@angular/animations",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/@angular/animations/LICENSE"
  },
  "@angular/cdk@19.1.3": {
    "licenses": "MIT",
    "repository": "https://github.com/angular/components",
    "path": "/builds/santyteam/santyrdv/node_modules/@angular/cdk",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/@angular/cdk/LICENSE"
  },
  "@angular/common@19.2.2": {
    "licenses": "MIT",
    "repository": "https://github.com/angular/angular",
    "publisher": "angular",
    "path": "/builds/santyteam/santyrdv/node_modules/@angular/common",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/@angular/common/LICENSE"
  },
  "@angular/compiler@19.2.2": {
    "licenses": "MIT",
    "repository": "https://github.com/angular/angular",
    "publisher": "angular",
    "path": "/builds/santyteam/santyrdv/node_modules/@angular/compiler",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/@angular/compiler/LICENSE"
  },
  "@angular/core@19.2.2": {
    "licenses": "MIT",
    "repository": "https://github.com/angular/angular",
    "publisher": "angular",
    "path": "/builds/santyteam/santyrdv/node_modules/@angular/core",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/@angular/core/LICENSE"
  },
  "@angular/forms@19.2.2": {
    "licenses": "MIT",
    "repository": "https://github.com/angular/angular",
    "publisher": "angular",
    "path": "/builds/santyteam/santyrdv/node_modules/@angular/forms",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/@angular/forms/LICENSE"
  },
  "@angular/material@19.1.3": {
    "licenses": "MIT",
    "repository": "https://github.com/angular/components",
    "path": "/builds/santyteam/santyrdv/node_modules/@angular/material",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/@angular/material/LICENSE"
  },
  "@angular/platform-browser-dynamic@19.2.2": {
    "licenses": "MIT",
    "repository": "https://github.com/angular/angular",
    "publisher": "angular",
    "path": "/builds/santyteam/santyrdv/node_modules/@angular/platform-browser-dynamic",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/@angular/platform-browser-dynamic/LICENSE"
  },
  "@angular/platform-browser@19.2.2": {
    "licenses": "MIT",
    "repository": "https://github.com/angular/angular",
    "publisher": "angular",
    "path": "/builds/santyteam/santyrdv/node_modules/@angular/platform-browser",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/@angular/platform-browser/LICENSE"
  },
  "@angular/platform-server@19.2.2": {
    "licenses": "MIT",
    "repository": "https://github.com/angular/angular",
    "publisher": "angular",
    "path": "/builds/santyteam/santyrdv/node_modules/@angular/platform-server",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/@angular/platform-server/LICENSE"
  },
  "@angular/router@19.2.2": {
    "licenses": "MIT",
    "repository": "https://github.com/angular/angular",
    "publisher": "angular",
    "path": "/builds/santyteam/santyrdv/node_modules/@angular/router",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/@angular/router/LICENSE"
  },
  "@angular/service-worker@19.2.2": {
    "licenses": "MIT",
    "repository": "https://github.com/angular/angular",
    "publisher": "angular",
    "path": "/builds/santyteam/santyrdv/node_modules/@angular/service-worker",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/@angular/service-worker/LICENSE"
  },
  "@angular/ssr@19.2.3": {
    "licenses": "MIT",
    "repository": "https://github.com/angular/angular-cli",
    "path": "/builds/santyteam/santyrdv/node_modules/@angular/ssr",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/@angular/ssr/LICENSE"
  },
  "@fortawesome/fontawesome-free@6.7.2": {
    "licenses": "(CC-BY-4.0 AND OFL-1.1 AND MIT)",
    "repository": "https://github.com/FortAwesome/Font-Awesome",
    "publisher": "The Font Awesome Team",
    "url": "https://github.com/orgs/FortAwesome/people",
    "path": "/builds/santyteam/santyrdv/node_modules/@fortawesome/fontawesome-free",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/@fortawesome/fontawesome-free/LICENSE.txt"
  },
  "@ngbracket/ngx-layout@19.0.0": {
    "licenses": "MIT",
    "repository": "https://github.com/ngbracket/ngx-layout",
    "path": "/builds/santyteam/santyrdv/node_modules/@ngbracket/ngx-layout",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/@ngbracket/ngx-layout/README.md"
  },
  "@ngx-loading-bar/core@7.0.0": {
    "licenses": "MIT",
    "repository": "https://github.com/aitboudad/ngx-loading-bar",
    "path": "/builds/santyteam/santyrdv/node_modules/@ngx-loading-bar/core",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/@ngx-loading-bar/core/README.md"
  },
  "@ngx-loading-bar/http-client@7.0.0": {
    "licenses": "MIT",
    "repository": "https://github.com/aitboudad/ngx-loading-bar",
    "path": "/builds/santyteam/santyrdv/node_modules/@ngx-loading-bar/http-client",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/@ngx-loading-bar/http-client/README.md"
  },
  "@ngx-translate/core@16.0.4": {
    "licenses": "MIT",
    "publisher": "Andreas Löw / CodeAndWeb GmbH, Olivier Combe",
    "path": "/builds/santyteam/santyrdv/node_modules/@ngx-translate/core",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/@ngx-translate/core/LICENSE"
  },
  "@ngx-translate/http-loader@16.0.1": {
    "licenses": "MIT",
    "publisher": "Andreas Löw / CodeAndWeb GmbH, Olivier Combe",
    "path": "/builds/santyteam/santyrdv/node_modules/@ngx-translate/http-loader",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/@ngx-translate/http-loader/LICENSE"
  },
  "@san/tools@0.0.0-live": {
    "licenses": "UNKNOWN",
    "repository": "git+https://gitlab.com/santyteam/san-tools",
    "path": "/builds/santyteam/santyrdv/node_modules/@san/tools"
  },
  "@swimlane/ngx-charts@22.0.0-alpha.0": {
    "licenses": "MIT",
    "repository": "https://github.com/swimlane/ngx-charts",
    "publisher": "Swimlane",
    "path": "/builds/santyteam/santyrdv/node_modules/@swimlane/ngx-charts"
  },
  "@types/tinycolor2@1.4.6": {
    "licenses": "MIT",
    "repository": "https://github.com/DefinitelyTyped/DefinitelyTyped",
    "path": "/builds/santyteam/santyrdv/node_modules/@types/tinycolor2",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/@types/tinycolor2/LICENSE"
  },
  "@types/trusted-types@2.0.7": {
    "licenses": "MIT",
    "repository": "https://github.com/DefinitelyTyped/DefinitelyTyped",
    "path": "/builds/santyteam/santyrdv/node_modules/@types/trusted-types",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/@types/trusted-types/LICENSE"
  },
  "abab@1.0.4": {
    "licenses": "ISC",
    "repository": "https://github.com/jsdom/abab",
    "publisher": "Jeff Carpenter",
    "email": "gcarpenterv@gmail.com",
    "path": "/builds/santyteam/santyrdv/node_modules/abab",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/abab/LICENSE.md"
  },
  "accepts@1.3.8": {
    "licenses": "MIT",
    "repository": "https://github.com/jshttp/accepts",
    "path": "/builds/santyteam/santyrdv/node_modules/accepts",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/accepts/LICENSE"
  },
  "acorn-globals@1.0.9": {
    "licenses": "MIT",
    "repository": "https://github.com/ForbesLindesay/acorn-globals",
    "publisher": "ForbesLindesay",
    "path": "/builds/santyteam/santyrdv/node_modules/acorn-globals",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/acorn-globals/LICENSE"
  },
  "acorn@2.7.0": {
    "licenses": "MIT",
    "repository": "https://github.com/ternjs/acorn",
    "path": "/builds/santyteam/santyrdv/node_modules/jsdom/node_modules/acorn",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/jsdom/node_modules/acorn/LICENSE"
  },
  "ajv@6.12.6": {
    "licenses": "MIT",
    "repository": "https://github.com/ajv-validator/ajv",
    "publisher": "Evgeny Poberezkin",
    "path": "/builds/santyteam/santyrdv/node_modules/har-validator/node_modules/ajv",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/har-validator/node_modules/ajv/LICENSE"
  },
  "array-equal@1.0.2": {
    "licenses": "MIT",
    "repository": "https://github.com/sindresorhus/array-equal",
    "publisher": "Jonathan Ong",
    "email": "me@jongleberry.com",
    "url": "http://jongleberry.com",
    "path": "/builds/santyteam/santyrdv/node_modules/array-equal",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/array-equal/LICENSE"
  },
  "array-flatten@1.1.1": {
    "licenses": "MIT",
    "repository": "https://github.com/blakeembrey/array-flatten",
    "publisher": "Blake Embrey",
    "email": "hello@blakeembrey.com",
    "url": "http://blakeembrey.me",
    "path": "/builds/santyteam/santyrdv/node_modules/array-flatten",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/array-flatten/LICENSE"
  },
  "asn1@0.2.6": {
    "licenses": "MIT",
    "repository": "https://github.com/joyent/node-asn1",
    "publisher": "Joyent",
    "url": "joyent.com",
    "path": "/builds/santyteam/santyrdv/node_modules/asn1",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/asn1/LICENSE"
  },
  "assert-plus@1.0.0": {
    "licenses": "MIT",
    "repository": "https://github.com/mcavage/node-assert-plus",
    "publisher": "Mark Cavage",
    "email": "mcavage@gmail.com",
    "path": "/builds/santyteam/santyrdv/node_modules/assert-plus",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/assert-plus/README.md"
  },
  "asynckit@0.4.0": {
    "licenses": "MIT",
    "repository": "https://github.com/alexindigo/asynckit",
    "publisher": "Alex Indigo",
    "email": "iam@alexindigo.com",
    "path": "/builds/santyteam/santyrdv/node_modules/asynckit",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/asynckit/LICENSE"
  },
  "aws-sign2@0.7.0": {
    "licenses": "Apache-2.0",
    "repository": "https://github.com/mikeal/aws-sign",
    "publisher": "Mikeal Rogers",
    "email": "mikeal.rogers@gmail.com",
    "url": "http://www.futurealoof.com",
    "path": "/builds/santyteam/santyrdv/node_modules/aws-sign2",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/aws-sign2/LICENSE"
  },
  "aws4@1.13.2": {
    "licenses": "MIT",
    "repository": "https://github.com/mhart/aws4",
    "publisher": "Michael Hart",
    "email": "michael.hart.au@gmail.com",
    "url": "https://github.com/mhart",
    "path": "/builds/santyteam/santyrdv/node_modules/aws4",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/aws4/LICENSE"
  },
  "base64-arraybuffer@0.1.5": {
    "licenses": "MIT",
    "repository": "https://github.com/niklasvh/base64-arraybuffer",
    "publisher": "Niklas von Hertzen",
    "email": "niklasvh@gmail.com",
    "url": "http://hertzen.com",
    "path": "/builds/santyteam/santyrdv/node_modules/base64-arraybuffer",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/base64-arraybuffer/LICENSE-MIT"
  },
  "base64-js@1.5.1": {
    "licenses": "MIT",
    "repository": "https://github.com/beatgammit/base64-js",
    "publisher": "T. Jameson Little",
    "email": "t.jameson.little@gmail.com",
    "path": "/builds/santyteam/santyrdv/node_modules/base64-js",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/base64-js/LICENSE"
  },
  "bcrypt-pbkdf@1.0.2": {
    "licenses": "BSD-3-Clause",
    "repository": "https://github.com/joyent/node-bcrypt-pbkdf",
    "path": "/builds/santyteam/santyrdv/node_modules/bcrypt-pbkdf",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/bcrypt-pbkdf/LICENSE"
  },
  "body-parser@1.20.3": {
    "licenses": "MIT",
    "repository": "https://github.com/expressjs/body-parser",
    "path": "/builds/santyteam/santyrdv/node_modules/body-parser",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/body-parser/LICENSE"
  },
  "buffer@6.0.3": {
    "licenses": "MIT",
    "repository": "https://github.com/feross/buffer",
    "publisher": "Feross Aboukhadijeh",
    "email": "feross@feross.org",
    "url": "https://feross.org",
    "path": "/builds/santyteam/santyrdv/node_modules/buffer",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/buffer/LICENSE"
  },
  "bytes@3.1.2": {
    "licenses": "MIT",
    "repository": "https://github.com/visionmedia/bytes.js",
    "publisher": "TJ Holowaychuk",
    "email": "tj@vision-media.ca",
    "url": "http://tjholowaychuk.com",
    "path": "/builds/santyteam/santyrdv/node_modules/bytes",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/bytes/LICENSE"
  },
  "call-bind-apply-helpers@1.0.2": {
    "licenses": "MIT",
    "repository": "https://github.com/ljharb/call-bind-apply-helpers",
    "publisher": "Jordan Harband",
    "email": "ljharb@gmail.com",
    "path": "/builds/santyteam/santyrdv/node_modules/call-bind-apply-helpers",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/call-bind-apply-helpers/LICENSE"
  },
  "call-bound@1.0.4": {
    "licenses": "MIT",
    "repository": "https://github.com/ljharb/call-bound",
    "publisher": "Jordan Harband",
    "email": "ljharb@gmail.com",
    "path": "/builds/santyteam/santyrdv/node_modules/call-bound",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/call-bound/LICENSE"
  },
  "canvg@1.5.3": {
    "licenses": "MIT",
    "repository": "https://github.com/canvg/canvg",
    "publisher": "Gabe Lerner",
    "email": "gabelerner@gmail.com",
    "path": "/builds/santyteam/santyrdv/node_modules/canvg",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/canvg/LICENSE"
  },
  "caseless@0.12.0": {
    "licenses": "Apache-2.0",
    "repository": "https://github.com/mikeal/caseless",
    "publisher": "Mikeal Rogers",
    "email": "mikeal.rogers@gmail.com",
    "path": "/builds/santyteam/santyrdv/node_modules/caseless",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/caseless/LICENSE"
  },
  "combined-stream@1.0.8": {
    "licenses": "MIT",
    "repository": "https://github.com/felixge/node-combined-stream",
    "publisher": "Felix Geisendörfer",
    "email": "felix@debuggable.com",
    "url": "http://debuggable.com/",
    "path": "/builds/santyteam/santyrdv/node_modules/combined-stream",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/combined-stream/License"
  },
  "commander@7.2.0": {
    "licenses": "MIT",
    "repository": "https://github.com/tj/commander.js",
    "publisher": "TJ Holowaychuk",
    "email": "tj@vision-media.ca",
    "path": "/builds/santyteam/santyrdv/node_modules/commander",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/commander/LICENSE"
  },
  "content-disposition@0.5.4": {
    "licenses": "MIT",
    "repository": "https://github.com/jshttp/content-disposition",
    "publisher": "Douglas Christopher Wilson",
    "email": "doug@somethingdoug.com",
    "path": "/builds/santyteam/santyrdv/node_modules/content-disposition",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/content-disposition/LICENSE"
  },
  "content-type@1.0.5": {
    "licenses": "MIT",
    "repository": "https://github.com/jshttp/content-type",
    "publisher": "Douglas Christopher Wilson",
    "email": "doug@somethingdoug.com",
    "path": "/builds/santyteam/santyrdv/node_modules/content-type",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/content-type/LICENSE"
  },
  "cookie-signature@1.0.6": {
    "licenses": "MIT",
    "repository": "https://github.com/visionmedia/node-cookie-signature",
    "publisher": "TJ Holowaychuk",
    "email": "tj@learnboost.com",
    "path": "/builds/santyteam/santyrdv/node_modules/cookie-signature",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/cookie-signature/Readme.md"
  },
  "cookie@0.7.1": {
    "licenses": "MIT",
    "repository": "https://github.com/jshttp/cookie",
    "publisher": "Roman Shtylman",
    "email": "shtylman@gmail.com",
    "path": "/builds/santyteam/santyrdv/node_modules/cookie",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/cookie/LICENSE"
  },
  "core-util-is@1.0.2": {
    "licenses": "MIT",
    "repository": "https://github.com/isaacs/core-util-is",
    "publisher": "Isaac Z. Schlueter",
    "email": "i@izs.me",
    "url": "http://blog.izs.me/",
    "path": "/builds/santyteam/santyrdv/node_modules/core-util-is",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/core-util-is/LICENSE"
  },
  "css-line-break@1.0.1": {
    "licenses": "MIT",
    "repository": "https://github.com/niklasvh/css-line-break",
    "publisher": "Niklas von Hertzen",
    "email": "niklasvh@gmail.com",
    "url": "https://hertzen.com",
    "path": "/builds/santyteam/santyrdv/node_modules/css-line-break",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/css-line-break/LICENSE"
  },
  "cssom@0.3.8": {
    "licenses": "MIT",
    "repository": "https://github.com/NV/CSSOM",
    "publisher": "Nikita Vasilyev",
    "email": "me@elv1s.ru",
    "path": "/builds/santyteam/santyrdv/node_modules/cssom",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/cssom/LICENSE.txt"
  },
  "cssstyle@0.2.37": {
    "licenses": "MIT",
    "repository": "https://github.com/chad3814/CSSStyleDeclaration",
    "path": "/builds/santyteam/santyrdv/node_modules/cssstyle",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/cssstyle/README.md"
  },
  "d3-array@2.12.1": {
    "licenses": "BSD-3-Clause",
    "repository": "https://github.com/d3/d3-array",
    "publisher": "Mike Bostock",
    "url": "http://bost.ocks.org/mike",
    "path": "/builds/santyteam/santyrdv/node_modules/d3-sankey/node_modules/d3-array",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/d3-sankey/node_modules/d3-array/LICENSE"
  },
  "d3-array@3.2.4": {
    "licenses": "ISC",
    "repository": "https://github.com/d3/d3-array",
    "publisher": "Mike Bostock",
    "url": "http://bost.ocks.org/mike",
    "path": "/builds/santyteam/santyrdv/node_modules/d3-array",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/d3-array/LICENSE"
  },
  "d3-axis@3.0.0": {
    "licenses": "ISC",
    "repository": "https://github.com/d3/d3-axis",
    "publisher": "Mike Bostock",
    "url": "https://bost.ocks.org/mike",
    "path": "/builds/santyteam/santyrdv/node_modules/d3-axis",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/d3-axis/LICENSE"
  },
  "d3-brush@3.0.0": {
    "licenses": "ISC",
    "repository": "https://github.com/d3/d3-brush",
    "publisher": "Mike Bostock",
    "url": "https://bost.ocks.org/mike",
    "path": "/builds/santyteam/santyrdv/node_modules/d3-brush",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/d3-brush/LICENSE"
  },
  "d3-chord@3.0.1": {
    "licenses": "ISC",
    "repository": "https://github.com/d3/d3-chord",
    "publisher": "Mike Bostock",
    "url": "http://bost.ocks.org/mike",
    "path": "/builds/santyteam/santyrdv/node_modules/d3-chord",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/d3-chord/LICENSE"
  },
  "d3-color@3.1.0": {
    "licenses": "ISC",
    "repository": "https://github.com/d3/d3-color",
    "publisher": "Mike Bostock",
    "url": "http://bost.ocks.org/mike",
    "path": "/builds/santyteam/santyrdv/node_modules/d3-color",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/d3-color/LICENSE"
  },
  "d3-contour@4.0.2": {
    "licenses": "ISC",
    "repository": "https://github.com/d3/d3-contour",
    "publisher": "Mike Bostock",
    "url": "http://bost.ocks.org/mike",
    "path": "/builds/santyteam/santyrdv/node_modules/d3-contour",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/d3-contour/LICENSE"
  },
  "d3-delaunay@6.0.4": {
    "licenses": "ISC",
    "repository": "https://github.com/d3/d3-delaunay",
    "publisher": "Mike Bostock",
    "url": "https://bost.ocks.org/mike",
    "path": "/builds/santyteam/santyrdv/node_modules/d3-delaunay",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/d3-delaunay/LICENSE"
  },
  "d3-dispatch@3.0.1": {
    "licenses": "ISC",
    "repository": "https://github.com/d3/d3-dispatch",
    "publisher": "Mike Bostock",
    "url": "http://bost.ocks.org/mike",
    "path": "/builds/santyteam/santyrdv/node_modules/d3-dispatch",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/d3-dispatch/LICENSE"
  },
  "d3-drag@3.0.0": {
    "licenses": "ISC",
    "repository": "https://github.com/d3/d3-drag",
    "publisher": "Mike Bostock",
    "url": "https://bost.ocks.org/mike",
    "path": "/builds/santyteam/santyrdv/node_modules/d3-drag",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/d3-drag/LICENSE"
  },
  "d3-dsv@3.0.1": {
    "licenses": "ISC",
    "repository": "https://github.com/d3/d3-dsv",
    "publisher": "Mike Bostock",
    "url": "http://bost.ocks.org/mike",
    "path": "/builds/santyteam/santyrdv/node_modules/d3-dsv",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/d3-dsv/LICENSE"
  },
  "d3-ease@3.0.1": {
    "licenses": "BSD-3-Clause",
    "repository": "https://github.com/d3/d3-ease",
    "publisher": "Mike Bostock",
    "url": "http://bost.ocks.org/mike",
    "path": "/builds/santyteam/santyrdv/node_modules/d3-ease",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/d3-ease/LICENSE"
  },
  "d3-fetch@3.0.1": {
    "licenses": "ISC",
    "repository": "https://github.com/d3/d3-fetch",
    "publisher": "Mike Bostock",
    "url": "http://bost.ocks.org/mike",
    "path": "/builds/santyteam/santyrdv/node_modules/d3-fetch",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/d3-fetch/LICENSE"
  },
  "d3-force@3.0.0": {
    "licenses": "ISC",
    "repository": "https://github.com/d3/d3-force",
    "publisher": "Mike Bostock",
    "url": "https://bost.ocks.org/mike",
    "path": "/builds/santyteam/santyrdv/node_modules/d3-force",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/d3-force/LICENSE"
  },
  "d3-format@3.1.0": {
    "licenses": "ISC",
    "repository": "https://github.com/d3/d3-format",
    "publisher": "Mike Bostock",
    "url": "http://bost.ocks.org/mike",
    "path": "/builds/santyteam/santyrdv/node_modules/d3-format",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/d3-format/LICENSE"
  },
  "d3-geo@3.1.1": {
    "licenses": "ISC",
    "repository": "https://github.com/d3/d3-geo",
    "publisher": "Mike Bostock",
    "url": "https://bost.ocks.org/mike",
    "path": "/builds/santyteam/santyrdv/node_modules/d3-geo",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/d3-geo/LICENSE"
  },
  "d3-hierarchy@3.1.2": {
    "licenses": "ISC",
    "repository": "https://github.com/d3/d3-hierarchy",
    "publisher": "Mike Bostock",
    "url": "http://bost.ocks.org/mike",
    "path": "/builds/santyteam/santyrdv/node_modules/d3-hierarchy",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/d3-hierarchy/LICENSE"
  },
  "d3-interpolate@3.0.1": {
    "licenses": "ISC",
    "repository": "https://github.com/d3/d3-interpolate",
    "publisher": "Mike Bostock",
    "url": "http://bost.ocks.org/mike",
    "path": "/builds/santyteam/santyrdv/node_modules/d3-interpolate",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/d3-interpolate/LICENSE"
  },
  "d3-path@1.0.9": {
    "licenses": "BSD-3-Clause",
    "repository": "https://github.com/d3/d3-path",
    "publisher": "Mike Bostock",
    "url": "http://bost.ocks.org/mike",
    "path": "/builds/santyteam/santyrdv/node_modules/d3-sankey/node_modules/d3-path",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/d3-sankey/node_modules/d3-path/LICENSE"
  },
  "d3-path@3.1.0": {
    "licenses": "ISC",
    "repository": "https://github.com/d3/d3-path",
    "publisher": "Mike Bostock",
    "url": "http://bost.ocks.org/mike",
    "path": "/builds/santyteam/santyrdv/node_modules/d3-path",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/d3-path/LICENSE"
  },
  "d3-polygon@3.0.1": {
    "licenses": "ISC",
    "repository": "https://github.com/d3/d3-polygon",
    "publisher": "Mike Bostock",
    "url": "http://bost.ocks.org/mike",
    "path": "/builds/santyteam/santyrdv/node_modules/d3-polygon",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/d3-polygon/LICENSE"
  },
  "d3-quadtree@3.0.1": {
    "licenses": "ISC",
    "repository": "https://github.com/d3/d3-quadtree",
    "publisher": "Mike Bostock",
    "url": "http://bost.ocks.org/mike",
    "path": "/builds/santyteam/santyrdv/node_modules/d3-quadtree",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/d3-quadtree/LICENSE"
  },
  "d3-random@3.0.1": {
    "licenses": "ISC",
    "repository": "https://github.com/d3/d3-random",
    "publisher": "Mike Bostock",
    "url": "http://bost.ocks.org/mike",
    "path": "/builds/santyteam/santyrdv/node_modules/d3-random",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/d3-random/LICENSE"
  },
  "d3-sankey@0.12.3": {
    "licenses": "BSD-3-Clause",
    "repository": "https://github.com/d3/d3-sankey",
    "publisher": "Mike Bostock",
    "url": "https://bost.ocks.org/mike/",
    "path": "/builds/santyteam/santyrdv/node_modules/d3-sankey",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/d3-sankey/LICENSE"
  },
  "d3-scale-chromatic@3.1.0": {
    "licenses": "ISC",
    "repository": "https://github.com/d3/d3-scale-chromatic",
    "publisher": "Mike Bostock",
    "url": "https://bost.ocks.org/mike",
    "path": "/builds/santyteam/santyrdv/node_modules/d3-scale-chromatic",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/d3-scale-chromatic/LICENSE"
  },
  "d3-scale@4.0.2": {
    "licenses": "ISC",
    "repository": "https://github.com/d3/d3-scale",
    "publisher": "Mike Bostock",
    "url": "https://bost.ocks.org/mike",
    "path": "/builds/santyteam/santyrdv/node_modules/d3-scale",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/d3-scale/LICENSE"
  },
  "d3-selection@3.0.0": {
    "licenses": "ISC",
    "repository": "https://github.com/d3/d3-selection",
    "publisher": "Mike Bostock",
    "url": "https://bost.ocks.org/mike",
    "path": "/builds/santyteam/santyrdv/node_modules/d3-selection",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/d3-selection/LICENSE"
  },
  "d3-shape@1.3.7": {
    "licenses": "BSD-3-Clause",
    "repository": "https://github.com/d3/d3-shape",
    "publisher": "Mike Bostock",
    "url": "http://bost.ocks.org/mike",
    "path": "/builds/santyteam/santyrdv/node_modules/d3-sankey/node_modules/d3-shape",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/d3-sankey/node_modules/d3-shape/LICENSE"
  },
  "d3-shape@3.2.0": {
    "licenses": "ISC",
    "repository": "https://github.com/d3/d3-shape",
    "publisher": "Mike Bostock",
    "url": "http://bost.ocks.org/mike",
    "path": "/builds/santyteam/santyrdv/node_modules/d3-shape",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/d3-shape/LICENSE"
  },
  "d3-time-format@4.1.0": {
    "licenses": "ISC",
    "repository": "https://github.com/d3/d3-time-format",
    "publisher": "Mike Bostock",
    "url": "http://bost.ocks.org/mike",
    "path": "/builds/santyteam/santyrdv/node_modules/d3-time-format",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/d3-time-format/LICENSE"
  },
  "d3-time@3.1.0": {
    "licenses": "ISC",
    "repository": "https://github.com/d3/d3-time",
    "publisher": "Mike Bostock",
    "url": "http://bost.ocks.org/mike",
    "path": "/builds/santyteam/santyrdv/node_modules/d3-time",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/d3-time/LICENSE"
  },
  "d3-timer@3.0.1": {
    "licenses": "ISC",
    "repository": "https://github.com/d3/d3-timer",
    "publisher": "Mike Bostock",
    "url": "http://bost.ocks.org/mike",
    "path": "/builds/santyteam/santyrdv/node_modules/d3-timer",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/d3-timer/LICENSE"
  },
  "d3-transition@3.0.1": {
    "licenses": "ISC",
    "repository": "https://github.com/d3/d3-transition",
    "publisher": "Mike Bostock",
    "url": "https://bost.ocks.org/mike",
    "path": "/builds/santyteam/santyrdv/node_modules/d3-transition",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/d3-transition/LICENSE"
  },
  "d3-zoom@3.0.0": {
    "licenses": "ISC",
    "repository": "https://github.com/d3/d3-zoom",
    "publisher": "Mike Bostock",
    "url": "https://bost.ocks.org/mike",
    "path": "/builds/santyteam/santyrdv/node_modules/d3-zoom",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/d3-zoom/LICENSE"
  },
  "d3@7.9.0": {
    "licenses": "ISC",
    "repository": "https://github.com/d3/d3",
    "publisher": "Mike Bostock",
    "url": "https://bost.ocks.org/mike",
    "path": "/builds/santyteam/santyrdv/node_modules/d3",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/d3/LICENSE"
  },
  "dashdash@1.14.1": {
    "licenses": "MIT",
    "repository": "https://github.com/trentm/node-dashdash",
    "publisher": "Trent Mick",
    "email": "trentm@gmail.com",
    "url": "http://trentm.com",
    "path": "/builds/santyteam/santyrdv/node_modules/dashdash",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/dashdash/LICENSE.txt"
  },
  "debug@2.6.9": {
    "licenses": "MIT",
    "repository": "https://github.com/visionmedia/debug",
    "publisher": "TJ Holowaychuk",
    "email": "tj@vision-media.ca",
    "path": "/builds/santyteam/santyrdv/node_modules/body-parser/node_modules/debug",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/body-parser/node_modules/debug/LICENSE"
  },
  "deep-is@0.1.4": {
    "licenses": "MIT",
    "repository": "https://github.com/thlorenz/deep-is",
    "publisher": "Thorsten Lorenz",
    "email": "thlorenz@gmx.de",
    "url": "http://thlorenz.com",
    "path": "/builds/santyteam/santyrdv/node_modules/deep-is",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/deep-is/LICENSE"
  },
  "delaunator@5.0.1": {
    "licenses": "ISC",
    "repository": "https://github.com/mapbox/delaunator",
    "publisher": "Vladimir Agafonkin",
    "path": "/builds/santyteam/santyrdv/node_modules/delaunator",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/delaunator/LICENSE"
  },
  "delayed-stream@1.0.0": {
    "licenses": "MIT",
    "repository": "https://github.com/felixge/node-delayed-stream",
    "publisher": "Felix Geisendörfer",
    "email": "felix@debuggable.com",
    "url": "http://debuggable.com/",
    "path": "/builds/santyteam/santyrdv/node_modules/delayed-stream",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/delayed-stream/License"
  },
  "depd@2.0.0": {
    "licenses": "MIT",
    "repository": "https://github.com/dougwilson/nodejs-depd",
    "publisher": "Douglas Christopher Wilson",
    "email": "doug@somethingdoug.com",
    "path": "/builds/santyteam/santyrdv/node_modules/depd",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/depd/LICENSE"
  },
  "destroy@1.2.0": {
    "licenses": "MIT",
    "repository": "https://github.com/stream-utils/destroy",
    "publisher": "Jonathan Ong",
    "email": "me@jongleberry.com",
    "url": "http://jongleberry.com",
    "path": "/builds/santyteam/santyrdv/node_modules/destroy",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/destroy/LICENSE"
  },
  "dompurify@3.2.4": {
    "licenses": "(MPL-2.0 OR Apache-2.0)",
    "repository": "https://github.com/cure53/DOMPurify",
    "publisher": "Dr.-Ing. Mario Heiderich, Cure53",
    "email": "mario@cure53.de",
    "url": "https://cure53.de/",
    "path": "/builds/santyteam/santyrdv/node_modules/dompurify",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/dompurify/LICENSE"
  },
  "dunder-proto@1.0.1": {
    "licenses": "MIT",
    "repository": "https://github.com/es-shims/dunder-proto",
    "publisher": "Jordan Harband",
    "email": "ljharb@gmail.com",
    "path": "/builds/santyteam/santyrdv/node_modules/dunder-proto",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/dunder-proto/LICENSE"
  },
  "ecc-jsbn@0.1.2": {
    "licenses": "MIT",
    "repository": "https://github.com/quartzjer/ecc-jsbn",
    "publisher": "Jeremie Miller",
    "email": "jeremie@jabber.org",
    "url": "http://jeremie.com/",
    "path": "/builds/santyteam/santyrdv/node_modules/ecc-jsbn",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/ecc-jsbn/LICENSE"
  },
  "ee-first@1.1.1": {
    "licenses": "MIT",
    "repository": "https://github.com/jonathanong/ee-first",
    "publisher": "Jonathan Ong",
    "email": "me@jongleberry.com",
    "url": "http://jongleberry.com",
    "path": "/builds/santyteam/santyrdv/node_modules/ee-first",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/ee-first/LICENSE"
  },
  "encodeurl@1.0.2": {
    "licenses": "MIT",
    "repository": "https://github.com/pillarjs/encodeurl",
    "path": "/builds/santyteam/santyrdv/node_modules/send/node_modules/encodeurl",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/send/node_modules/encodeurl/LICENSE"
  },
  "encodeurl@2.0.0": {
    "licenses": "MIT",
    "repository": "https://github.com/pillarjs/encodeurl",
    "path": "/builds/santyteam/santyrdv/node_modules/encodeurl",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/encodeurl/LICENSE"
  },
  "entities@4.5.0": {
    "licenses": "BSD-2-Clause",
    "repository": "https://github.com/fb55/entities",
    "publisher": "Felix Boehm",
    "email": "me@feedic.com",
    "path": "/builds/santyteam/santyrdv/node_modules/entities",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/entities/LICENSE"
  },
  "es-define-property@1.0.1": {
    "licenses": "MIT",
    "repository": "https://github.com/ljharb/es-define-property",
    "publisher": "Jordan Harband",
    "email": "ljharb@gmail.com",
    "path": "/builds/santyteam/santyrdv/node_modules/es-define-property",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/es-define-property/LICENSE"
  },
  "es-errors@1.3.0": {
    "licenses": "MIT",
    "repository": "https://github.com/ljharb/es-errors",
    "publisher": "Jordan Harband",
    "email": "ljharb@gmail.com",
    "path": "/builds/santyteam/santyrdv/node_modules/es-errors",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/es-errors/LICENSE"
  },
  "es-object-atoms@1.1.1": {
    "licenses": "MIT",
    "repository": "https://github.com/ljharb/es-object-atoms",
    "publisher": "Jordan Harband",
    "email": "ljharb@gmail.com",
    "path": "/builds/santyteam/santyrdv/node_modules/es-object-atoms",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/es-object-atoms/LICENSE"
  },
  "escape-html@1.0.3": {
    "licenses": "MIT",
    "repository": "https://github.com/component/escape-html",
    "path": "/builds/santyteam/santyrdv/node_modules/escape-html",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/escape-html/LICENSE"
  },
  "escodegen@1.14.3": {
    "licenses": "BSD-2-Clause",
    "repository": "https://github.com/estools/escodegen",
    "path": "/builds/santyteam/santyrdv/node_modules/escodegen",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/escodegen/LICENSE.BSD"
  },
  "esprima@4.0.1": {
    "licenses": "BSD-2-Clause",
    "repository": "https://github.com/jquery/esprima",
    "publisher": "Ariya Hidayat",
    "email": "ariya.hidayat@gmail.com",
    "path": "/builds/santyteam/santyrdv/node_modules/esprima",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/esprima/LICENSE.BSD"
  },
  "estraverse@4.3.0": {
    "licenses": "BSD-2-Clause",
    "repository": "https://github.com/estools/estraverse",
    "path": "/builds/santyteam/santyrdv/node_modules/escodegen/node_modules/estraverse",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/escodegen/node_modules/estraverse/LICENSE.BSD"
  },
  "esutils@2.0.3": {
    "licenses": "BSD-2-Clause",
    "repository": "https://github.com/estools/esutils",
    "path": "/builds/santyteam/santyrdv/node_modules/esutils",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/esutils/LICENSE.BSD"
  },
  "etag@1.8.1": {
    "licenses": "MIT",
    "repository": "https://github.com/jshttp/etag",
    "path": "/builds/santyteam/santyrdv/node_modules/etag",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/etag/LICENSE"
  },
  "eventemitter3@5.0.1": {
    "licenses": "MIT",
    "repository": "https://github.com/primus/eventemitter3",
    "publisher": "Arnout Kazemier",
    "path": "/builds/santyteam/santyrdv/node_modules/quill/node_modules/eventemitter3",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/quill/node_modules/eventemitter3/LICENSE"
  },
  "express@4.21.2": {
    "licenses": "MIT",
    "repository": "https://github.com/expressjs/express",
    "publisher": "TJ Holowaychuk",
    "email": "tj@vision-media.ca",
    "path": "/builds/santyteam/santyrdv/node_modules/express",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/express/LICENSE"
  },
  "extend@3.0.2": {
    "licenses": "MIT",
    "repository": "https://github.com/justmoon/node-extend",
    "publisher": "Stefan Thomas",
    "email": "justmoon@members.fsf.org",
    "url": "http://www.justmoon.net",
    "path": "/builds/santyteam/santyrdv/node_modules/extend",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/extend/LICENSE"
  },
  "extsprintf@1.3.0": {
    "licenses": "MIT",
    "repository": "https://github.com/davepacheco/node-extsprintf",
    "path": "/builds/santyteam/santyrdv/node_modules/extsprintf",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/extsprintf/LICENSE"
  },
  "fast-deep-equal@3.1.3": {
    "licenses": "MIT",
    "repository": "https://github.com/epoberezkin/fast-deep-equal",
    "publisher": "Evgeny Poberezkin",
    "path": "/builds/santyteam/santyrdv/node_modules/fast-deep-equal",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/fast-deep-equal/LICENSE"
  },
  "fast-diff@1.3.0": {
    "licenses": "Apache-2.0",
    "repository": "https://github.com/jhchen/fast-diff",
    "publisher": "Jason Chen",
    "email": "jhchen7@gmail.com",
    "path": "/builds/santyteam/santyrdv/node_modules/fast-diff",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/fast-diff/LICENSE"
  },
  "fast-json-stable-stringify@2.1.0": {
    "licenses": "MIT",
    "repository": "https://github.com/epoberezkin/fast-json-stable-stringify",
    "publisher": "James Halliday",
    "email": "mail@substack.net",
    "url": "http://substack.net",
    "path": "/builds/santyteam/santyrdv/node_modules/fast-json-stable-stringify",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/fast-json-stable-stringify/LICENSE"
  },
  "fast-levenshtein@2.0.6": {
    "licenses": "MIT",
    "repository": "https://github.com/hiddentao/fast-levenshtein",
    "publisher": "Ramesh Nair",
    "email": "ram@hiddentao.com",
    "url": "http://www.hiddentao.com/",
    "path": "/builds/santyteam/santyrdv/node_modules/fast-levenshtein",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/fast-levenshtein/LICENSE.md"
  },
  "file-saver@1.3.8": {
    "licenses": "MIT",
    "repository": "https://github.com/eligrey/FileSaver.js",
    "publisher": "Eli Grey",
    "email": "me@eligrey.com",
    "path": "/builds/santyteam/santyrdv/node_modules/jspdf/node_modules/file-saver",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/jspdf/node_modules/file-saver/LICENSE.md"
  },
  "file-saver@2.0.5": {
    "licenses": "MIT",
    "repository": "https://github.com/eligrey/FileSaver.js",
    "publisher": "Eli Grey",
    "email": "me@eligrey.com",
    "path": "/builds/santyteam/santyrdv/node_modules/file-saver",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/file-saver/LICENSE.md"
  },
  "finalhandler@1.3.1": {
    "licenses": "MIT",
    "repository": "https://github.com/pillarjs/finalhandler",
    "publisher": "Douglas Christopher Wilson",
    "email": "doug@somethingdoug.com",
    "path": "/builds/santyteam/santyrdv/node_modules/finalhandler",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/finalhandler/LICENSE"
  },
  "forever-agent@0.6.1": {
    "licenses": "Apache-2.0",
    "repository": "https://github.com/mikeal/forever-agent",
    "publisher": "Mikeal Rogers",
    "email": "mikeal.rogers@gmail.com",
    "url": "http://www.futurealoof.com",
    "path": "/builds/santyteam/santyrdv/node_modules/forever-agent",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/forever-agent/LICENSE"
  },
  "form-data@2.3.3": {
    "licenses": "MIT",
    "repository": "https://github.com/form-data/form-data",
    "publisher": "Felix Geisendörfer",
    "email": "felix@debuggable.com",
    "url": "http://debuggable.com/",
    "path": "/builds/santyteam/santyrdv/node_modules/form-data",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/form-data/License"
  },
  "forwarded@0.2.0": {
    "licenses": "MIT",
    "repository": "https://github.com/jshttp/forwarded",
    "path": "/builds/santyteam/santyrdv/node_modules/forwarded",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/forwarded/LICENSE"
  },
  "fresh@0.5.2": {
    "licenses": "MIT",
    "repository": "https://github.com/jshttp/fresh",
    "publisher": "TJ Holowaychuk",
    "email": "tj@vision-media.ca",
    "url": "http://tjholowaychuk.com",
    "path": "/builds/santyteam/santyrdv/node_modules/fresh",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/fresh/LICENSE"
  },
  "function-bind@1.1.2": {
    "licenses": "MIT",
    "repository": "https://github.com/Raynos/function-bind",
    "publisher": "Raynos",
    "email": "raynos2@gmail.com",
    "path": "/builds/santyteam/santyrdv/node_modules/function-bind",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/function-bind/LICENSE"
  },
  "get-intrinsic@1.3.0": {
    "licenses": "MIT",
    "repository": "https://github.com/ljharb/get-intrinsic",
    "publisher": "Jordan Harband",
    "email": "ljharb@gmail.com",
    "path": "/builds/santyteam/santyrdv/node_modules/get-intrinsic",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/get-intrinsic/LICENSE"
  },
  "get-proto@1.0.1": {
    "licenses": "MIT",
    "repository": "https://github.com/ljharb/get-proto",
    "publisher": "Jordan Harband",
    "email": "ljharb@gmail.com",
    "path": "/builds/santyteam/santyrdv/node_modules/get-proto",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/get-proto/LICENSE"
  },
  "getpass@0.1.7": {
    "licenses": "MIT",
    "repository": "https://github.com/arekinath/node-getpass",
    "publisher": "Alex Wilson",
    "email": "alex.wilson@joyent.com",
    "path": "/builds/santyteam/santyrdv/node_modules/getpass",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/getpass/LICENSE"
  },
  "gopd@1.2.0": {
    "licenses": "MIT",
    "repository": "https://github.com/ljharb/gopd",
    "publisher": "Jordan Harband",
    "email": "ljharb@gmail.com",
    "path": "/builds/santyteam/santyrdv/node_modules/gopd",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/gopd/LICENSE"
  },
  "gradient-path@2.3.0": {
    "licenses": "MIT",
    "repository": "https://github.com/cereallarceny/gradient-path",
    "publisher": "Patrick Cason",
    "email": "me@patrickcason.com",
    "path": "/builds/santyteam/santyrdv/node_modules/gradient-path",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/gradient-path/LICENSE"
  },
  "har-schema@2.0.0": {
    "licenses": "ISC",
    "repository": "https://github.com/ahmadnassri/har-schema",
    "publisher": "Ahmad Nassri",
    "email": "ahmad@ahmadnassri.com",
    "url": "https://www.ahmadnassri.com/",
    "path": "/builds/santyteam/santyrdv/node_modules/har-schema",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/har-schema/LICENSE"
  },
  "har-validator@5.1.5": {
    "licenses": "MIT",
    "repository": "https://github.com/ahmadnassri/node-har-validator",
    "publisher": "Ahmad Nassri",
    "email": "ahmad@ahmadnassri.com",
    "url": "https://www.ahmadnassri.com/",
    "path": "/builds/santyteam/santyrdv/node_modules/har-validator",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/har-validator/LICENSE"
  },
  "has-symbols@1.1.0": {
    "licenses": "MIT",
    "repository": "https://github.com/inspect-js/has-symbols",
    "publisher": "Jordan Harband",
    "email": "ljharb@gmail.com",
    "url": "http://ljharb.codes",
    "path": "/builds/santyteam/santyrdv/node_modules/has-symbols",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/has-symbols/LICENSE"
  },
  "hasown@2.0.2": {
    "licenses": "MIT",
    "repository": "https://github.com/inspect-js/hasOwn",
    "publisher": "Jordan Harband",
    "email": "ljharb@gmail.com",
    "path": "/builds/santyteam/santyrdv/node_modules/hasown",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/hasown/LICENSE"
  },
  "html2canvas@1.0.0-alpha.12": {
    "licenses": "MIT",
    "repository": "https://github.com/niklasvh/html2canvas",
    "publisher": "Niklas von Hertzen",
    "email": "niklasvh@gmail.com",
    "url": "https://hertzen.com",
    "path": "/builds/santyteam/santyrdv/node_modules/html2canvas",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/html2canvas/LICENSE"
  },
  "http-errors@2.0.0": {
    "licenses": "MIT",
    "repository": "https://github.com/jshttp/http-errors",
    "publisher": "Jonathan Ong",
    "email": "me@jongleberry.com",
    "url": "http://jongleberry.com",
    "path": "/builds/santyteam/santyrdv/node_modules/http-errors",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/http-errors/LICENSE"
  },
  "http-signature@1.2.0": {
    "licenses": "MIT",
    "repository": "https://github.com/joyent/node-http-signature",
    "publisher": "Joyent, Inc",
    "path": "/builds/santyteam/santyrdv/node_modules/http-signature",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/http-signature/LICENSE"
  },
  "iconv-lite@0.4.24": {
    "licenses": "MIT",
    "repository": "https://github.com/ashtuchkin/iconv-lite",
    "publisher": "Alexander Shtuchkin",
    "email": "ashtuchkin@gmail.com",
    "path": "/builds/santyteam/santyrdv/node_modules/body-parser/node_modules/iconv-lite",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/body-parser/node_modules/iconv-lite/LICENSE"
  },
  "iconv-lite@0.6.3": {
    "licenses": "MIT",
    "repository": "https://github.com/ashtuchkin/iconv-lite",
    "publisher": "Alexander Shtuchkin",
    "email": "ashtuchkin@gmail.com",
    "path": "/builds/santyteam/santyrdv/node_modules/iconv-lite",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/iconv-lite/LICENSE"
  },
  "ieee754@1.2.1": {
    "licenses": "BSD-3-Clause",
    "repository": "https://github.com/feross/ieee754",
    "publisher": "Feross Aboukhadijeh",
    "email": "feross@feross.org",
    "url": "https://feross.org",
    "path": "/builds/santyteam/santyrdv/node_modules/ieee754",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/ieee754/LICENSE"
  },
  "inherits@2.0.4": {
    "licenses": "ISC",
    "repository": "https://github.com/isaacs/inherits",
    "path": "/builds/santyteam/santyrdv/node_modules/inherits",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/inherits/LICENSE"
  },
  "internmap@1.0.1": {
    "licenses": "ISC",
    "repository": "https://github.com/mbostock/internmap",
    "publisher": "Mike Bostock",
    "url": "https://bost.ocks.org/mike",
    "path": "/builds/santyteam/santyrdv/node_modules/d3-sankey/node_modules/internmap",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/d3-sankey/node_modules/internmap/LICENSE"
  },
  "internmap@2.0.3": {
    "licenses": "ISC",
    "repository": "https://github.com/mbostock/internmap",
    "publisher": "Mike Bostock",
    "url": "https://bost.ocks.org/mike",
    "path": "/builds/santyteam/santyrdv/node_modules/internmap",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/internmap/LICENSE"
  },
  "ipaddr.js@1.9.1": {
    "licenses": "MIT",
    "repository": "https://github.com/whitequark/ipaddr.js",
    "publisher": "whitequark",
    "email": "whitequark@whitequark.org",
    "path": "/builds/santyteam/santyrdv/node_modules/ipaddr.js",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/ipaddr.js/LICENSE"
  },
  "is-typedarray@1.0.0": {
    "licenses": "MIT",
    "repository": "https://github.com/hughsk/is-typedarray",
    "publisher": "Hugh Kennedy",
    "email": "hughskennedy@gmail.com",
    "url": "http://hughsk.io/",
    "path": "/builds/santyteam/santyrdv/node_modules/is-typedarray",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/is-typedarray/LICENSE.md"
  },
  "isstream@0.1.2": {
    "licenses": "MIT",
    "repository": "https://github.com/rvagg/isstream",
    "publisher": "Rod Vagg",
    "email": "rod@vagg.org",
    "path": "/builds/santyteam/santyrdv/node_modules/isstream",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/isstream/LICENSE.md"
  },
  "jsbn@0.1.1": {
    "licenses": "MIT",
    "repository": "https://github.com/andyperlitch/jsbn",
    "publisher": "Tom Wu",
    "path": "/builds/santyteam/santyrdv/node_modules/sshpk/node_modules/jsbn",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/sshpk/node_modules/jsbn/LICENSE"
  },
  "jsdom@8.5.0": {
    "licenses": "MIT",
    "repository": "https://github.com/tmpvar/jsdom",
    "path": "/builds/santyteam/santyrdv/node_modules/jsdom",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/jsdom/LICENSE.txt"
  },
  "json-schema-traverse@0.4.1": {
    "licenses": "MIT",
    "repository": "https://github.com/epoberezkin/json-schema-traverse",
    "publisher": "Evgeny Poberezkin",
    "path": "/builds/santyteam/santyrdv/node_modules/har-validator/node_modules/json-schema-traverse",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/har-validator/node_modules/json-schema-traverse/LICENSE"
  },
  "json-schema@0.4.0": {
    "licenses": "(AFL-2.1 OR BSD-3-Clause)",
    "repository": "https://github.com/kriszyp/json-schema",
    "publisher": "Kris Zyp",
    "path": "/builds/santyteam/santyrdv/node_modules/json-schema",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/json-schema/LICENSE"
  },
  "json-stringify-safe@5.0.1": {
    "licenses": "ISC",
    "repository": "https://github.com/isaacs/json-stringify-safe",
    "publisher": "Isaac Z. Schlueter",
    "email": "i@izs.me",
    "url": "http://blog.izs.me",
    "path": "/builds/santyteam/santyrdv/node_modules/json-stringify-safe",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/json-stringify-safe/LICENSE"
  },
  "jspdf-autotable@3.8.4": {
    "licenses": "MIT",
    "repository": "https://github.com/simonbengtsson/jsPDF-AutoTable",
    "publisher": "Simon Bengtsson",
    "email": "dev@simonbengtsson.com",
    "path": "/builds/santyteam/santyrdv/node_modules/jspdf-autotable",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/jspdf-autotable/LICENSE.txt"
  },
  "jspdf@1.5.3": {
    "licenses": "MIT",
    "repository": "https://github.com/MrRio/jsPDF",
    "path": "/builds/santyteam/santyrdv/node_modules/jspdf",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/jspdf/README.md"
  },
  "jsprim@1.4.2": {
    "licenses": "MIT",
    "repository": "https://github.com/joyent/node-jsprim",
    "path": "/builds/santyteam/santyrdv/node_modules/jsprim",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/jsprim/LICENSE"
  },
  "jwt-decode@4.0.0": {
    "licenses": "MIT",
    "repository": "https://github.com/auth0/jwt-decode",
    "publisher": "Jose F. Romaniello",
    "email": "jfromaniello@gmail.com",
    "path": "/builds/santyteam/santyrdv/node_modules/jwt-decode",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/jwt-decode/LICENSE"
  },
  "leaflet-cascade-buttons@1.0.10": {
    "licenses": "MIT",
    "repository": "https://github.com/clavijojuan/L.cascadeButtons",
    "publisher": "Juan Camilo Clavijo Sandoval",
    "path": "/builds/santyteam/santyrdv/node_modules/leaflet-cascade-buttons",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/leaflet-cascade-buttons/LICENSE"
  },
  "leaflet.fullscreen@3.0.2": {
    "licenses": "MIT",
    "repository": "https://github.com/brunob/leaflet.fullscreen",
    "publisher": "b_b",
    "path": "/builds/santyteam/santyrdv/node_modules/leaflet.fullscreen",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/leaflet.fullscreen/LICENSE"
  },
  "leaflet@1.9.4": {
    "licenses": "BSD-2-Clause",
    "repository": "https://github.com/Leaflet/Leaflet",
    "path": "/builds/santyteam/santyrdv/node_modules/leaflet",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/leaflet/LICENSE"
  },
  "levn@0.3.0": {
    "licenses": "MIT",
    "repository": "https://github.com/gkz/levn",
    "publisher": "George Zahariev",
    "email": "z@georgezahariev.com",
    "path": "/builds/santyteam/santyrdv/node_modules/escodegen/node_modules/levn",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/escodegen/node_modules/levn/LICENSE"
  },
  "lodash-es@4.17.21": {
    "licenses": "MIT",
    "repository": "https://github.com/lodash/lodash",
    "publisher": "John-David Dalton",
    "email": "john.david.dalton@gmail.com",
    "path": "/builds/santyteam/santyrdv/node_modules/lodash-es",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/lodash-es/LICENSE"
  },
  "lodash.clonedeep@4.5.0": {
    "licenses": "MIT",
    "repository": "https://github.com/lodash/lodash",
    "publisher": "John-David Dalton",
    "email": "john.david.dalton@gmail.com",
    "url": "http://allyoucanleet.com/",
    "path": "/builds/santyteam/santyrdv/node_modules/lodash.clonedeep",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/lodash.clonedeep/LICENSE"
  },
  "lodash.isequal@4.5.0": {
    "licenses": "MIT",
    "repository": "https://github.com/lodash/lodash",
    "publisher": "John-David Dalton",
    "email": "john.david.dalton@gmail.com",
    "url": "http://allyoucanleet.com/",
    "path": "/builds/santyteam/santyrdv/node_modules/lodash.isequal",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/lodash.isequal/LICENSE"
  },
  "lodash@4.17.21": {
    "licenses": "MIT",
    "repository": "https://github.com/lodash/lodash",
    "publisher": "John-David Dalton",
    "email": "john.david.dalton@gmail.com",
    "path": "/builds/santyteam/santyrdv/node_modules/lodash",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/lodash/LICENSE"
  },
  "luxon@3.5.0": {
    "licenses": "MIT",
    "repository": "https://github.com/moment/luxon",
    "publisher": "Isaac Cambron",
    "path": "/builds/santyteam/santyrdv/node_modules/luxon",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/luxon/LICENSE.md"
  },
  "math-intrinsics@1.1.0": {
    "licenses": "MIT",
    "repository": "https://github.com/es-shims/math-intrinsics",
    "publisher": "Jordan Harband",
    "email": "ljharb@gmail.com",
    "path": "/builds/santyteam/santyrdv/node_modules/math-intrinsics",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/math-intrinsics/LICENSE"
  },
  "media-typer@0.3.0": {
    "licenses": "MIT",
    "repository": "https://github.com/jshttp/media-typer",
    "publisher": "Douglas Christopher Wilson",
    "email": "doug@somethingdoug.com",
    "path": "/builds/santyteam/santyrdv/node_modules/media-typer",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/media-typer/LICENSE"
  },
  "merge-descriptors@1.0.3": {
    "licenses": "MIT",
    "repository": "https://github.com/sindresorhus/merge-descriptors",
    "publisher": "Jonathan Ong",
    "email": "me@jongleberry.com",
    "url": "http://jongleberry.com",
    "path": "/builds/santyteam/santyrdv/node_modules/merge-descriptors",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/merge-descriptors/LICENSE"
  },
  "methods@1.1.2": {
    "licenses": "MIT",
    "repository": "https://github.com/jshttp/methods",
    "path": "/builds/santyteam/santyrdv/node_modules/methods",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/methods/LICENSE"
  },
  "mime-db@1.52.0": {
    "licenses": "MIT",
    "repository": "https://github.com/jshttp/mime-db",
    "path": "/builds/santyteam/santyrdv/node_modules/mime-db",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/mime-db/LICENSE"
  },
  "mime-types@2.1.35": {
    "licenses": "MIT",
    "repository": "https://github.com/jshttp/mime-types",
    "path": "/builds/santyteam/santyrdv/node_modules/mime-types",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/mime-types/LICENSE"
  },
  "mime@1.6.0": {
    "licenses": "MIT",
    "repository": "https://github.com/broofa/node-mime",
    "publisher": "Robert Kieffer",
    "email": "robert@broofa.com",
    "url": "http://github.com/broofa",
    "path": "/builds/santyteam/santyrdv/node_modules/send/node_modules/mime",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/send/node_modules/mime/LICENSE"
  },
  "ms@2.0.0": {
    "licenses": "MIT",
    "repository": "https://github.com/zeit/ms",
    "path": "/builds/santyteam/santyrdv/node_modules/body-parser/node_modules/ms",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/body-parser/node_modules/ms/license.md"
  },
  "ms@2.1.3": {
    "licenses": "MIT",
    "repository": "https://github.com/vercel/ms",
    "path": "/builds/santyteam/santyrdv/node_modules/ms",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/ms/license.md"
  },
  "negotiator@0.6.3": {
    "licenses": "MIT",
    "repository": "https://github.com/jshttp/negotiator",
    "path": "/builds/santyteam/santyrdv/node_modules/negotiator",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/negotiator/LICENSE"
  },
  "ngx-quill@27.0.0": {
    "licenses": "MIT",
    "repository": "https://github.com/KillerCodeMonkey/ngx-quill",
    "publisher": "Bengt Weiße",
    "path": "/builds/santyteam/santyrdv/node_modules/ngx-quill",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/ngx-quill/LICENSE.txt"
  },
  "ngx-skeleton-loader@9.0.0": {
    "licenses": "MIT",
    "repository": "https://github.com/willmendesneto/ngx-skeleton-loader",
    "path": "/builds/santyteam/santyrdv/node_modules/ngx-skeleton-loader",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/ngx-skeleton-loader/README.md"
  },
  "ngx-toastr@19.0.0": {
    "licenses": "MIT",
    "repository": "https://github.com/scttcper/ngx-toastr",
    "path": "/builds/santyteam/santyrdv/node_modules/ngx-toastr",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/ngx-toastr/LICENSE"
  },
  "ngx-turnstile@0.2.4": {
    "licenses": "MIT",
    "repository": "https://github.com/verto-health/ngx-turnstile",
    "path": "/builds/santyteam/santyrdv/node_modules/ngx-turnstile",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/ngx-turnstile/LICENSE"
  },
  "nwmatcher@1.4.4": {
    "licenses": "MIT",
    "repository": "https://github.com/dperini/nwmatcher",
    "publisher": "Diego Perini",
    "email": "diego.perini@gmail.com",
    "url": "http://www.iport.it/",
    "path": "/builds/santyteam/santyrdv/node_modules/nwmatcher",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/nwmatcher/LICENSE"
  },
  "oauth-sign@0.9.0": {
    "licenses": "Apache-2.0",
    "repository": "https://github.com/mikeal/oauth-sign",
    "publisher": "Mikeal Rogers",
    "email": "mikeal.rogers@gmail.com",
    "url": "http://www.futurealoof.com",
    "path": "/builds/santyteam/santyrdv/node_modules/oauth-sign",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/oauth-sign/LICENSE"
  },
  "object-inspect@1.13.4": {
    "licenses": "MIT",
    "repository": "https://github.com/inspect-js/object-inspect",
    "publisher": "James Halliday",
    "email": "mail@substack.net",
    "url": "http://substack.net",
    "path": "/builds/santyteam/santyrdv/node_modules/object-inspect",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/object-inspect/LICENSE"
  },
  "omggif@1.0.7": {
    "licenses": "MIT*",
    "repository": "https://github.com/deanm/omggif",
    "publisher": "Dean McNamee",
    "email": "dean@gmail.com",
    "path": "/builds/santyteam/santyrdv/node_modules/omggif",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/omggif/README"
  },
  "on-finished@2.4.1": {
    "licenses": "MIT",
    "repository": "https://github.com/jshttp/on-finished",
    "path": "/builds/santyteam/santyrdv/node_modules/on-finished",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/on-finished/LICENSE"
  },
  "optionator@0.8.3": {
    "licenses": "MIT",
    "repository": "https://github.com/gkz/optionator",
    "publisher": "George Zahariev",
    "email": "z@georgezahariev.com",
    "path": "/builds/santyteam/santyrdv/node_modules/escodegen/node_modules/optionator",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/escodegen/node_modules/optionator/LICENSE"
  },
  "parchment@3.0.0": {
    "licenses": "BSD-3-Clause",
    "repository": "https://github.com/quilljs/parchment",
    "publisher": "Jason Chen",
    "email": "jhchen7@gmail.com",
    "path": "/builds/santyteam/santyrdv/node_modules/parchment",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/parchment/LICENSE"
  },
  "parse5@1.5.1": {
    "licenses": "MIT",
    "repository": "https://github.com/inikulin/parse5",
    "publisher": "Ivan Nikulin",
    "email": "ifaaan@gmail.com",
    "url": "https://github.com/inikulin",
    "path": "/builds/santyteam/santyrdv/node_modules/jsdom/node_modules/parse5",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/jsdom/node_modules/parse5/LICENSE"
  },
  "parse5@7.2.1": {
    "licenses": "MIT",
    "repository": "https://github.com/inikulin/parse5",
    "publisher": "Ivan Nikulin",
    "email": "ifaaan@gmail.com",
    "url": "https://github.com/inikulin",
    "path": "/builds/santyteam/santyrdv/node_modules/parse5",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/parse5/LICENSE"
  },
  "parseurl@1.3.3": {
    "licenses": "MIT",
    "repository": "https://github.com/pillarjs/parseurl",
    "path": "/builds/santyteam/santyrdv/node_modules/parseurl",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/parseurl/LICENSE"
  },
  "path-to-regexp@0.1.12": {
    "licenses": "MIT",
    "repository": "https://github.com/pillarjs/path-to-regexp",
    "path": "/builds/santyteam/santyrdv/node_modules/path-to-regexp",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/path-to-regexp/LICENSE"
  },
  "performance-now@2.1.0": {
    "licenses": "MIT",
    "repository": "https://github.com/braveg1rl/performance-now",
    "publisher": "Braveg1rl",
    "email": "braveg1rl@outlook.com",
    "path": "/builds/santyteam/santyrdv/node_modules/performance-now",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/performance-now/license.txt"
  },
  "prelude-ls@1.1.2": {
    "licenses": "MIT",
    "repository": "https://github.com/gkz/prelude-ls",
    "publisher": "George Zahariev",
    "email": "z@georgezahariev.com",
    "path": "/builds/santyteam/santyrdv/node_modules/escodegen/node_modules/prelude-ls",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/escodegen/node_modules/prelude-ls/LICENSE"
  },
  "promise-polyfill@8.1.0": {
    "licenses": "MIT",
    "repository": "https://github.com/taylorhakes/promise-polyfill",
    "publisher": "Taylor Hakes",
    "path": "/builds/santyteam/santyrdv/node_modules/promise-polyfill",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/promise-polyfill/LICENSE"
  },
  "proxy-addr@2.0.7": {
    "licenses": "MIT",
    "repository": "https://github.com/jshttp/proxy-addr",
    "publisher": "Douglas Christopher Wilson",
    "email": "doug@somethingdoug.com",
    "path": "/builds/santyteam/santyrdv/node_modules/proxy-addr",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/proxy-addr/LICENSE"
  },
  "psl@1.15.0": {
    "licenses": "MIT",
    "repository": "https://github.com/lupomontero/psl",
    "publisher": "Lupo Montero",
    "email": "lupomontero@gmail.com",
    "url": "https://lupomontero.com/",
    "path": "/builds/santyteam/santyrdv/node_modules/psl",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/psl/LICENSE"
  },
  "punycode@2.3.1": {
    "licenses": "MIT",
    "repository": "https://github.com/mathiasbynens/punycode.js",
    "publisher": "Mathias Bynens",
    "url": "https://mathiasbynens.be/",
    "path": "/builds/santyteam/santyrdv/node_modules/uri-js/node_modules/punycode",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/uri-js/node_modules/punycode/LICENSE-MIT.txt"
  },
  "qs@6.13.0": {
    "licenses": "BSD-3-Clause",
    "repository": "https://github.com/ljharb/qs",
    "path": "/builds/santyteam/santyrdv/node_modules/qs",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/qs/LICENSE.md"
  },
  "qs@6.5.3": {
    "licenses": "BSD-3-Clause",
    "repository": "https://github.com/ljharb/qs",
    "path": "/builds/santyteam/santyrdv/node_modules/request/node_modules/qs",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/request/node_modules/qs/LICENSE.md"
  },
  "quill-delta@5.1.0": {
    "licenses": "MIT",
    "repository": "https://github.com/quilljs/delta",
    "publisher": "Jason Chen",
    "email": "jhchen7@gmail.com",
    "path": "/builds/santyteam/santyrdv/node_modules/quill-delta",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/quill-delta/LICENSE"
  },
  "quill@2.0.3": {
    "licenses": "BSD-3-Clause",
    "repository": "https://github.com/slab/quill",
    "publisher": "Jason Chen",
    "email": "jhchen7@gmail.com",
    "path": "/builds/santyteam/santyrdv/node_modules/quill",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/quill/LICENSE"
  },
  "range-parser@1.2.1": {
    "licenses": "MIT",
    "repository": "https://github.com/jshttp/range-parser",
    "publisher": "TJ Holowaychuk",
    "email": "tj@vision-media.ca",
    "url": "http://tjholowaychuk.com",
    "path": "/builds/santyteam/santyrdv/node_modules/range-parser",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/range-parser/LICENSE"
  },
  "raw-body@2.5.2": {
    "licenses": "MIT",
    "repository": "https://github.com/stream-utils/raw-body",
    "publisher": "Jonathan Ong",
    "email": "me@jongleberry.com",
    "url": "http://jongleberry.com",
    "path": "/builds/santyteam/santyrdv/node_modules/raw-body",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/raw-body/LICENSE"
  },
  "request@2.88.2": {
    "licenses": "Apache-2.0",
    "repository": "https://github.com/request/request",
    "publisher": "Mikeal Rogers",
    "email": "mikeal.rogers@gmail.com",
    "path": "/builds/santyteam/santyrdv/node_modules/request",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/request/LICENSE"
  },
  "rgbcolor@1.0.1": {
    "licenses": "MIT*",
    "repository": "https://github.com/yetzt/node-rgbcolor",
    "publisher": "Sebastian Vollnhals",
    "email": "sebastian@vollnhals.info",
    "path": "/builds/santyteam/santyrdv/node_modules/rgbcolor",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/rgbcolor/LICENSE.md"
  },
  "robust-predicates@3.0.2": {
    "licenses": "Unlicense",
    "repository": "https://github.com/mourner/robust-predicates",
    "publisher": "Vladimir Agafonkin",
    "path": "/builds/santyteam/santyrdv/node_modules/robust-predicates",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/robust-predicates/LICENSE"
  },
  "rw@1.3.3": {
    "licenses": "BSD-3-Clause",
    "repository": "https://github.com/mbostock/rw",
    "publisher": "Mike Bostock",
    "url": "http://bost.ocks.org/mike",
    "path": "/builds/santyteam/santyrdv/node_modules/rw",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/rw/LICENSE"
  },
  "rxjs@7.8.2": {
    "licenses": "Apache-2.0",
    "repository": "https://github.com/reactivex/rxjs",
    "publisher": "Ben Lesh",
    "email": "ben@benlesh.com",
    "path": "/builds/santyteam/santyrdv/node_modules/rxjs",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/rxjs/LICENSE.txt"
  },
  "safe-buffer@5.2.1": {
    "licenses": "MIT",
    "repository": "https://github.com/feross/safe-buffer",
    "publisher": "Feross Aboukhadijeh",
    "email": "feross@feross.org",
    "url": "https://feross.org",
    "path": "/builds/santyteam/santyrdv/node_modules/safe-buffer",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/safe-buffer/LICENSE"
  },
  "safer-buffer@2.1.2": {
    "licenses": "MIT",
    "repository": "https://github.com/ChALkeR/safer-buffer",
    "publisher": "Nikita Skovoroda",
    "email": "chalkerx@gmail.com",
    "url": "https://github.com/ChALkeR",
    "path": "/builds/santyteam/santyrdv/node_modules/safer-buffer",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/safer-buffer/LICENSE"
  },
  "santyrdv@1.4.2": {
    "licenses": "UNLICENSED",
    "private": true,
    "path": "/builds/santyteam/santyrdv",
    "licenseFile": "/builds/santyteam/santyrdv/README.md"
  },
  "sax@1.4.1": {
    "licenses": "ISC",
    "repository": "https://github.com/isaacs/sax-js",
    "publisher": "Isaac Z. Schlueter",
    "email": "i@izs.me",
    "url": "http://blog.izs.me/",
    "path": "/builds/santyteam/santyrdv/node_modules/sax",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/sax/LICENSE"
  },
  "send@0.19.0": {
    "licenses": "MIT",
    "repository": "https://github.com/pillarjs/send",
    "publisher": "TJ Holowaychuk",
    "email": "tj@vision-media.ca",
    "path": "/builds/santyteam/santyrdv/node_modules/send",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/send/LICENSE"
  },
  "serve-static@1.16.2": {
    "licenses": "MIT",
    "repository": "https://github.com/expressjs/serve-static",
    "publisher": "Douglas Christopher Wilson",
    "email": "doug@somethingdoug.com",
    "path": "/builds/santyteam/santyrdv/node_modules/serve-static",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/serve-static/LICENSE"
  },
  "setprototypeof@1.2.0": {
    "licenses": "ISC",
    "repository": "https://github.com/wesleytodd/setprototypeof",
    "publisher": "Wes Todd",
    "path": "/builds/santyteam/santyrdv/node_modules/setprototypeof",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/setprototypeof/LICENSE"
  },
  "side-channel-list@1.0.0": {
    "licenses": "MIT",
    "repository": "https://github.com/ljharb/side-channel-list",
    "publisher": "Jordan Harband",
    "email": "ljharb@gmail.com",
    "path": "/builds/santyteam/santyrdv/node_modules/side-channel-list",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/side-channel-list/LICENSE"
  },
  "side-channel-map@1.0.1": {
    "licenses": "MIT",
    "repository": "https://github.com/ljharb/side-channel-map",
    "publisher": "Jordan Harband",
    "email": "ljharb@gmail.com",
    "path": "/builds/santyteam/santyrdv/node_modules/side-channel-map",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/side-channel-map/LICENSE"
  },
  "side-channel-weakmap@1.0.2": {
    "licenses": "MIT",
    "repository": "https://github.com/ljharb/side-channel-weakmap",
    "publisher": "Jordan Harband",
    "email": "ljharb@gmail.com",
    "path": "/builds/santyteam/santyrdv/node_modules/side-channel-weakmap",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/side-channel-weakmap/LICENSE"
  },
  "side-channel@1.1.0": {
    "licenses": "MIT",
    "repository": "https://github.com/ljharb/side-channel",
    "publisher": "Jordan Harband",
    "email": "ljharb@gmail.com",
    "path": "/builds/santyteam/santyrdv/node_modules/side-channel",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/side-channel/LICENSE"
  },
  "source-map@0.6.1": {
    "licenses": "BSD-3-Clause",
    "repository": "https://github.com/mozilla/source-map",
    "publisher": "Nick Fitzgerald",
    "email": "nfitzgerald@mozilla.com",
    "path": "/builds/santyteam/santyrdv/node_modules/escodegen/node_modules/source-map",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/escodegen/node_modules/source-map/LICENSE"
  },
  "sshpk@1.18.0": {
    "licenses": "MIT",
    "repository": "https://github.com/joyent/node-sshpk",
    "publisher": "Joyent, Inc",
    "path": "/builds/santyteam/santyrdv/node_modules/sshpk",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/sshpk/LICENSE"
  },
  "stackblur-canvas@1.4.1": {
    "licenses": "MIT",
    "repository": "https://github.com/flozz/StackBlur",
    "publisher": "Mario Klingemann",
    "path": "/builds/santyteam/santyrdv/node_modules/canvg/node_modules/stackblur-canvas",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/canvg/node_modules/stackblur-canvas/LICENSE"
  },
  "stackblur-canvas@2.2.0": {
    "licenses": "MIT",
    "repository": "https://github.com/flozz/StackBlur",
    "publisher": "Mario Klingemann",
    "path": "/builds/santyteam/santyrdv/node_modules/stackblur-canvas",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/stackblur-canvas/LICENSE-MIT.txt"
  },
  "statuses@2.0.1": {
    "licenses": "MIT",
    "repository": "https://github.com/jshttp/statuses",
    "path": "/builds/santyteam/santyrdv/node_modules/statuses",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/statuses/LICENSE"
  },
  "symbol-tree@3.2.4": {
    "licenses": "MIT",
    "repository": "https://github.com/jsdom/js-symbol-tree",
    "publisher": "Joris van der Wel",
    "email": "joris@jorisvanderwel.com",
    "path": "/builds/santyteam/santyrdv/node_modules/symbol-tree",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/symbol-tree/LICENSE"
  },
  "tinycolor2@1.6.0": {
    "licenses": "MIT",
    "repository": "https://github.com/bgrins/TinyColor",
    "publisher": "Brian Grinstead",
    "email": "briangrinstead@gmail.com",
    "url": "http://briangrinstead.com",
    "path": "/builds/santyteam/santyrdv/node_modules/tinycolor2",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/tinycolor2/LICENSE"
  },
  "tinygradient@1.1.5": {
    "licenses": "MIT",
    "repository": "https://github.com/mistic100/tinygradient",
    "publisher": "Damien \"Mistic\" Sorel",
    "email": "contact@git.strangeplanet.fr",
    "url": "https://www.strangeplanet.fr",
    "path": "/builds/santyteam/santyrdv/node_modules/tinygradient",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/tinygradient/LICENSE"
  },
  "toidentifier@1.0.1": {
    "licenses": "MIT",
    "repository": "https://github.com/component/toidentifier",
    "publisher": "Douglas Christopher Wilson",
    "email": "doug@somethingdoug.com",
    "path": "/builds/santyteam/santyrdv/node_modules/toidentifier",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/toidentifier/LICENSE"
  },
  "tough-cookie@2.5.0": {
    "licenses": "BSD-3-Clause",
    "repository": "https://github.com/salesforce/tough-cookie",
    "publisher": "Jeremy Stashewsky",
    "email": "jstash@gmail.com",
    "path": "/builds/santyteam/santyrdv/node_modules/tough-cookie",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/tough-cookie/LICENSE"
  },
  "tr46@0.0.3": {
    "licenses": "MIT",
    "repository": "https://github.com/Sebmaster/tr46.js",
    "publisher": "Sebastian Mayr",
    "email": "npm@smayr.name",
    "path": "/builds/santyteam/santyrdv/node_modules/tr46"
  },
  "tslib@2.8.1": {
    "licenses": "0BSD",
    "repository": "https://github.com/Microsoft/tslib",
    "publisher": "Microsoft Corp.",
    "path": "/builds/santyteam/santyrdv/node_modules/tslib",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/tslib/LICENSE.txt"
  },
  "tunnel-agent@0.6.0": {
    "licenses": "Apache-2.0",
    "repository": "https://github.com/mikeal/tunnel-agent",
    "publisher": "Mikeal Rogers",
    "email": "mikeal.rogers@gmail.com",
    "url": "http://www.futurealoof.com",
    "path": "/builds/santyteam/santyrdv/node_modules/tunnel-agent",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/tunnel-agent/LICENSE"
  },
  "tweetnacl@0.14.5": {
    "licenses": "Unlicense",
    "repository": "https://github.com/dchest/tweetnacl-js",
    "publisher": "TweetNaCl-js contributors",
    "path": "/builds/santyteam/santyrdv/node_modules/tweetnacl",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/tweetnacl/LICENSE"
  },
  "type-check@0.3.2": {
    "licenses": "MIT",
    "repository": "https://github.com/gkz/type-check",
    "publisher": "George Zahariev",
    "email": "z@georgezahariev.com",
    "path": "/builds/santyteam/santyrdv/node_modules/escodegen/node_modules/type-check",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/escodegen/node_modules/type-check/LICENSE"
  },
  "type-is@1.6.18": {
    "licenses": "MIT",
    "repository": "https://github.com/jshttp/type-is",
    "path": "/builds/santyteam/santyrdv/node_modules/type-is",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/type-is/LICENSE"
  },
  "unpipe@1.0.0": {
    "licenses": "MIT",
    "repository": "https://github.com/stream-utils/unpipe",
    "publisher": "Douglas Christopher Wilson",
    "email": "doug@somethingdoug.com",
    "path": "/builds/santyteam/santyrdv/node_modules/unpipe",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/unpipe/LICENSE"
  },
  "uri-js@4.4.1": {
    "licenses": "BSD-2-Clause",
    "repository": "https://github.com/garycourt/uri-js",
    "publisher": "Gary Court",
    "email": "gary.court@gmail.com",
    "path": "/builds/santyteam/santyrdv/node_modules/uri-js",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/uri-js/LICENSE"
  },
  "utils-merge@1.0.1": {
    "licenses": "MIT",
    "repository": "https://github.com/jaredhanson/utils-merge",
    "publisher": "Jared Hanson",
    "email": "jaredhanson@gmail.com",
    "url": "http://www.jaredhanson.net/",
    "path": "/builds/santyteam/santyrdv/node_modules/utils-merge",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/utils-merge/LICENSE"
  },
  "uuid@3.4.0": {
    "licenses": "MIT",
    "repository": "https://github.com/uuidjs/uuid",
    "path": "/builds/santyteam/santyrdv/node_modules/uuid",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/uuid/LICENSE.md"
  },
  "validator@13.11.0": {
    "licenses": "MIT",
    "repository": "https://github.com/validatorjs/validator.js",
    "publisher": "Chris O'Hara",
    "email": "cohara87@gmail.com",
    "path": "/builds/santyteam/santyrdv/node_modules/validator",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/validator/LICENSE"
  },
  "vary@1.1.2": {
    "licenses": "MIT",
    "repository": "https://github.com/jshttp/vary",
    "publisher": "Douglas Christopher Wilson",
    "email": "doug@somethingdoug.com",
    "path": "/builds/santyteam/santyrdv/node_modules/vary",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/vary/LICENSE"
  },
  "verror@1.10.0": {
    "licenses": "MIT",
    "repository": "https://github.com/davepacheco/node-verror",
    "path": "/builds/santyteam/santyrdv/node_modules/verror",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/verror/LICENSE"
  },
  "webidl-conversions@3.0.1": {
    "licenses": "BSD-2-Clause",
    "repository": "https://github.com/jsdom/webidl-conversions",
    "publisher": "Domenic Denicola",
    "email": "d@domenic.me",
    "url": "https://domenic.me/",
    "path": "/builds/santyteam/santyrdv/node_modules/webidl-conversions",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/webidl-conversions/LICENSE.md"
  },
  "whatwg-url@2.0.1": {
    "licenses": "MIT",
    "repository": "https://github.com/jsdom/whatwg-url",
    "publisher": "Sebastian Mayr",
    "email": "github@smayr.name",
    "path": "/builds/santyteam/santyrdv/node_modules/whatwg-url",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/whatwg-url/LICENSE.txt"
  },
  "word-wrap@1.2.5": {
    "licenses": "MIT",
    "repository": "https://github.com/jonschlinkert/word-wrap",
    "publisher": "Jon Schlinkert",
    "url": "https://github.com/jonschlinkert",
    "path": "/builds/santyteam/santyrdv/node_modules/word-wrap",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/word-wrap/LICENSE"
  },
  "xhr2@0.2.1": {
    "licenses": "MIT",
    "repository": "https://github.com/pwnall/node-xhr2",
    "publisher": "Victor Costan",
    "email": "victor@costan.us",
    "url": "http://www.costan.us",
    "path": "/builds/santyteam/santyrdv/node_modules/xhr2",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/xhr2/LICENSE.txt"
  },
  "xml-name-validator@2.0.1": {
    "licenses": "WTFPL",
    "repository": "https://github.com/jsdom/xml-name-validator",
    "publisher": "Domenic Denicola",
    "email": "d@domenic.me",
    "url": "https://domenic.me/",
    "path": "/builds/santyteam/santyrdv/node_modules/xml-name-validator",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/xml-name-validator/LICENSE.txt"
  },
  "xmldom@0.1.31": {
    "licenses": "MIT*",
    "repository": "https://github.com/xmldom/xmldom",
    "publisher": "jindw",
    "email": "jindw@xidea.org",
    "url": "http://www.xidea.org",
    "path": "/builds/santyteam/santyrdv/node_modules/xmldom",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/xmldom/LICENSE"
  },
  "zone.js@0.15.0": {
    "licenses": "MIT",
    "repository": "https://github.com/angular/angular",
    "publisher": "Brian Ford",
    "path": "/builds/santyteam/santyrdv/node_modules/zone.js",
    "licenseFile": "/builds/santyteam/santyrdv/node_modules/zone.js/LICENSE"
  }
}
