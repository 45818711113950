export enum StatusRdv {
  PENDING = 'PENDING',
  IN_PROGRESS = 'IN_PROGRESS',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
  TERMINATED = 'TERMINATED',
  CANCELED = 'CANCELED'
}

export enum RdvStatusColor {
  PENDING = '#eef908',
  ACCEPTED = '#0b72f5',
  IN_PROGRESS = '#b51cfc',
  TERMINATED = '#2cd90c',
  CANCELED = '#f58e0b',
  REJECTED = '#dc2d4d'
}

export enum RdvType {
  RDV = 'rdv',
  EVENT = 'event'
}

export enum RdvStep {
  DATE = 1,
  RAISON = 2,
  RESUME = 3
}

export enum RdvAffectedStatus {
  ALL = 'ALL',
  MINE = 'MINE',
  UNAFFECTED = 'UNAFFECTED'
}
