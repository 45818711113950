import { inject, Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { RouterStateSnapshot, TitleStrategy } from '@angular/router';
import { TraductorService } from '@shared/services/traductor.service';

@Injectable({ providedIn: 'root' })
export class AppPageTitleStrategy extends TitleStrategy {
  private readonly traductor = inject(TraductorService);
  private readonly title = inject(Title);

  override updateTitle(routerState: RouterStateSnapshot) {
    const title = this.buildTitle(routerState);
    if (title !== undefined) {
      this.title.setTitle(
        `Santynelle RDV | ${this.traductor.translate(title)}`
      );
    }
  }
}
