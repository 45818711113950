import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  inject,
} from '@angular/core';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { IconName } from '@san/tools/models';
import { SanButtonIconComponent } from '@san/tools/ui/button-icon';
import { BaseDialogComponent } from '@san/tools/ui/dialog';
import { SanModalComponent } from '@san/tools/ui/modal';
import { SanSlideToggleComponent } from '@san/tools/ui/slide-toggle';
import { filter } from 'rxjs';
import { ReactiveService } from 'src/app/shared/services/reactive.service';
import { Utilisateur } from '@shared/models/entity/Utilisateur';
import { UserService } from '@shared/services/user.service';

@Component({
  selector: 'rdv-notification-settings',
  templateUrl: './notification-settings.component.html',
  styleUrls: ['./notification-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    SanModalComponent,
    FormsModule,
    ReactiveFormsModule,
    SanButtonIconComponent,
    SanSlideToggleComponent,
    FlexLayoutModule,
  ],
})
export class NotificationSettingsComponent
  extends BaseDialogComponent
  implements OnInit
{
  private readonly userService = inject(UserService);
  private readonly reactiveService = inject(ReactiveService);

  protected readonly IconName: typeof IconName = IconName;
  protected notifForm: FormGroup;
  protected readonly user = this.userService.connected();

  ngOnInit(): void {
    this.notifForm = new FormGroup({
      notif_app: new FormControl(this.user?.notif_app),
      notif_sms: new FormControl(this.user?.notif_sms),
    });

    if (this.isEntityManager) {
      this.notifForm.addControl(
        'notif_rdv',
        new FormControl((this.user as Utilisateur)?.notif_rdv)
      );
    }
  }

  protected get isEntityManager() {
    return this.userService.isEntityManager();
  }

  protected validate() {
    const request = this.notifForm.getRawValue();
    this.reactiveService.call(
      this.userService
        .updateNotificationSettings(this.userService.connectedUserType(), {
          ...request,
          id: this.user.id,
        })
        .pipe(filter((res) => res.data === true)),
      () => {
        this.user.notif_app = request.notif_app;
        this.user.notif_sms = request.notif_sms;
        if (this.isEntityManager) {
          (this.user as Utilisateur).notif_rdv = request.notif_rdv;
        }
        this.userService.setConnectedUser(this.user);
        this.close({ content: true });
      },
      true
    );
  }
}
