import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { FileUploadDialogContent, IconName } from '@san/tools/models';
import { SanUploadDialogComponent } from '@san/tools/ui/file-upload';
import { BaseComponent } from '@san/tools/utils';
import { filter, map, take, takeUntil } from 'rxjs';
import { PatientService } from '../../api/patient.service';
import { ProService } from '../../api/pro.service';
import { Utilisateur } from '../../models/entity/Utilisateur';
import { Patient } from '../../models/entity/patient';
import { AppConf } from '../../models/interfaces/app-conf.interface';
import { FileDataResponse, FileDestination } from '../../models/interfaces/file.interface';
import { DialogService } from '../../services/dialog.service';
import { FileService } from '../../services/file.service';
import { ReactiveService } from '../../services/reactive.service';
import { AppconfService } from './../../services/app-conf.service';

@Component({
  selector: 'rdv-photo',
  templateUrl: './photo.component.html',
  styleUrls: ['./photo.component.scss']
})
export class PhotoComponent extends BaseComponent {

  private proService = inject(ProService);
  private reactiveService = inject(ReactiveService);
  private patientService = inject(PatientService);
  private dialogService = inject(DialogService);
  private appConfService = inject(AppconfService);

  @Input() user: Utilisateur | Patient;
  @Input() isPatient: boolean = true;
  @Input() editable: boolean = false;
  @Output() photoChange: EventEmitter<Utilisateur | Patient> = new EventEmitter<Utilisateur | Patient>;
  IconName = IconName;
  conf: AppConf;

  constructor() {
    super();
    this.conf = this.appConfService.getAppConf();
  }

  get destitation() {
    return this.isPatient ? FileDestination.PATIENT : FileDestination.USER;
  }

  openEditionModal() {
    this.dialogService.open(SanUploadDialogComponent, {
      data: {
        header: 'shared.photo',
        content: {
          extensions: this.appConfService.imageExtensions,
          uploadFn: (files: File[]) => {
            const data = new FormData();
            data.append('uuid', this.user.id);
            data.append('destination', this.destitation);
            data.append('photo', files[0], files[0].name);
            const ws = !this.isPatient ? this.proService.updatePhoto(data) : this.patientService.updatePhoto(data);
            return ws.pipe(filter(FileService.filterFileResponse));
          }
        } as FileUploadDialogContent
      }
    }).closed.pipe(
      takeUntil(this.destroy),
      take(1),
      filter(res => !!res?.content),
      map(res => res.content?.response?.data as FileDataResponse)
    ).subscribe(file => {
      this.user.fichier = file.name;
      this.photoChange.next(this.user);
    });
  }


  deletePhoto() {
    if (this.user?.fichier?.length) {
      const ws = !this.isPatient ? this.proService.deletePhoto(this.user.id) : this.patientService.deletePhoto(this.user.id);
      this.reactiveService.call(ws.pipe(take(1)), () => {
        this.user.fichier = null;
        this.photoChange.next(this.user);
      }, true);
    }
  }
}
