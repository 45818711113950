import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { SearchCriteria, SearchParam } from '@san/tools/models';
import { Observable } from 'rxjs';
import { ApiResponse } from '../models/dto/response.dto';
import { EtablissementHoraire } from '../models/entity/EtablissementHoraire';
import { Etablissement } from '../models/entity/etablissement';
import { Medecin } from '../models/entity/medecin';
import { Patient } from '../models/entity/patient';
import { Prestation } from '../models/entity/prestation';
import { Etat } from '../models/enum/state.enum';
import { StatResponse } from '../models/interfaces/rdv.interface';
import { SearchUserParam } from '../models/interfaces/search.interface';
import { RdvSettingsRequest } from '../models/request/medecin.request';
import { PositionRequest, SimplesRequest, StatsRequest } from '../models/request/simple.request';
import { ApiBaseService } from './api-base.service';

@Injectable({
  providedIn: 'root'
})
export class EtablissementService extends ApiBaseService {

  constructor(override http: HttpClient) {
    super(http, 'etablissements');
  }

  /**
   * Ajoute/modifie un etablissement
   * @param etablissement
   * @returns
   */
  save(etablissement: Partial<Etablissement>): Observable<ApiResponse<Etablissement>> {
    return this.http.post<ApiResponse<Etablissement>>(this.baseUrl, etablissement);
  }

  /**
   * Renvoie un etablissement
   * @param id
   * @param relations
   * @returns
   */
  get(id: string, relations?: string[]): Observable<ApiResponse<Etablissement>> {
    return this.http.post<ApiResponse<Etablissement>>(`${this.baseUrl}/find`, { id, relations });
  }

  updateSimples(request: SimplesRequest): Observable<ApiResponse<boolean>> {
    return this.http.post<ApiResponse<boolean>>(`${this.baseUrl}/update-simples`, request);
  }

  savePrestation(request: Partial<Prestation>): Observable<ApiResponse<Prestation>> {
    return this.http.post<ApiResponse<Prestation>>(`${this.baseUrl}/save-prestation`, request);
  }

  togglePrestation(id: string, actif: boolean): Observable<ApiResponse<boolean>> {
    const state = actif ? Etat.ACTIF : Etat.INACTIF;
    return this.http.post<ApiResponse<boolean>>(`${this.baseUrl}/toggle-prestation/${id}/${state}`, null);
  }

  deletePrestation(id: string): Observable<ApiResponse<boolean>> {
    return this.http.delete<ApiResponse<boolean>>(`${this.baseUrl}/delete-prestation/${id}`);
  }

  updateSettings(request: RdvSettingsRequest): Observable<ApiResponse<boolean>> {
    return this.http.post<ApiResponse<boolean>>(`${this.baseUrl}/update-rdv-settings`, request);
  }

  getPatients(param?: SearchCriteria<SearchUserParam>): Observable<ApiResponse<Patient[]>> {
    return this.http.post<ApiResponse<Patient[]>>(`${this.baseUrl}/get/patients`, param);
  }

  getPrestations(active = Etat.ACTIF): Observable<ApiResponse<Prestation[]>> {
    return this.http.post<ApiResponse<Prestation[]>>(`${this.baseUrl}/get/prestations?active=${active}`, null);
  }

  getMedecins(param: SearchParam): Observable<ApiResponse<Medecin[]>> {
    return this.http.post<ApiResponse<Medecin[]>>(`${this.baseUrl}/get/medecins`, param);
  }

  saveHoraire(request: EtablissementHoraire): Observable<ApiResponse<EtablissementHoraire>> {
    return this.http.post<ApiResponse<EtablissementHoraire>>(`${this.baseUrl}/save-horaire`, request);
  }

  savePosition(request: PositionRequest): Observable<ApiResponse<boolean>> {
    return this.http.post<ApiResponse<boolean>>(`${this.baseUrl}/save-position`, request);
  }

  toggleHoraire(id: number, actif: boolean): Observable<ApiResponse<boolean>> {
    const state = actif ? Etat.ACTIF : Etat.INACTIF;
    return this.http.put<ApiResponse<boolean>>(`${this.baseUrl}/toggle-horaire/${id}/${state}`, null);
  }

  getStats(request?: StatsRequest): Observable<ApiResponse<StatResponse>> {
    return this.http.post<ApiResponse<StatResponse>>(`${this.baseUrl}/get/stats`, request);
  }
}
