<div class="user-resume" class="full-width" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="5px" *ngIf="pro">
  <div fxLayout="row wrap" fxLayoutAlign="start start" fxLayoutGap="5px">
    <rdv-photo [user]="pro" [isPatient]="false" [editable]="false"></rdv-photo>
    <div class="info small-padding">
      <div><label>{{'account.name' | translate}}</label> : <span class="font-bold">{{pro | fullName}}</span>
      </div>
      <div><label>{{'profil-medecin.etablissement' | translate}}</label> :
        <span>{{pro?.etablissement?.libelle|displayValue}}</span>
      </div>
      <div><label>{{'profil-medecin.edit-etablissement.ville' | translate}}</label> :
        <span>
          {{pro?.etablissement?.ville?.libelle | displayValue}} /
          {{pro?.etablissement?.commune?.libelle | displayValue}}
        </span>
      </div>
      <div><label>{{'profil-medecin.specialites' | translate}}</label> :
        <span>{{pro?.medecin.specialites|displayList}}</span>
      </div>
    </div>
  </div>
  <div fxLayout="row wrap" class="full-width" fxLayoutAlign="end start">
    <san-button-icon id="profil-medecin-btn-{{pro.id}}" label="{{'profil-medecin.afficher-profil' | translate}}"
      color="accent" [icon]="IconName.EYE" [borderless]="true" (onclick)="openProfile()"></san-button-icon>

    <san-button-icon *ngIf="showRdvButton" id="rdv-medecin-btn-{{pro.id}}" label="{{'rdv.meeting' | translate}}"
      color="accent" [borderless]="true" [icon]="IconName.TIME" (onclick)="openBooking()"></san-button-icon>
  </div>
</div>