import { Component, Input, inject } from '@angular/core';
import { IconName } from '@san/tools/models';
import { Etablissement } from '../../models/entity/etablissement';
import { Medecin } from '../../models/entity/medecin';
import { RdvStep } from '../../models/enum/rdv.enum';
import { FileDestination } from '../../models/interfaces/file.interface';
import { NavigationService } from '../../services/navigation.service';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'rdv-reservation-bloc',
  templateUrl: './reservation-bloc.component.html',
  styleUrls: ['./reservation-bloc.component.scss']
})
export class ReservationBlocComponent {

  private navigationService = inject(NavigationService);

  pro: Medecin;

  @Input() etablissement: Etablissement;
  @Input() set medecin(medecin: Medecin) {
    this.pro = medecin;
    this.pro.utilisateur.medecin = medecin;
  }
  IconName = IconName;
  FileDestination = FileDestination;

  get isPro() {
    return !!this.pro?.id;
  }

  get header() {
    return this.isPro ? UserService.getFullName(this.pro.utilisateur) : this.etablissement.libelle;
  }

  get id() {
    return this.pro?.id ?? this.etablissement?.id?.toString();
  }

  openBooking(date: Date) {
    this.navigationService.openBooking(this.pro, this.isPro ? null : this.etablissement, RdvStep.RAISON, null, date);
  }

}
