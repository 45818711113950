import { Injectable } from '@angular/core';
import { SanDateService } from '@san/tools/services';
import { DateTime, Duration } from 'luxon';

@Injectable({
  providedIn: 'root',
})
export class DateService extends SanDateService {

  longDate(date: Date = new Date(), hours: boolean = false): string {
    const format = hours ? DateTime.DATETIME_MED_WITH_WEEKDAY : DateTime.DATE_MED_WITH_WEEKDAY;
    return DateService.dateTime(date).setLocale(this.translationService.defaultLang).toLocaleString(format);
  }

  static format(date: Date = new Date(), hours: boolean, seconds?: boolean): string {
    let format = 'dd/MM/yyyy';
    if (hours) {
      format = `${format} T`;
    }
    if (seconds) {
      format = `${format} TT`;
    }
    return DateService.dateTime(date).toFormat(format);
  }

  static trunkHours(date: Date) {
    date.setSeconds(0, 0);
    return date;
  }

  static formatHours(date: Date = new Date(), seconds?: boolean): string {
    const format = !seconds ? 'T' : 'TT';
    return DateService.dateTime(date).toFormat(format);
  }

  static formatPeriodHours(start: Date, end: Date, seconds?: boolean): string {
    if (start && end) {
      return `${DateService.formatHours(start, seconds)} -  ${DateService.formatHours(end, seconds)}`;
    }
    return '';
  }

  static minToTime(min: number = 0): Date {
    const date = new Date();
    const duration = Duration.fromObject({ minutes: min });
    date.setHours(duration.hours, duration.minutes, duration.seconds);
    return date;
  }

  static timeToMin(time: Date = new Date()): number {
    if (time) {
      return time.getHours() * 60 + time.getMinutes();
    }
    return 0;
  }

  static addMinToDate(date: Date, minutes: number): Date {
    return DateService.dateTime(date).plus({ minutes }).toJSDate();
  }

  static firstDateOfYear(): Date {
    return DateService.dateTime().startOf('year').toJSDate();
  }

  static lastDateOfYear(): Date {
    return DateService.dateTime().endOf('year').toJSDate();
  }

  static currentSemesterPeriod() {
    const month = DateService.currentMonth();
    const middleDate = DateService.dateAfterMonths(5, DateService.firstDateOfYear());
    if (month < 6) {
      return { debut: DateService.firstDateOfYear(), fin: DateService.lastDayOfMonth(middleDate) };
    }
    return { debut: DateService.firstDayOfMonth(middleDate), fin: DateService.lastDateOfYear() };
  }

  static currentTrimesterPeriod() {
    const month = DateService.currentMonth();
    let refDate = DateService.firstDateOfYear();
    if (month > 9) {
      refDate = DateService.dateAfterMonths(9, refDate);
    } else if (month > 6) {
      refDate = DateService.dateAfterMonths(6, refDate);
    } else if (month > 3) {
      refDate = DateService.dateAfterMonths(3, refDate);
    }
    return { debut: DateService.firstDayOfMonth(refDate), fin: DateService.lastDayOfMonth(DateService.dateAfterMonths(2, refDate)) };
  }

}
