import { IMAGE_CONFIG } from '@angular/common';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  provideHttpClient,
  withFetch,
  withInterceptorsFromDi,
} from '@angular/common/http';
import {
  ApplicationConfig,
  importProvidersFrom,
  inject,
  isDevMode,
  provideAppInitializer,
  provideZoneChangeDetection,
} from '@angular/core';
import { MAT_RIPPLE_GLOBAL_OPTIONS } from '@angular/material/core';
import {
  BrowserModule,
  provideClientHydration,
  withEventReplay,
} from '@angular/platform-browser';
import {
  BrowserAnimationsModule,
  provideAnimations,
} from '@angular/platform-browser/animations';
import { provideRouter, TitleStrategy } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { DateInterceptor } from '@core/interceptors/date.interceptor';
import { ErrorInterceptor } from '@core/interceptors/error.interceptor';
import { RequestInterceptor } from '@core/interceptors/url.interceptor';
import { AppPageTitleStrategy } from '@core/services/app.title.strategy';
import { provideLoadingBarInterceptor } from '@ngx-loading-bar/http-client';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { PubliqueService } from '@shared/api/publique.service';
import { AppconfService } from '@shared/services/app-conf.service';
import { ApplicationService } from '@shared/services/application.service';
import { SsrService } from '@shared/services/ssr.service';
import { TraductorService } from '@shared/services/traductor.service';
import { UserService } from '@shared/services/user.service';
import { ToastrModule } from 'ngx-toastr';
import { appRoutes } from './app.routes';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideAppInitializer(() =>
      AppconfService.initialize(
        inject(AppconfService),
        inject(ApplicationService),
        inject(PubliqueService),
        inject(UserService),
        inject(TraductorService),
        inject(SsrService)
      )
    ),
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(appRoutes),
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi(), withFetch()),
    { provide: TitleStrategy, useClass: AppPageTitleStrategy },
    { provide: IMAGE_CONFIG, useValue: { disableImageSizeWarning: true } },
    { provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: { disabled: true } },
    { provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: DateInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    importProvidersFrom(
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: createTranslateLoader,
          deps: [HttpClient],
        },
      }),
      ServiceWorkerModule.register('ngsw-worker.js', {
        enabled: !isDevMode(),
        registrationStrategy: 'registerWhenStable:30000',
      }),
      ToastrModule.forRoot({
        preventDuplicates: true,
        autoDismiss: true,
        closeButton: true,
        enableHtml: true,
        positionClass: 'toast-bottom-right',
      }),
      BrowserModule,
      BrowserAnimationsModule
    ),
    provideClientHydration(withEventReplay()),
    provideLoadingBarInterceptor(),
  ],
};
