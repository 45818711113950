export enum StatusCode {
  OK = 200,
  NOT_VERIFIED = 203,
  NO_CONTENT = 204,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  METHOD_NOT_ALLOWED = 405,
  DUPLICATED = 409,
  DUPLICATED_EVENT = 410,
  INVALID_EVENT_PERIOD = 4101,
  VALIDATION_FAILED = 412,
  CUSTOM_ERROR = 411,
  PAYLOAD_TOO_LARGE = 413,
  UNSUPPORTED_FILE_TYPE = 415,
  BAD_FILES_REQUEST = 414,
  FILE_TOO_LOURD = 431,
  TOO_MANY_FILES = 429,
  FAILED = 417,
  LOCKED = 423,
  NEED_CHANGES = 426,
  INTERNAL_SERVER_ERROR = 500,
  DESACTIVATED = 501,
  SERVICE_UNAVAILABLE = 503,
  INVALID_TOKEN = 511
  /*
  CREATED = 201,
  ACCEPTED = 202,
  NO_CONTENT = 204,
  MULTI_STATUS = 207,
  ALREADY_REPORTED = 208,
  IM_USED = 226,
  MULTIPLE_CHOICES = 300,
  MOVED_PERMANENTLY = 301,
  FOUND = 302,
  SEE_OTHER = 303,
  NOT_MODIFIED = 304,
  USE_PROXY = 305,
  SWITCH_PROXY = 306,
  TEMPORARY_REDIRECT = 307,
  PERMANENT_REDIRECT = 308,
  PAYMENT_REQUIRED = 402,
  NOT_ACCEPTABLE = 406,
  RANGE_NOT_SATISFIABLE = 416,
  FAILED_DEPENDENCY = 424,
  PRECONDITION_REQUIRED = 428,
  TOO_MANY_REQUESTS = 429,
  REQUEST_HEADER_FIELDS_TOO_LARGE = 431,
  UNAVAILABLE_FOR_LEGAL_REASONS = 451,
  NOT_IMPLEMENTED = 501,
  BAD_GATEWAY = 502,
  GATEWAY_TIMEOUT = 504,
  HTTP_VERSION_NOT_SUPPORTED = 505,
  VARIANT_ALSO_NEGOTIATES = 506,
  INSUFFICIENT_STORAGE = 507,
  LOOP_DETECTED = 508,
  NOT_EXTENDED = 510,
  NETWORK_AUTHENTICATION_REQUIRED = 511*/
}
