import { NgFor } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { AppConf } from '@app/shared/models/interfaces/app-conf.interface';
import { AppconfService } from '@app/shared/services/app-conf.service';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { TranslatePipe } from '@ngx-translate/core';
import { IconName } from '@san/tools/models';
import { SanButtonComponent } from '@san/tools/ui/button';
import { BaseDialogComponent } from '@san/tools/ui/dialog';
import { SanModalComponent } from '@san/tools/ui/modal';
import { SanTabComponent, SanTabsComponent } from '@san/tools/ui/tabs';
import { get } from 'lodash-es';
import licences from '../../../../assets/json/licenses.json';

type Licence = {
  name: string;
  version: string;
  fullName: string;
  licenses: string;
  repository: string;
  publisher: string;
};

@Component({
  selector: 'rdv-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgFor,
    SanModalComponent,
    SanTabComponent,
    SanTabsComponent,
    SanButtonComponent,
    TranslatePipe,
    FlexLayoutModule,
  ],
})
export class AboutComponent extends BaseDialogComponent {
  protected readonly appConf: AppConf = inject(AppconfService).getAppConf();
  protected readonly IconName: typeof IconName = IconName;
  protected readonly libs: Licence[] = Object.keys(licences)?.map((key) => {
    const versionIndex = key.lastIndexOf('@');
    const licence = get(licences, key);
    return {
      name: key.substring(0, versionIndex),
      version: key.substring(versionIndex + 1),
      fullName: key,
      licenses: licence.licenses ?? 'Private',
      repository: licence.repository,
      publisher: licence.publisher ?? 'Private',
    };
  });
}
