import { inject, Injectable } from '@angular/core';
import { AccountType } from '@shared/models/enum/user-shared.enum';
import { NavigationService } from '@shared/services/navigation.service';
import { SsrService } from '@shared/services/ssr.service';
import { UserService } from '@shared/services/user.service';

@Injectable({ providedIn: 'root' })
export class TokenVerificationService {
  private readonly navigationService = inject(NavigationService);
  private readonly userService = inject(UserService);
  private readonly ssrService = inject(SsrService);

  canActivateRoute(isPro: boolean) {
    if (this.ssrService.isBrowser()) {
      const url = this.loginUrl(isPro);
      if (this.connected(isPro)) {
        return true;
      }
      this.navigationService.navigate(url);
    }
    return false;
  }

  private connected(isPro: boolean) {
    if (isPro) {
      return this.userService.proIsConnected();
    }
    return this.userService.patientIsConnected();
  }

  private loginUrl(isPro: boolean): string {
    const type = isPro ? AccountType.PRO : AccountType.PATIENT;
    return `/account/${type}/login`;
  }
}
