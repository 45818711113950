import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { IconName } from '@san/tools/models';
import { SanButtonIconComponent } from '@san/tools/ui/button-icon';
import { SanIconComponent } from '@san/tools/ui/icon';

@Component({
  selector: 'rdv-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    SanButtonIconComponent,
    TranslateModule,
    SanIconComponent,
    RouterLink,
    FlexLayoutModule
  ]
})
export class PageNotFoundComponent {
  IconName: typeof IconName = IconName;
}
