import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { TranslatePipe } from '@ngx-translate/core';
import { IconName } from '@san/tools/models';
import { SanIconComponent } from '@san/tools/ui/icon';
import { AppConf } from '@shared/models/interfaces/app-conf.interface';
import { AppconfService } from '@shared/services/app-conf.service';
import { DialogService } from '@shared/services/dialog.service';
import { version } from 'package.json';
import { AboutComponent } from '../about/about.component';

@Component({
  selector: 'rdv-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [SanIconComponent, TranslatePipe, FlexLayoutModule],
})
export class FooterComponent {
  private readonly dialogService = inject(DialogService);

  protected readonly conf: AppConf = inject(AppconfService).getAppConf();
  protected readonly IconName: typeof IconName = IconName;
  protected readonly version = version;

  protected openAboutDialog() {
    this.dialogService.open(AboutComponent, { width: '700px' });
  }
}
