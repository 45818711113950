<div class="wrapper">
  <div class="header">
    <rdv-header *ngIf="appService.initialized()"></rdv-header>
  </div>
  <div
    *ngIf="!appService.initialized()"
    class="fullVh"
    fxLayout="column"
    fxLayoutAlign="center center"
  >
    <ngx-skeleton-loader
      appearance="circle"
      [theme]="{ background: 'rgba(var(--accent-color-rgb),.5)' }"
      [count]="5"
    />
  </div>
  <div
    [isLoading]="
      appService.initialized() &&
      (loaderService.toggleMainLoader() || (routeLoading$ | async))
    "
    class="content"
  >
    <router-outlet></router-outlet>
  </div>
  <rdv-footer *ngIf="appService.initialized()"></rdv-footer>
</div>
<ngx-loading-bar
  color="#00bfa5"
  [includeSpinner]="false"
  height="5px"
></ngx-loading-bar>
