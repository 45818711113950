import { Dialog } from "@angular/cdk/dialog";
import { Injectable } from "@angular/core";
import { SanDialogService } from "@san/tools/ui/dialog";
import { filter, map, take } from "rxjs";
import { CommentDialogComponent } from "../components/comment-dialog/comment-dialog.component";
import { CommentParam } from "../models/interfaces/comment.interface";
import { ObjectUtility } from './../utils/object.utils';

@Injectable({
  providedIn: 'root'
})
export class DialogService extends SanDialogService {

  constructor(public override dialog: Dialog) {
    super(dialog);
  }

  openCommentDialog(param?: CommentParam) {
    return this.open(CommentDialogComponent, {
      disableClose: true,
      hasBackdrop: true,
      data: {
        content: param
      }
    }).closed.pipe(take(1),
      filter(res => res?.content !== false && !ObjectUtility.isNullOrUndefined(res?.content)),
      map(data => data.content as string)
    );
  }
}
