{
  "name": "santyrdv",
  "version": "1.4.2",
  "scripts": {
    "ng": "ng",
    "cls": "rimraf node_modules package-lock.json dist .angular",
    "deps": "npm i --legacy-peer-deps",
    "cls:deps": "npm run cls && npm i --legacy-peer-deps",
    "licences": "npx license-checker --json --production --out src/assets/json/licenses.json",
    "local:tools": "rimraf .angular && rimraf node_modules/@san/tools/* && ncp ../san-tools/dist/lib/. node_modules/@san/tools/",
    "start": "npm run trads && ng serve",
    "start:ssr": "npm run build:demo && node dist/santyrdv/server/server.mjs",
    "start:local": "npm run local:tools && npm run trads && ng serve",
    "start:live": "npm i @san/tools@0.0.0-live --no-save --legacy-peer-deps && npm run trads && ng serve",
    "start:latest": "npm i @san/tools@latest --no-save && npm run trads && ng serve",
    "trads": "node src/scripts/merge.trads.ts",
    "build:production": "ng build --configuration=production",
    "build:demo": "ng build --configuration=demo",
    "lint": "ng lint",
    "serve:ssr:santyrdv": "node dist/santyrdv/server/server.mjs"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^19.1.5",
    "@angular/cdk": "19.1.3",
    "@angular/common": "^19.1.5",
    "@angular/compiler": "^19.1.5",
    "@angular/core": "^19.1.5",
    "@angular/forms": "^19.1.5",
    "@angular/material": "19.1.3",
    "@angular/platform-browser": "^19.1.5",
    "@angular/platform-browser-dynamic": "^19.1.5",
    "@angular/platform-server": "^19.1.5",
    "@angular/router": "^19.1.5",
    "@angular/service-worker": "^19.1.5",
    "@angular/ssr": "^19.1.6",
    "@fortawesome/fontawesome-free": "^6.5.1",
    "@ngbracket/ngx-layout": "^19.0.0",
    "@ngx-loading-bar/core": "^7.0.0",
    "@ngx-loading-bar/http-client": "^7.0.0",
    "@ngx-translate/core": "16.0.4",
    "@ngx-translate/http-loader": "16.0.1",
    "@san/tools": "^0.0.0-live",
    "@swimlane/ngx-charts": "^22.0.0-alpha.0",
    "buffer": "6.0.3",
    "d3": "7.9.0",
    "d3-array": "3.2.4",
    "d3-brush": "3.0.0",
    "d3-color": "3.1.0",
    "d3-force": "3.0.0",
    "d3-format": "3.1.0",
    "d3-hierarchy": "3.1.2",
    "d3-interpolate": "3.0.1",
    "d3-scale": "4.0.2",
    "d3-selection": "3.0.0",
    "d3-shape": "3.2.0",
    "d3-time-format": "4.1.0",
    "dompurify": "^3.2.4",
    "express": "^4.18.2",
    "file-saver": "^2.0.5",
    "jspdf": "^1.4.0",
    "jspdf-autotable": "^3.0.4",
    "jwt-decode": "^4.0.0",
    "leaflet": "^1.9.4",
    "leaflet-cascade-buttons": "^1.0.10",
    "leaflet.fullscreen": "^3.0.2",
    "lodash-es": "^4.17.21",
    "luxon": "3.5.0",
    "ngx-quill": "27.0.0",
    "ngx-skeleton-loader": "^9.0.0",
    "ngx-toastr": "19.0.0",
    "ngx-turnstile": "0.2.4",
    "quill": "2.0.3",
    "rxjs": "^7.8.1",
    "tslib": "^2.6.2",
    "validator": "13.11.0",
    "zone.js": "^0.15.0"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^19.1.6",
    "@angular-eslint/builder": "19.0.2",
    "@angular-eslint/eslint-plugin": "19.0.2",
    "@angular-eslint/eslint-plugin-template": "19.0.2",
    "@angular-eslint/schematics": "19.0.2",
    "@angular-eslint/template-parser": "19.0.2",
    "@angular/cli": "^19.1.6",
    "@angular/compiler-cli": "^19.1.5",
    "@types/d3-scale": "^4.0.8",
    "@types/d3-selection": "^3.0.10",
    "@types/d3-shape": "^3.1.6",
    "@types/express": "^4.17.17",
    "@types/file-saver": "^2.0.7",
    "@types/jasmine": "^5.1.4",
    "@types/jspdf": "^1.3.3",
    "@types/leaflet": "^1.9.12",
    "@types/leaflet.fullscreen": "^3.0.2",
    "@types/lodash-es": "^4.17.12",
    "@types/luxon": "3.4.2",
    "@types/node": "^18.18.0",
    "@types/uuid": "10.0.0",
    "@types/validator": "13.11.0",
    "@typescript-eslint/eslint-plugin": "8.18.0",
    "@typescript-eslint/parser": "8.18.0",
    "eslint": "9.17.0",
    "jasmine-core": "5.5.0",
    "karma": "6.4.4",
    "karma-chrome-launcher": "3.2.0",
    "karma-coverage": "2.2.1",
    "karma-jasmine": "5.1.0",
    "karma-jasmine-html-reporter": "2.1.0",
    "license-checker": "^25.0.1",
    "ncp": "2.0.0",
    "rimraf": "^5.0.5",
    "typescript": "5.6.3"
  },
  "browser": {
    "crypto": false,
    "stream": false
  }
}
