import { Component, Injector, OnInit, inject } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { IconName } from '@san/tools/models';
import { BaseDialogComponent } from '@san/tools/ui/dialog';
import { filter } from 'rxjs';
import { ReactiveService } from 'src/app/shared/services/reactive.service';
import { Utilisateur } from './../../../shared/models/entity/Utilisateur';
import { UserService } from './../../../shared/services/user.service';

@Component({
  selector: 'rdv-notification-settings',
  templateUrl: './notification-settings.component.html',
  styleUrls: ['./notification-settings.component.scss']
})
export class NotificationSettingsComponent extends BaseDialogComponent implements OnInit {

  private userService = inject(UserService);
  private reactiveService = inject(ReactiveService);

  IconName: typeof IconName = IconName;
  notifForm: FormGroup;
  user = this.userService.connected;

  constructor(protected override injector: Injector) {
    super(injector);
  }

  ngOnInit(): void {
    this.notifForm = new FormGroup({
      notif_app: new FormControl(this.user?.notif_app),
      notif_sms: new FormControl(this.user?.notif_sms)
    });

    if (this.isEntityManager) {
      this.notifForm.addControl('notif_rdv', new FormControl((this.user as Utilisateur)?.notif_rdv));
    }
  }

  get isEntityManager() {
    return this.userService.isEntityManager;
  }

  validate() {
    const request = this.notifForm.getRawValue();
    this.reactiveService.call(this.userService.updateNotificationSettings(this.userService.connectedUserType, { ...request, id: this.user.id })
      .pipe(filter(res => res.data === true)),
      () => {
        this.user.notif_app = request.notif_app;
        this.user.notif_sms = request.notif_sms;
        if (this.isEntityManager) {
          (this.user as Utilisateur).notif_rdv = request.notif_rdv;
        }
        this.userService.setConnectedUser(this.user);
        this.close({ content: true });
      }, true);
  }
}
