import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponent } from './core/components/page-not-found/page-not-found.component';
import { CanActivateAccountRoute } from './core/guards/account-access.guard';
import { CanActivatePatientRoute } from './core/guards/patient-access.guard';
import { CanActivateProRoute } from './core/guards/pro-access.guard';
import { BaseMenu } from './shared/models/enum/user-menu.enum';

const routes: Routes = [
  { path: '', redirectTo: BaseMenu.HOME, pathMatch: 'full' },
  { path: BaseMenu.HOME, loadChildren: () => import('./home/home.module').then(m => m.HomeModule) },
  {
    path: BaseMenu.ACCOUNT,
    canActivate: [CanActivateAccountRoute],
    loadChildren: () => import('./account/account.module').then(m => m.AccountModule)
  },
  {
    path: BaseMenu.PATIENT,
    canActivate: [CanActivatePatientRoute],
    loadChildren: () => import('./patient/patient.module').then(m => m.PatientModule)
  },
  {
    path: BaseMenu.PRO,
    canActivate: [CanActivateProRoute],
    loadChildren: () => import('./pro/pro.module').then(m => m.ProModule)
  },
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
