<div class="header" fxLayout="row" fxLayoutAlign="start center">
  <div fxShow="true" fxHide.gt-sm="true">
    <san-icon id="icon-side-menu" title="{{'core.header.open-menu' | translate }}" [name]="IconName.MENU_BAR"
      (onclick)="toggleMenu()"></san-icon>
  </div>

  <div>
    <img class="pointer" alt="Logo" src="./assets/images/logo-blanc.png" style="display: flex; height: 45px;" />
  </div>

  <span class="app-spacer"></span>

  <ng-container *ngIf="!userService.isConnected()">

    <div fxLayout="row" fxLayoutAlign="start center" fxShow="true" fxHide.lt-md="true">
      <rdv-top-menu [menu]="menu"></rdv-top-menu>
    </div>

    <div fxLayout="row" fxLayoutAlign="start center" fxShow="true" fxHide.lt-md="true">
      <div title="{{'core.header.are-you-medecin' | translate}}" (click)="login()"
        class="space-link text-warn font-bold pointer">
        {{'menu.espace-pro' | translate}}</div>
    </div>

    <div fxLayout="row" fxLayoutAlign="start center" fxShow="true" fxHide.gt-sm="true">
      <san-icon (onclick)="login(AccountType.PATIENT)" [title]="'core.header.are-you-patient' | translate" size="2xl"
        [name]="IconName.USER" classes="text-white">
      </san-icon>
      <san-icon (onclick)="login()" [title]="'core.header.are-you-medecin' | translate" size="2xl"
        [name]="IconName.DOCTOR" classes="text-warn">
      </san-icon>
    </div>
  </ng-container>

  <div *ngIf="userService.isConnected()" fxLayout="row" fxLayoutAlign="start center">

    <san-icon routerLink="/" [title]="'core.header.go-home' | translate" size="2xl" [name]="IconName.HOME"
      classes="text-white">
    </san-icon>

    <san-icon [routerLink]="userService.dashboardLink()" [title]="'menu.synthese' | translate" size="2xl"
      [name]="IconName.DISPLAY" classes="text-white">
    </san-icon>

    <san-icon [title]="'menu.identifiant-securite' | translate" [name]="IconName.USER" classes="tWhite" size="2xl"
      [cdkMenuTriggerFor]="profMenu.mainMenu">
    </san-icon>

    <san-menu #profMenu [menuItems]="profileMenu"></san-menu>
  </div>
</div>
<rdv-side-menu *ngIf="showMenu()" (closeMenu)="toggleMenu(false)"></rdv-side-menu>
