<san-bloc header="rdv.filter.titre" [retractable]="true" [icon]="IconName.SEARCH">
  <div content>
    <form [formGroup]="searchForm">
      <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap.gt-xs="5px">
        <san-input fxFlex.lt-sm="100%" idControl="filter-patient-numero" label="core.user.numero"
          placeholder="core.user.numero" formControlName="numero"></san-input>
        <san-input fxFlex.lt-sm="100%" idControl="filter-patient-nom_complet" label="core.user.full-name"
          placeholder="core.user.full-name" formControlName="nom_complet"></san-input>
        <san-date fxFlex.lt-sm="100%" idControl="filter-patient-naissance" [dateOnly]="true" [maxDate]="today"
          label="core.user.date_naissance" formControlName="date_naissance"></san-date>
      </div>
    </form>
  </div>
</san-bloc>