export enum SimpleEntity {
  MOYEN = 'moyen',
  SPECIALITE = 'specialite',
  VILLE = 'ville',
  JOUR = 'jour',
  COUVERTURE = 'couverture',
  MOTIF = 'motif'
}

export enum SearchPeriod {
  WEEK = 'WEEK',
  MONTH = 'MONTH',
  TRIMESTER = 'TRIMESTER',
  SEMESTER = 'SEMESTER',
  ANNUAL = 'ANNUAL',
  CUSTOM = 'CUSTOM'
}
