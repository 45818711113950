
export enum Sexe {
  MASCULIN = 'M',
  FEMININ = 'F'
}

export enum AccountType {
  PRO = 'pro',
  PATIENT = 'patient'
}
