import { Component, Injector } from '@angular/core';
import { IconName, OptionModel } from '@san/tools/models';
import { BaseDialogComponent } from '@san/tools/ui/dialog';

@Component({
  selector: 'rdv-check-list-modal',
  templateUrl: './check-list-modal.component.html',
  styleUrls: ['./check-list-modal.component.scss']
})
export class CheckListModalComponent extends BaseDialogComponent {

  IconName = IconName;
  items: OptionModel<unknown>[];

  constructor(protected override injector: Injector) {
    super(injector);
  }

  handleSelected(options: OptionModel<unknown>[]) {
    this.items = options;
  }

  validate() {
    this.close({ content: this.items?.map(it => it.data) });
  }
}
