<san-bloc header="rdv.filter.titre" [retractable]="true" [icon]="IconName.SEARCH">
  <div content>
    <form [formGroup]="searchForm">
      <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap.gt-xs="5px">

        <san-select *ngIf="isPro" fxFlex.lt-sm="100%" idControl="filter-events-type" label="rdv.filter.type"
          placeholder="rdv.filter.type-pl" [required]="true" [options]="types" formControlName="type"
          [manageObject]="false"></san-select>

        <san-date fxFlex.lt-sm="100%" idControl="filter-events-debut" [dateOnly]="false" label="rdv.filter.debut"
          formControlName="debut"></san-date>

        <san-date fxFlex.lt-sm="100%" idControl="filter-events-fin" [dateOnly]="false" label="rdv.filter.fin"
          formControlName="fin"></san-date>

        <san-multi-select fxFlex.lt-sm="100%" idControl="filter-events-etats" label="rdv.filter.etat"
          placeholder="rdv.filter.etat-pl" [options]="status" formControlName="status" [maxLabels]="3"
          [manageObject]="false"></san-multi-select>

        <ng-container *ngIf="isPro">
          <san-multi-select fxFlex.lt-sm="100%" [class.hidden]="!isRdv" idControl="filter-events-prestations"
            label="rdv.filter.prestation" placeholder="rdv.filter.prestation-pl" [options]="prestations$ | async"
            [maxLabels]="3" [manageObject]="false" formControlName="prestations"></san-multi-select>

          <san-input fxFlex.lt-sm="100%" idControl="filter-events-term" label="rdv.filter.filtre"
            [placeholder]="termPlaceholder" formControlName="term"></san-input>
        </ng-container>
      </div>

    </form>
  </div>
</san-bloc>