export const DefaultImage = './assets/images/default.png';

export enum FileDestination {
  PATIENT = 'patient',
  USER = 'user',
  DIPLOME = 'diplome',
  RDV = 'rdv'
}

export interface FileDataResponse {
  ressourceId: string;
  name?: string;
}

export interface FileResponse {
  files: FileDataResponse[];
}

export interface FileUploadRequest {
  uuid: string;
  destination: FileDestination;
  name?: string;
  label?: string;
}
