import { APP_INITIALIZER, enableProdMode, importProvidersFrom, isDevMode } from '@angular/core';

import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withFetch, withInterceptorsFromDi } from '@angular/common/http';
import { MAT_RIPPLE_GLOBAL_OPTIONS } from '@angular/material/core';
import { bootstrapApplication, BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule, provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ToastrModule } from 'ngx-toastr';
import { appRoutes } from './app/app-routes';
import { AppComponent } from './app/app.component';
import { DateInterceptor } from './app/core/interceptors/date.interceptor';
import { ErrorInterceptor } from './app/core/interceptors/error.interceptor';
import { UrlInterceptor } from './app/core/interceptors/url.interceptor';
import { PubliqueService } from './app/shared/api/publique.service';
import { AppconfService } from './app/shared/services/app-conf.service';
import { ApplicationService } from './app/shared/services/application.service';
import { TraductorService } from './app/shared/services/traductor.service';
import { UserService } from './app/shared/services/user.service';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

bootstrapApplication(AppComponent, {
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: ApplicationService.initialize,
      deps: [AppconfService, PubliqueService, UserService, TraductorService],
      multi: true
    },
    { provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: { disabled: true } },
    { provide: HTTP_INTERCEPTORS, useClass: UrlInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: DateInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    provideRouter(appRoutes),
    provideAnimations(),
    provideHttpClient(
      withInterceptorsFromDi(),
      withFetch()
    ),
    importProvidersFrom(
      ServiceWorkerModule.register('ngsw-worker.js', {
        enabled: !isDevMode(),
        registrationStrategy: 'registerWhenStable:30000'
      }),
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: createTranslateLoader,
          deps: [HttpClient]
        }
      }),
      ToastrModule.forRoot({
        preventDuplicates: true,
        autoDismiss: true,
        closeButton: true,
        enableHtml: true,
        positionClass: 'toast-bottom-right'
      }),
      BrowserModule,
      BrowserAnimationsModule
    )
  ]
});
