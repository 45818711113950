import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DateService } from '@shared/services/date.service';
import { InterceptorException } from './../enum/core.enum';

@Injectable({ providedIn: 'root' })
export class DateInterceptor implements HttpInterceptor {
  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(req).pipe(
      tap((resp) => {
        if (resp instanceof HttpResponse && resp.body) {
          const tab: string[] = req.url.split('?');
          if (tab.length > 0) {
            const urlWithoutParameters = tab[0];
            if (
              !InterceptorException.some((it) =>
                urlWithoutParameters.endsWith(it)
              )
            ) {
              DateService.recursiveForEach(resp.body);
            }
          }
        }
      })
    );
  }
}
