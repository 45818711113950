import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { LoaderService } from '@app/shared/services/loader.service';
import { ApiResponse } from '@shared/models/dto/response.dto';
import { StatusCode } from '@shared/models/dto/status-code.model';
import { NotificationService } from '@shared/services/notifaction.service';
import { SsrService } from '@shared/services/ssr.service';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import {
  DOWNLOAD_REQUEST_CONTEXT,
  InterceptorException,
} from './../enum/core.enum';
import { TraductorService } from '@app/shared/services/traductor.service';

@Injectable({ providedIn: 'root' })
export class ErrorInterceptor implements HttpInterceptor {
  private readonly notificationService = inject(NotificationService);
  private readonly ssrService = inject(SsrService);
  private readonly loaderService = inject(LoaderService);
  private readonly traductorService = inject(TraductorService);

  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const tab: string[] = req.url.split('?');
    if (tab.length > 0 && this.ssrService.isBrowser()) {
      const urlWithoutParameters = tab[0];
      if (
        !InterceptorException.some((it) => urlWithoutParameters.endsWith(it))
      ) {
        return next.handle(req).pipe(
          tap((resp) => {
            if (
              resp instanceof HttpResponse &&
              !this.excludedContexts(req) &&
              (resp?.body as ApiResponse<unknown>)?.status !== StatusCode.OK
            ) {
              const rep = resp?.body as ApiResponse<unknown>;
              const sms =
                rep?.status === StatusCode.CUSTOM_ERROR ? rep.message : null;
              this.notificationService.handleResponse(
                (resp?.body as ApiResponse<unknown>)?.status,
                sms,
                false
              );
            }
          }),
          catchError((error) => {
            this.notificationService.error('core.error.500');
            this.updateLoading();
            this.loaderService.removeLoaders();
            return throwError(() => new Error(error));
          })
        );
      }
    }
    return next.handle(req);
  }

  updateLoading() {
    const loader = document.getElementById('splash-loader');
    const message = document.getElementById('splash-text');
    const error = document.getElementById('splash-error');
    const retry = document.getElementById('retry-btn');
    if (loader) {
      loader.style.display = 'none';
    }
    if (message) {
      message.style.display = 'none';
    }
    if (error) {
      error.innerHTML = this.traductorService.translate('core.error.500');
      error.style.display = 'block';
    }
    if (retry) {
      retry.style.display = 'block';
    }
  }

  private excludedContexts(req: HttpRequest<unknown>): boolean {
    return req.context.get(DOWNLOAD_REQUEST_CONTEXT) === true;
  }
}
