<div *ngIf="user" fxLayout="row wrap" fxLayoutAlign="start start" fxLayoutGap="16px">
  <div>
    <div class="info small-padding">
      @if(isPro(user)){
      <div><label>{{'account.droit' | translate}}</label> : <span>{{user.droit | displayDroit}}</span></div>
      <div>
        <label>{{'shared.medecin' | translate}}</label> :
        <span>{{!!user.medecin?.id | displayBool}}
          <rdv-state-icon [state]="!!user.medecin?.id"></rdv-state-icon>
        </span>
      </div>
      }
      <div>
        <label>{{'shared.actif' | translate}}</label> :
        <span>{{user.actif | displayBool}}
          <rdv-state-icon [state]="user.actif"></rdv-state-icon>
        </span>
      </div>
      <div>
        <label>{{'shared.verifie' | translate}}</label> :
        <span>{{user.verifie | displayBool}}
          <rdv-state-icon [state]="user.verifie"></rdv-state-icon>
        </span>
      </div>
      <div>
        <label>{{'core.user.notif-app' | translate}}</label> :
        <span>{{user.notif_app | displayBool}}
          <rdv-state-icon [state]="user.notif_app"></rdv-state-icon>
        </span>
      </div>
      <div>
        <label>{{'core.user.notif-sms' | translate}}</label> :
        <span>{{user.notif_sms | displayBool}}
          <rdv-state-icon [state]="user.notif_sms"></rdv-state-icon>
        </span>
      </div>
      <div *ngIf="isPro(user) && isEntityManager">
        <label>{{'core.user.notif-rdv' | translate}}</label> :
        <span>{{user.notif_rdv | displayBool}}
          <rdv-state-icon [state]="user.notif_rdv"></rdv-state-icon>
        </span>
      </div>
      <div><label>{{'account.creation' | translate}}</label> : <span> {{user.creation | displayDate:true}}</span></div>
    </div>
  </div>
</div>
