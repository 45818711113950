import { Routes } from '@angular/router';
import { PageNotFoundComponent } from '@core/components/page-not-found/page-not-found.component';
import { CanActivateAccountRoute } from '@core/guards/account-access.guard';
import { CanActivatePatientRoute } from '@core/guards/patient-access.guard';
import { CanActivateProRoute } from '@core/guards/pro-access.guard';
import { BaseMenu } from '@shared/models/enum/user-menu.enum';

export const appRoutes: Routes = [
  { path: '', redirectTo: BaseMenu.HOME, pathMatch: 'full' },
  {
    path: BaseMenu.HOME,
    loadChildren: () => import('./home/home-routes').then((m) => m.homeRoutes),
  },
  {
    path: BaseMenu.ACCOUNT,
    canActivate: [CanActivateAccountRoute],
    loadChildren: () =>
      import('./account/account-routes').then((m) => m.accountRoutes),
  },
  {
    path: BaseMenu.PATIENT,
    canActivate: [CanActivatePatientRoute],
    loadChildren: () =>
      import('./patient/patient-routes').then((m) => m.patientRoutes),
  },
  {
    path: BaseMenu.PRO,
    canActivate: [CanActivateProRoute],
    loadChildren: () => import('./pro/pro-routes').then((m) => m.proRoutes),
  },
  { path: '**', component: PageNotFoundComponent },
];
