<div class="reservation-bloc">
  <san-bloc *ngIf="pro?.id || etablissement?.id" [header]="header | uppercase">
    <div content fxLayout="row wrap" fxLayoutAlign="start start" fxLayoutGap="16px">
      <rdv-user-resume *ngIf="isPro" [pro]="pro.utilisateur"></rdv-user-resume>
      <rdv-hospital *ngIf="!isPro" [etablissement]="etablissement" [readOnly]="true"></rdv-hospital>
      <div class="middle" fxLayout="column" fxLayoutAlign="start start">
        <rdv-available-periods [etalissmentOrProId]="id" [isPro]="isPro"
          (clickOnTime)="openBooking($event)"></rdv-available-periods>
      </div>
    </div>
  </san-bloc>
</div>
