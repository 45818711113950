import { Component, Input } from '@angular/core';
import { IconName, IconSize } from '@san/tools/models';

@Component({
  selector: 'rdv-state-icon',
  templateUrl: './state-icon.component.html',
  styleUrls: ['./state-icon.component.scss']
})
export class StateIconComponent {
  @Input() state: boolean;
  @Input() size: IconSize = 'lg';
  @Input() validIcon: IconName = IconName.CHECK_CIRCLE;
  @Input() unValidIcon: IconName = IconName.CLOSE_CIRCLE;
  @Input() unValidMessage: string;

  get stateIcon() {
    return this.state ? this.validIcon : this.unValidIcon;
  }

  get stateColor() {
    return this.state ? 'primary' : 'warn';
  }

  get verificationMessage() {
    return this.state ? '' : (this.unValidMessage ?? '');
  }
}
