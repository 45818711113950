export enum Etat {
  ACTIF = 1,
  INACTIF = 0,
  TOUT = -1
}

export enum Action {
  SUPPRIMER = 0,
  AJOUTER = 1,
  REMPLACER = 2
}
