import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { SanMenuModule } from '@san/tools/ui/menu';
import { SanSlideToggleModule } from '@san/tools/ui/slide-toggle';
import { SanTabsModule } from '@san/tools/ui/tabs';
import { SharedModule } from './../shared/shared.module';
import { AboutComponent } from './components/about/about.component';
import { ChangeEmailComponent } from './components/change-email/change-email.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { NotificationSettingsComponent } from './components/notification-settings/notification-settings.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { SideMenuComponent } from './components/side-menu/side-menu.component';
import { TopMenuComponent } from './components/top-menu/top-menu.component';
import { DateInterceptor } from './interceptors/date.interceptor';
import { ErrorInterceptor } from './interceptors/error.interceptor';
import { UrlInterceptor } from './interceptors/url.interceptor';

@NgModule({
  declarations: [
    HeaderComponent,
    PageNotFoundComponent,
    AboutComponent,
    TopMenuComponent,
    SideMenuComponent,
    FooterComponent,
    ChangePasswordComponent,
    ChangeEmailComponent,
    NotificationSettingsComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    SanTabsModule,
    SanMenuModule,
    SanSlideToggleModule,
    TranslateModule.forChild()
  ],
  exports: [
    SharedModule,
    HeaderComponent,
    FooterComponent,
    PageNotFoundComponent
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: UrlInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: DateInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }
  ]
})
export class CoreModule { }
