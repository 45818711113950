import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';
import { IconName } from '@san/tools/models';
import { SanIconComponent } from '@san/tools/ui/icon';
import { AppConf } from '../../../shared/models/interfaces/app-conf.interface';
import { AppconfService } from '../../../shared/services/app-conf.service';
import { AboutComponent } from '../about/about.component';
import { DialogService } from './../../../shared/services/dialog.service';

@Component({
  selector: 'rdv-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    SanIconComponent,
    TranslateModule,
    FlexLayoutModule
  ]
})
export class FooterComponent {

  private readonly dialogService = inject(DialogService);

  conf: AppConf = inject(AppconfService).getAppConf();
  IconName: typeof IconName = IconName;


  openAboutDialog() {
    this.dialogService.open(AboutComponent, { width: '700px' });
  }
}
