import { Component } from '@angular/core';
import { IconName } from '@san/tools/models';

@Component({
  selector: 'rdv-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent {
  IconName: typeof IconName = IconName;
}
