{
  "metas": [
    { "name": "author", "content": "Santynelle" },
    { "name": "robots", "content": "index, follow" },
    { "name": "googlebot", "content": "notranslate" },
    { "name": "googlebot-news", "content": "nosnippet" },
    { "name": "twitter:card", "content": "summary" },
    {
      "name": "description",
      "content": "Santynelle est une solution complète pour la gestion de vos Rendez-vous médicaux chez les professionnels de santé"
    },
    {
      "name": "keywords",
      "content": "Santé, Rendez-vous, Hopital, Soins, Soigner, Injection, Perfusion, Ordonnance, Médicament, CHU, Médecin, Patient, RDV, Médical, Infirmier, Sanitaire, Prise de rendez-vous, Abidjan, Côte d'Ivoire, Bilan de santé"
    },
    {
      "name": "og:title",
      "property": "og:title",
      "content": "Santynelle RDV"
    },
    {
      "name": "og:type",
      "property": "og:type",
      "content": "website"
    },
    {
      "name": "og:url",
      "property": "og:url",
      "content": "https://www.santynelle.com"
    },
    {
      "name": "og:description",
      "property": "og:description",
      "content": "Santynelle est une solution complète pour la gestion de vos Rendez-vous médicaux chez les professionnels de santé"
    },
    {
      "name": "og:image:height",
      "property": "og:image:height",
      "content": "600"
    },
    {
      "name": "og:image:width",
      "property": "og:image:width",
      "content": "1200"
    }
  ]
}
