import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';
import { IconName } from '@san/tools/models';
import { SanButtonComponent } from '@san/tools/ui/button';
import { BaseDialogComponent } from '@san/tools/ui/dialog';
import { SanModalComponent } from '@san/tools/ui/modal';
import { SanTabComponent, SanTabsComponent } from '@san/tools/ui/tabs';

@Component({
  selector: 'rdv-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    SanModalComponent,
    SanTabComponent,
    SanTabsComponent,
    SanButtonComponent,
    TranslateModule,
    FlexLayoutModule
  ]
})
export class AboutComponent extends BaseDialogComponent {

  IconName: typeof IconName = IconName;
}
