import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { SearchParam } from '@san/tools/models';
import { BehaviorSubject, first, Observable, tap } from 'rxjs';
import { ApiResponse } from '../models/dto/response.dto';
import { Utilisateur } from '../models/entity/Utilisateur';
import { Commune } from '../models/entity/commune';
import { Etablissement } from '../models/entity/etablissement';
import { LibelleEntity } from '../models/entity/libelle.entity';
import { Medecin } from '../models/entity/medecin';
import { Patient } from '../models/entity/patient';
import { Prestation } from '../models/entity/prestation';
import { Ville } from '../models/entity/ville';
import { Etat } from '../models/enum/state.enum';
import { AppEnvironnement, GlobalSearchTerm, SearchTerm, SearchTermType } from '../models/interfaces/app-conf.interface';
import { AppointmentPeriod } from '../models/interfaces/rdv.interface';
import { EmailRequest, LoginRequest, LoginToken, PasswordRequest, VerificationData } from "../models/interfaces/user.interfaces";
import { PeriodRequest } from '../models/request/rdv.request';
import { SimpleEntity } from './../models/enum/simple.enum';
import { ApiBaseService } from './api-base.service';

@Injectable({
  providedIn: 'root'
})
export class PubliqueService extends ApiBaseService {

  static USER_PATH: string = 'user';
  static MEDECIN_PATH: string = 'medecin';
  static PATIENT_PATH: string = 'patient';
  static COMMON_PATH: string = 'common';
  static RDV_PATH: string = 'rdv';
  envData: BehaviorSubject<AppEnvironnement> = new BehaviorSubject<AppEnvironnement>(null);
  turnstileStatus: BehaviorSubject<Etat> = new BehaviorSubject<Etat>(Etat.TOUT);

  constructor(override http: HttpClient) {
    super(http, 'publique');
  }

  /**
   * Create an etablissement
   * @param request
   * @returns
   */
  createEtablissement(request: { etablissement: Partial<Etablissement>, user: Partial<Utilisateur> }): Observable<ApiResponse<boolean>> {
    return this.http.post<ApiResponse<boolean>>(`${this.baseUrl}/${PubliqueService.USER_PATH}/create-etablissement`, request);
  }

  /**
   * Login Pro
   * @param request
   * @returns
   */
  connectPro(request: LoginRequest): Observable<ApiResponse<LoginToken>> {
    return this.http.post<ApiResponse<LoginToken>>(`${this.baseUrl}/${PubliqueService.USER_PATH}/login`, request);
  }

  /**
   * Change pro password
   * @param request
   * @returns
   */
  changeProPassword(request: PasswordRequest): Observable<ApiResponse<boolean>> {
    return this.http.post<ApiResponse<boolean>>(`${this.baseUrl}/${PubliqueService.USER_PATH}/change-password`, request);
  }

  verifyProEmail(request: VerificationData): Observable<ApiResponse<boolean>> {
    return this.http.post<ApiResponse<boolean>>(`${this.baseUrl}/${PubliqueService.USER_PATH}/verify-email`, request);
  }

  /**
   * Recover Pro password
   * @param request
   * @returns
   */
  recoverProPassword(request: EmailRequest): Observable<ApiResponse<boolean>> {
    return this.http.post<ApiResponse<boolean>>(`${this.baseUrl}/${PubliqueService.USER_PATH}/forget-password`, request);
  }

  /**
   * Create a patient
   * @param request
   * @returns
   */
  registerPatient(request: Partial<Patient>): Observable<ApiResponse<boolean>> {
    return this.http.post<ApiResponse<boolean>>(`${this.baseUrl}/${PubliqueService.PATIENT_PATH}/register`, request);
  }

  /**
   * Login patient
   * @param request
   * @returns
   */
  connectPatient(request: LoginRequest): Observable<ApiResponse<LoginToken>> {
    return this.http.post<ApiResponse<LoginToken>>(`${this.baseUrl}/${PubliqueService.PATIENT_PATH}/login`, request);
  }

  /**
   * Change patient password
   * @param request
   * @returns
   */
  changePatientPassword(request: PasswordRequest): Observable<ApiResponse<boolean>> {
    return this.http.post<ApiResponse<boolean>>(`${this.baseUrl}/${PubliqueService.PATIENT_PATH}/change-password`, request);
  }

  verifyPatientEmail(request: VerificationData): Observable<ApiResponse<boolean>> {
    return this.http.post<ApiResponse<boolean>>(`${this.baseUrl}/${PubliqueService.PATIENT_PATH}/verify-email`, request);
  }

  /**
   * Recover patient password
   * @param request
   * @returns
   */
  recoverPatientPassword(request: EmailRequest): Observable<ApiResponse<boolean>> {
    return this.http.post<ApiResponse<boolean>>(`${this.baseUrl}/${PubliqueService.PATIENT_PATH}/forget-password`, request);
  }


  /**
   * get a common entity
   * @param repo
   * @param id
   * @param relations
   * @returns
   */
  commonFind<T>(repo: SimpleEntity, id: string, relations?: string[]): Observable<ApiResponse<T>> {
    const query = `?repo=${repo}`;
    return this.http.post<ApiResponse<T>>(`${this.baseUrl}/${PubliqueService.COMMON_PATH}/find${query}`, { id, relations });
  }

  /**
   * Paginate entities
   * @param repo
   * @param param
   * @returns
   */
  commonPaginate<T>(repo: SimpleEntity, param?: SearchParam): Observable<ApiResponse<T[]>> {
    const query = `?repo=${repo}`;
    return this.http.post<ApiResponse<T[]>>(`${this.baseUrl}/${PubliqueService.COMMON_PATH}/list/paginate${query}`, param);
  }

  /**
   * Search entities
   * @param repo
   * @param param
   * @returns
   */
  commonSearch<T>(repo: SimpleEntity, param?: SearchParam): Observable<ApiResponse<T[]>> {
    const query = `?repo=${repo}`;
    return this.http.post<ApiResponse<T[]>>(`${this.baseUrl}/${PubliqueService.COMMON_PATH}/list/full${query}`, param);
  }

  /**
   * Load app env data
   * @returns
   */
  appEnv(): Observable<ApiResponse<AppEnvironnement>> {
    return this.http.get<ApiResponse<AppEnvironnement>>(`${this.baseUrl}/${PubliqueService.COMMON_PATH}/app/environnement`);
  }

  searchMedecins(searchTerm: GlobalSearchTerm) {
    return this.http.post<ApiResponse<Medecin[]>>(`${this.baseUrl}/${PubliqueService.RDV_PATH}/search/medecins`, searchTerm);
  }

  searchEtablissements(searchTerm: GlobalSearchTerm) {
    return this.http.post<ApiResponse<Etablissement[]>>(`${this.baseUrl}/${PubliqueService.RDV_PATH}/search/etablissements`, searchTerm);
  }

  findMedecin(id: string) {
    return this.http.post<ApiResponse<Utilisateur>>(`${this.baseUrl}/${PubliqueService.RDV_PATH}/medecin/find/${id}`, null);
  }

  availableMedecinRdvPeriods(param: PeriodRequest) {
    return this.http.post<ApiResponse<AppointmentPeriod[]>>(`${this.baseUrl}/${PubliqueService.RDV_PATH}/get/available-medecin-rdv-periods`, param);
  }

  availableEtablissmentRdvPeriods(param: PeriodRequest) {
    return this.http.post<ApiResponse<AppointmentPeriod[]>>(`${this.baseUrl}/${PubliqueService.RDV_PATH}/get/available-etablissement-rdv-periods`, param);
  }

  getPrestations(etablissmentId: string) {
    return this.http.post<ApiResponse<Prestation[]>>(`${this.baseUrl}/${PubliqueService.RDV_PATH}/get/prestations/${etablissmentId}`, null);
  }

  availableMedecins(param: SearchParam) {
    return this.http.post<ApiResponse<Utilisateur[]>>(`${this.baseUrl}/${PubliqueService.MEDECIN_PATH}/get/available-medecins`, param);
  }

  checkTurnstile(token: string): Observable<ApiResponse<boolean>> {
    return this.http.post<ApiResponse<boolean>>(`${this.baseUrl}/${PubliqueService.COMMON_PATH}/turnstile`, { token })
      .pipe(first(), tap(res => this.turnstileStatus.next(res?.data ? Etat.ACTIF : Etat.INACTIF)));
  }

  get citiesOptions() {
    const env = this.envData.getValue();
    return [
      ...PubliqueService.createSearchOptions(env?.communes, 'commune'),
      ...PubliqueService.createSearchOptions(env?.villes, 'ville')
    ];
  }

  get termOptions() {
    const env = this.envData.getValue();
    return [
      ...PubliqueService.createSearchOptions(env?.specialites, 'specialite'),
      ...PubliqueService.createSearchOptions(env?.prestations, 'prestation'),
      ...PubliqueService.createSearchOptions(env?.etablissements, 'etablissement')
    ];
  }

  getVille(commune: Commune, ville: Ville) {
    if (commune) {
      return this.citiesOptions.find(c => c.id === commune.id && c.type === 'commune');
    } else if (ville) {
      return this.citiesOptions.find(c => c.id === ville.id && c.type === 'ville');
    }
    return null;
  }

  public static createSearchOptions(data: LibelleEntity[], type: SearchTermType): SearchTerm[] {
    return (data ?? []).map((d: LibelleEntity) => ({ ...d, type, data: d } as SearchTerm));
  }

  public static cityFromOption(option: SearchTerm) {
    if (option.type === 'ville') {
      return { ville: option.data, commune: null as Commune };
    } else {
      return { ville: (option.data as Commune).ville, commune: option.data };
    }
  }

}
