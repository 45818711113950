import { DialogModule } from '@angular/cdk/dialog';
import { CdkMenuModule } from '@angular/cdk/menu';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { SanSharedModule } from '@san/tools/shared';
import { SanAutocompleteModule } from '@san/tools/ui/autocomplete';
import { SanBlocModule } from '@san/tools/ui/bloc';
import { SanButtonModule } from '@san/tools/ui/button';
import { SanButtonIconModule } from '@san/tools/ui/button-icon';
import { SanButtonToggleModule } from '@san/tools/ui/button-toggle';
import { SanCheckListModule } from '@san/tools/ui/check-list';
import { SanDateModule } from '@san/tools/ui/date';
import { SanFormModule } from '@san/tools/ui/forms';
import { SanIconModule } from '@san/tools/ui/icon';
import { SanInformationModule } from '@san/tools/ui/information';
import { SanInputModule } from '@san/tools/ui/input';
import { SanListViewModule } from '@san/tools/ui/list-view';
import { SanMenuModule } from '@san/tools/ui/menu';
import { SanModalModule } from '@san/tools/ui/modal';
import { SanSelectModule } from '@san/tools/ui/select';
import { AppointmentComponent } from './components/appointment/appointment.component';
import { AvailablePeriodsComponent } from './components/available-periods/available-periods.component';
import { CheckListModalComponent } from './components/check-list-modal/check-list-modal.component';
import { CommentDialogComponent } from './components/comment-dialog/comment-dialog.component';
import { FilterEventComponent } from './components/filters/rdv-filter/rdv-filter.component';
import { StatsFilterComponent } from './components/filters/stats-filter/stats-filter.component';
import { UserFilterComponent } from './components/filters/user-filter/user-filter.component';
import { HospitalComponent } from './components/hospital/hospital.component';
import { MapComponent } from './components/map/map.component';
import { MenuBarComponent } from './components/menu-bar/menu-bar.component';
import { PatientDetailComponent } from './components/patient/patient-detail/patient-detail.component';
import { PatientInfoComponent } from './components/patient/patient-info/patient-info.component';
import { PhotoComponent } from './components/photo/photo.component';
import { RdvDetailComponent } from './components/rdv-detail/rdv-detail.component';
import { RdvDialogComponent } from './components/rdv-dialog/rdv-dialog.component';
import { RdvMedecinDialogComponent } from './components/rdv-medecin-dialog/rdv-medecin-dialog.component';
import { RdvPreviewBlocComponent } from './components/rdv-preview-bloc/rdv-preview-bloc.component';
import { ReservationBlocComponent } from './components/reservation-bloc/reservation-bloc.component';
import { StateIconComponent } from './components/state-icon/state-icon.component';
import { SyntheseEventsBlocComponent } from './components/synthese-events-bloc/synthese-events-bloc.component';
import { UserAccountComponent } from './components/user-account/user-account.component';
import { MedecinDiplomeDialogComponent } from './components/user/medecin-diplome/medecin-diplome-dialog/medecin-diplome-dialog.component';
import { MedecinDiplomeComponent } from './components/user/medecin-diplome/medecin-diplome.component';
import { UserProfileComponent } from './components/user/profile/user-profile.component';
import { UserInfoComponent } from './components/user/user-info/user-info.component';
import { UserResumeComponent } from './components/user/user-resume/user-resume.component';
import { ImageDirective } from './directives/image.directive';
import { FileSizePipe, ImageFilePipe, PreviewFilePipe } from './pipes/file.pipe';
import { DisplayBoolPipe, DisplayDatePipe, DisplayDroitPipe, DisplayHourPipe, DisplayListPipe, DisplayPricePipe, DisplayValuePipe, DureeRdvPipe, LongDatePipe } from './pipes/object.pipe';
import { RdvStatusPipe } from './pipes/rdv.pipe';
import { FullNamePipe, GenrePipe, MedecinIsConnectedPipe, PatientIsConnectedPipe, UserIsConnectedPipe } from './pipes/user.pipe';

const sanModules = [
  SanSharedModule,
  SanFormModule,
  SanModalModule,
  SanBlocModule,
  SanMenuModule,
  SanIconModule,
  SanInputModule,
  SanButtonIconModule,
  SanButtonModule,
  SanListViewModule,
  SanInformationModule
];

const exportables = [
  MenuBarComponent,
  AppointmentComponent,
  UserInfoComponent,
  UserResumeComponent,
  UserAccountComponent,
  MedecinDiplomeComponent,
  MedecinDiplomeDialogComponent,
  CheckListModalComponent,
  ReservationBlocComponent,
  AvailablePeriodsComponent,
  UserProfileComponent,
  HospitalComponent,
  RdvDialogComponent,
  RdvMedecinDialogComponent,
  RdvDetailComponent,
  FilterEventComponent,
  CommentDialogComponent,
  MedecinIsConnectedPipe,
  PatientIsConnectedPipe,
  UserIsConnectedPipe,
  ImageFilePipe,
  PreviewFilePipe,
  FileSizePipe,
  DisplayValuePipe,
  DisplayDatePipe,
  DisplayDroitPipe,
  DisplayPricePipe,
  DisplayListPipe,
  GenrePipe,
  DisplayHourPipe,
  LongDatePipe,
  FullNamePipe,
  UserFilterComponent,
  PatientInfoComponent,
  PhotoComponent,
  RdvStatusPipe,
  SyntheseEventsBlocComponent,
  RdvPreviewBlocComponent,
  ImageDirective,
  DisplayBoolPipe,
  DureeRdvPipe,
  PatientDetailComponent,
  StateIconComponent,
  MapComponent,
  StatsFilterComponent
];

@NgModule({
  declarations: [
    ...exportables
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    PortalModule,
    DialogModule,
    OverlayModule,
    CdkMenuModule,
    SanSelectModule,
    SanButtonToggleModule,
    SanCheckListModule,
    SanAutocompleteModule,
    SanDateModule,
    TranslateModule.forChild(),
    ...sanModules
  ],
  exports: [
    // Modules
    FormsModule,
    ReactiveFormsModule,
    PortalModule,
    DialogModule,
    RouterModule,
    OverlayModule,
    CdkMenuModule,
    ...sanModules,
    ...exportables
  ],
  providers: []
})
export class SharedModule {
}
