import { Component, EventEmitter, OnInit, Output, inject } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { IconName, OptionModel, Page, SearchCondition, SearchParam, SortDirection } from '@san/tools/models';
import { BaseComponent, FilterUtils } from '@san/tools/utils';
import { cloneDeep, isEqual } from 'lodash';
import { debounceTime, map, takeUntil, tap } from 'rxjs';
import { EtablissementService } from './../../../api/etablissement.service';
import { SearchPeriod } from './../../../models/enum/simple.enum';
import { StatsRequest } from './../../../models/request/simple.request';
import { DateService } from './../../../services/date.service';
import { UserService } from './../../../services/user.service';

@Component({
  selector: 'rdv-filter-stats',
  templateUrl: './stats-filter.component.html',
  styleUrls: ['./stats-filter.component.scss']
})
export class StatsFilterComponent extends BaseComponent implements OnInit {

  private etablissementService = inject(EtablissementService);
  private userService = inject(UserService);

  isPro = this.userService.proIsConnected;
  IconName = IconName;
  filterForm: FormGroup;
  request: StatsRequest = {};
  periods: OptionModel<SearchPeriod>[] = Object.values(SearchPeriod).map(p => ({ value: p, label: `shared.search-period.${p.toLowerCase()}` }));
  noEvents = { onlySelf: true, emitEvent: false };
  @Output() searching: EventEmitter<StatsRequest> = new EventEmitter<StatsRequest>();

  ngOnInit(): void {
    this.filterForm = new FormGroup({
      period: new FormControl(),
      debut: new FormControl(),
      fin: new FormControl(),
      medecin: new FormControl(),
      patient: new FormControl()
    });

    this.periodControl.valueChanges.pipe(takeUntil(this.destroy), tap(period => {
      if (period === SearchPeriod.CUSTOM) {
        this.debutControl.enable(this.noEvents);
        this.finControl.enable(this.noEvents);
      } else {
        this.debutControl.disable(this.noEvents);
        this.finControl.disable(this.noEvents);
        const { debut, fin } = this.updatePeriod(period);
        this.debutControl.patchValue(debut, this.noEvents);
        this.finControl.patchValue(fin, this.noEvents);
      }
    })).subscribe();

    this.filterForm.valueChanges.pipe(debounceTime(1000), takeUntil(this.destroy)).subscribe(() => this.search());
    this.periodControl.patchValue(SearchPeriod.MONTH);
  }

  get periodControl() {
    return this.filterForm.get('period');
  }

  get debutControl() {
    return this.filterForm.get('debut');
  }

  get finControl() {
    return this.filterForm.get('fin');
  }

  filterPatients = (term: string, limit?: number) => {
    return this.etablissementService.getPatients({ criteria: { nom_complet: term }, page: Page.build({ currentPage: 1, pageSize: limit }) })
      .pipe(debounceTime(500), map(resp => resp.data.map(p => ({
        value: p.id,
        label: UserService.getFullName(p),
        data: p
      }))));
  }

  filterMedecins = (term: string, limit?: number) => {
    const option = FilterUtils.generateFilter(SearchCondition.OR);
    const utils = new FilterUtils();
    option.filters.push(utils.stringFilter('utilisateur.nom').contains(term).get());
    option.filters.push(utils.stringFilter('utilisateur.prenoms').contains(term).get());
    const param: SearchParam = {
      filter: option,
      page: Page.build({ currentPage: 1, pageSize: limit }),
      relations: ['utilisateur.etablissement'],
      orders: [{ field: 'utilisateur.nom', direction: SortDirection.ASC },
      { field: 'utilisateur.prenoms', direction: SortDirection.ASC }]
    };

    return this.etablissementService.getMedecins(param).pipe(debounceTime(500), map(resp => resp.data.map(m => ({
      value: m.id,
      label: UserService.getFullName(m.utilisateur),
      data: m
    }))));
  }

  updatePeriod(period: SearchPeriod) {
    let p = null;
    let debut = null;
    let fin = null;
    switch (period) {
      case SearchPeriod.WEEK:
        debut = DateService.firstDateOfWeek();
        fin = DateService.lastDateOfWeek();
        break;
      case SearchPeriod.MONTH:
        debut = DateService.firstDayOfMonth();
        fin = DateService.lastDayOfMonth();
        break;
      case SearchPeriod.TRIMESTER:
        p = DateService.currentTrimesterPeriod();
        debut = p.debut;
        fin = p.fin;
        break;
      case SearchPeriod.SEMESTER:
        p = DateService.currentSemesterPeriod();
        debut = p.debut;
        fin = p.fin;
        break;
      case SearchPeriod.ANNUAL:
        debut = DateService.firstDateOfYear();
        fin = DateService.lastDateOfYear();
        break;
      default: // CUSTOM
        break;
    }
    return { debut, fin };
  }

  buildRequest() {
    return {
      debut: DateService.trunk(this.debutControl.value),
      fin: DateService.trunk(this.finControl.value),
      medecinId: this.filterForm.controls['medecin'].value ?? null,
      patientId: this.filterForm.controls['patient'].value ?? null
    } as StatsRequest;
  }

  search() {
    const param = this.buildRequest();
    if (!isEqual(this.request, param)) {
      this.request = cloneDeep(param);
      this.searching.next(this.request);
    }
  }

}
