export enum CommentType {
  REQUIRED = 1,
  OPTIONAL = 2,
  HIDE = 3
}

export interface CommentParam {
  titleKey?: string;
  message?: string;
  labelKey?: string;
  type?: CommentType;
  maxlength?: number;
  validationLabelKey?: string;
}
