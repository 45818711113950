import { CdkMenuModule } from '@angular/cdk/menu';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Input,
} from '@angular/core';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { TranslatePipe } from '@ngx-translate/core';
import { MenuItem } from '@san/tools/models';
import { SanMenuComponent } from '@san/tools/ui/menu';
import { NavigationService } from '@shared/services/navigation.service';

@Component({
  selector: 'rdv-top-menu',
  templateUrl: './top-menu.component.html',
  styleUrls: ['./top-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    SanMenuComponent,
    CdkMenuModule,
    TranslatePipe,
    FlexLayoutModule,
  ],
})
export class TopMenuComponent {
  private readonly navigationService = inject(NavigationService);

  @Input() menu: MenuItem[] = [];

  protected clickOnMenu(m: MenuItem) {
    m.expanded = m.items && !m.expanded;
    if (m?.click) {
      m.click();
    }
    if (m?.link) {
      this.navigationService.navigate(`/${m.link}`);
    }
  }
}
