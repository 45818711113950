import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, inject } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, RouterOutlet } from '@angular/router';
import { SanLoaderDirective } from '@san/tools/shared';
import { Observable, debounceTime, filter, fromEvent, map } from 'rxjs';
import { FooterComponent } from './core/components/footer/footer.component';
import { HeaderComponent } from './core/components/header/header.component';
import { ApplicationService } from './shared/services/application.service';
import { LoaderService } from './shared/services/loader.service';

@Component({
  selector: 'rdv-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [
    HeaderComponent,
    CommonModule,
    RouterOutlet,
    FooterComponent,
    SanLoaderDirective
  ]
})
export class AppComponent implements AfterViewInit {

  private appService = inject(ApplicationService);
  public loaderService = inject(LoaderService);
  private router = inject(Router);

  routeLoading$: Observable<boolean>;

  constructor() {
    this.routeLoading$ = this.router.events.pipe(
      filter(routeEvents => [
        NavigationStart,
        NavigationEnd,
        NavigationError,
        NavigationCancel
      ].some(construct => routeEvents instanceof construct)),
      map((events) => events instanceof NavigationStart));
  }

  ngAfterViewInit(): void {
    // Ecoute les changements de taille de fenêtre
    fromEvent(window, 'resize').pipe(debounceTime(500)).subscribe(evt => this.appService.resizingWindow.next(evt));
  }
}
