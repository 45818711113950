import { Component, Input } from '@angular/core';
import { Utilisateur } from './../../../models/entity/Utilisateur';

@Component({
  selector: 'rdv-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.scss']
})
export class UserInfoComponent {

  @Input() user: Utilisateur;
  @Input() editable: boolean = false;

}
