import { inject, Injectable } from '@angular/core';
import { UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { NavigationService } from '../../shared/services/navigation.service';
import { UserService } from '../../shared/services/user.service';

@Injectable({
  providedIn: 'root'
})
export class CanActivateAccountRoute {

  private readonly navigationService = inject(NavigationService);
  private readonly userService = inject(UserService);

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.userService.isConnected) {
      this.navigationService.navigate(this.userService.dashboardLink);
      return false;
    }
    return true;
  }
}
