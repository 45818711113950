export enum UserType {
  PATIENT = 0,
  PRO = 1
}

export enum UserDroit {
  ADMIN = 'ADM',
  ENTITY_MANAGER = 'ETM',
  USER_MANAGER = 'USM'
}
