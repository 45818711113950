<div *ngIf="etablissement" fxLayout="row wrap" fxLayoutAlign="start start" fxLayoutGap="16px">
  <div class="info small-padding">
    <div><label>{{'etablissement.edit-etablissement.nom' | translate}}</label> :
      <span>{{etablissement.libelle | displayValue}}</span>
    </div>
    <div><label>{{'etablissement.edit-etablissement.email' | translate}}</label> :
      <span>{{etablissement.email | displayValue}}</span>
    </div>
    <div><label>{{'etablissement.edit-etablissement.telephone' | translate}}</label> :
      <span>{{etablissement.telephone | displayValue}}</span>
    </div>
    <div><label>{{'account.city' | translate}}</label> :
      <span>{{etablissement.ville?.libelle | displayValue}}</span>
    </div>
    <div><label>{{'account.commune' | translate}}</label> :
      <span>{{etablissement.commune?.libelle | displayValue}}</span>
    </div>
    <div><label>{{'etablissement.edit-etablissement.adresse' | translate}}</label> :
      <span>{{etablissement.adresse | displayValue}}</span>
    </div>
    <div><label>{{'shared.actif' | translate}}</label> : <span>{{etablissement.actif | displayBool}}
        <rdv-state-icon [state]="etablissement.actif"></rdv-state-icon>
      </span></div>

    <div><label>{{'shared.verifie' | translate}}</label> : <span>{{etablissement.verifie | displayBool}}
        <rdv-state-icon [state]="etablissement.verifie"
          unValidMessage="etablissement.message-verification"></rdv-state-icon>
      </span></div>
    <div><label>{{'shared.creation' | translate}}</label> : <span>{{etablissement.creation | displayDate}}</span></div>

    @if(!readOnly){
    <div fxLayout="row wrap" *ngIf="!etablissement.actif || !etablissement.verifie">
      <san-button *ngIf="!etablissement.actif" [borderless]="true" color="accent"
        label="etablissement.demander-activation"></san-button>
      <san-button *ngIf="!etablissement.verifie" [borderless]="true" color="accent"
        label="etablissement.demander-verification"></san-button>
    </div>
    }
  </div>
</div>
