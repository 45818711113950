<div *ngIf="menu.length" fxLayout="row" fxLayoutAlign="start center">
  <ng-container *ngFor="let m of menu;">
    <ng-container *ngIf="!!m.visible">
      <ng-container *ngIf="!m.items?.length else withItems">
        <div class="top-menu" (click)="clickOnMenu(m)">
          {{m.label | translate}}
        </div>
      </ng-container>
      <ng-template #withItems>
        <div class="top-menu" [cdkMenuTriggerFor]="menu.mainMenu">
          {{m.label | translate}}
        </div>
        <san-menu #menu [menuItems]="m.items" (triggerMenu)="clickOnMenu($event)"></san-menu>
      </ng-template>
    </ng-container>
  </ng-container>
</div>
