import { Component, Injector, OnInit, inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IconName, OptionModel, Page } from '@san/tools/models';
import { BaseDialogComponent } from '@san/tools/ui/dialog';
import { Observable, debounceTime, first, map, takeUntil } from 'rxjs';
import { EtablissementService } from '../../api/etablissement.service';
import { RdvService } from '../../api/rdv.service';
import { Utilisateur } from '../../models/entity/Utilisateur';
import { Patient } from '../../models/entity/patient';
import { Rdv } from '../../models/entity/rdv';
import { RdvType, StatusRdv } from '../../models/enum/rdv.enum';
import { ReactiveService } from '../../services/reactive.service';
import { DateService } from './../../services/date.service';
import { UserService } from './../../services/user.service';

@Component({
  selector: 'rdv-dialog',
  templateUrl: './rdv-dialog.component.html',
  styleUrls: ['./rdv-dialog.component.scss']
})
export class RdvDialogComponent extends BaseDialogComponent implements OnInit {

  private etablissementService = inject(EtablissementService);
  private rdvService = inject(RdvService);
  private userService = inject(UserService);
  private reactiveService = inject(ReactiveService);

  IconName = IconName;
  rdv: Rdv;
  date: Date;
  rdvForm: FormGroup;
  types: OptionModel<RdvType>[] = Object.values(RdvType).map(value => ({ value, label: `rdv.${value}-type` }));
  prestations$: Observable<OptionModel<string>[]>;
  user: Utilisateur = this.userService.connectedPro;
  medecinUser: Utilisateur;
  patient: Patient;

  constructor(protected override injector: Injector) {
    super(injector);
    this.rdv = this.data?.content?.rdv;
    this.date = this.data?.content?.date;
    this.patient = this.data?.content?.patient;
    this.medecinUser = this.data?.content?.medecinUser;

    this.prestations$ = this.etablissementService.getPrestations()
      .pipe(first(), map(resp => resp.data.map(p => ({
        value: p.id.toString(),
        label: p.libelle,
        data: p
      }))));
  }

  ngOnInit(): void {
    this.rdvForm = new FormGroup({
      type: new FormControl(this.types[0].value),
      patient: new FormControl({ value: this.getSelectedPatient(), disabled: !!this.rdv?.id }, Validators.required),
      prestation: new FormControl(this.getSelectedPrestation(), Validators.required),
      motif: new FormControl({ value: this.rdv?.motif, disabled: true }),
      debut: new FormControl(this.rdv?.debut ?? this.date, Validators.required),
      fin: new FormControl(this.rdv?.fin ?? this.date),
      lieu: new FormControl(this.rdv?.lieu),
      details: new FormControl(this.rdv?.details)
    });

    this.typeControl.valueChanges.pipe(takeUntil(this.destroy), debounceTime(200)).subscribe(type => {
      if (type === RdvType.RDV) {
        this.patientControl.addValidators(Validators.required);
        this.prestationControl.addValidators(Validators.required);
        this.patientControl.enable();
        this.prestationControl.enable();
        this.motifControl.clearValidators();
        this.motifControl.disable();
        this.finControl.clearValidators();
        this.finControl.disable();
      } else {
        this.patientControl.clearValidators();
        this.prestationControl.clearValidators();
        this.patientControl.disable();
        this.prestationControl.disable();
        this.motifControl.addValidators(Validators.required);
        this.motifControl.enable();
        this.finControl.addValidators(Validators.required);
        this.finControl.enable();
      }
    });

    if (this.rdv && !(this.rdv.patient || this.patient)) {
      this.typeControl.patchValue(this.types[1].value);
      this.motifControl.enable();
      this.motifControl.addValidators(Validators.required);
    }

    this.medecinUser = this.getRdvMedecinUser();
  }

  filterPatients = (term: string, limit?: number) => {
    return this.etablissementService.getPatients({ criteria: { nom_complet: term }, page: Page.build({ currentPage: 1, pageSize: limit }) })
      .pipe(debounceTime(500), map(resp => resp.data.map(p => ({
        value: p.id,
        label: UserService.getFullName(p),
        data: p
      }))));
  }

  getSelectedPatient() {
    const patient = this.rdv?.patient ?? this.patient;
    return patient ? {
      value: patient.id,
      label: UserService.getFullName(patient),
      data: patient
    } : null;
  }

  getSelectedPrestation() {
    return this.rdv?.prestation ? {
      value: this.rdv.prestation.id,
      label: this.rdv.prestation?.libelle ?? this.rdv?.motif,
      data: this.rdv.prestation
    } : null;
  }

  get rdvMedecin() {
    if (this.rdv?.medecin) {
      return this.rdv.medecin;
    } else if (this.medecinUser) {
      return this.medecinUser.medecin;
    } else if (this.userService?.medecinIsConnected && !this.rdv?.id) {
      return this.userService.connectedPro.medecin;
    }
    return null;
  }

  getRdvMedecinUser() {
    if (this.rdv?.medecin) {
      return this.rdv.medecin.utilisateur;
    } else if (this.medecinUser) {
      return this.medecinUser;
    } else if (this.userService?.medecinIsConnected && !this.rdv?.id) {
      return this.userService.connectedPro;
    }
    return null;
  }

  get typeControl() {
    return this.rdvForm.get('type');
  }

  get patientControl() {
    return this.rdvForm.get('patient');
  }

  get prestationControl() {
    return this.rdvForm.get('prestation');
  }

  get motifControl() {
    return this.rdvForm.get('motif');
  }

  get finControl() {
    return this.rdvForm.get('fin');
  }

  get isRdv() {
    return this.typeControl.value === RdvType.RDV || !!this.rdv?.patient?.id;
  }

  save() {
    if (this.rdvForm.valid) {
      const data = this.rdvForm.getRawValue();
      const debut = DateService.trunkHours(data.debut);
      const fin = this.isRdv ? DateService.addMinToDate(debut, this.user?.medecin?.duree_rdv ?? 30) : DateService.trunkHours(data.fin);
      const request: Rdv = {
        id: this.rdv?.id ?? null,
        status: this.rdv?.status ?? StatusRdv.ACCEPTED,
        debut,
        fin,
        lieu: this.isRdv ? this.user?.etablissement?.libelle : data.lieu,
        prestation: this.isRdv ? data.prestation?.data : null,
        motif: this.isRdv ? data.prestation?.label : data.motif,
        tarif: this.isRdv ? data.prestation?.data?.tarif : null,
        patient: this.isRdv ? (data.patient?.value ?? this.rdv?.patient?.id) : null,
        details: data.details,
        medecin: this.rdvMedecin
      };
      this.reactiveService.call(this.rdvService.save(this.typeControl.value, request), resp => this.close({ content: resp.data }), true);
    }
  }
}
