import { inject, Injectable } from "@angular/core";
import { IndividualConfig, ToastrService } from "ngx-toastr";
import { StatusCode } from "../models/dto/status-code.model";
import { TraductorService } from './traductor.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  private toastService = inject(ToastrService);
  private traductor = inject(TraductorService);

  info(message: string, title?: string, config?: Partial<IndividualConfig<unknown>>) {
    this.toastService.info(this.traductor.translate(message), title, config);
  }

  success(message: string, title?: string, config?: Partial<IndividualConfig<unknown>>) {
    this.toastService.success(this.traductor.translate(message), title, config);
  }

  warning(message: string, title?: string, config?: Partial<IndividualConfig<unknown>>) {
    this.toastService.warning(this.traductor.translate(message), title, config);
  }

  error(message: string, title?: string, config?: Partial<IndividualConfig<unknown>>) {
    this.toastService.error(this.traductor.translate(message), title, config);
  }

  getResponseSMS(code: StatusCode, message?: string, showSuccess?: boolean): { sms: string, type: number } {
    let sms = message ?? null;
    let type = 0;
    switch (code) {
      case StatusCode.OK:
        sms = showSuccess ? null : '';
        break;
      case StatusCode.CUSTOM_ERROR:
        sms = message;
        type = 2;
        break;
      case StatusCode.DUPLICATED:
      case StatusCode.DUPLICATED_EVENT:
      case StatusCode.INVALID_EVENT_PERIOD:
      case StatusCode.VALIDATION_FAILED:
      case StatusCode.NEED_CHANGES:
      case StatusCode.FILE_TOO_LOURD:
      case StatusCode.PAYLOAD_TOO_LARGE:
      case StatusCode.TOO_MANY_FILES:
      case StatusCode.UNSUPPORTED_FILE_TYPE:
        type = 1;
        break;
      case StatusCode.NOT_FOUND:
      case StatusCode.NO_CONTENT:
      case StatusCode.UNAUTHORIZED:
      case StatusCode.BAD_REQUEST:
      case StatusCode.LOCKED:
      case StatusCode.FORBIDDEN:
      case StatusCode.FAILED:
      case StatusCode.NOT_VERIFIED:
      case StatusCode.DESACTIVATED:
      case StatusCode.BAD_FILES_REQUEST:
      case StatusCode.METHOD_NOT_ALLOWED:
      case StatusCode.SERVICE_UNAVAILABLE:
      case StatusCode.INTERNAL_SERVER_ERROR:
      default:
        type = 2;
        break;
    }
    sms = sms === null ? `core.error.${code}` : sms;
    return { sms, type };
  }

  handleResponse(code: StatusCode, message?: string, showSuccess?: boolean) {
    if (code === StatusCode.INVALID_TOKEN) {
      // Le cas du token invalide est géré par le refresh Token
      return;
    }
    const { sms, type } = this.getResponseSMS(code, message, showSuccess);
    if (sms?.length) {
      switch (type) {
        case 0:
          this.success(sms);
          break;
        case 1:
          this.warning(sms);
          break;
        case 2:
          this.error(sms);
          break;
        default:
          this.info(sms);
          break;
      }
    }
  }
}
