export enum BaseMenu {
  HOME = 'home',
  ACCOUNT = 'account',
  PRO = 'pro',
  PATIENT = 'patient',
  DOCTOR = 'doctors',
  RDV = 'rendez-vous',
}

export enum ProMenu {
  SYNTHESE = 'synthese',
  CALENDRIER = 'calendrier',
  RDV = 'rdv',
  PATIENTS = 'patients',
  PAYEMENTS = 'paiements',
  STATS = 'stats',
  PROFILE = 'profil',
  USERS = 'users',
  ETABLISSEMENT = 'etablissement',
  LOCALISATION = 'localisation',
  MEDECIN = 'parametres-medecin'
}

export enum PatientMenu {
  SYNTHESE = 'tdb',
  RDV = 'mes-rdv',
  PAYEMENTS = 'mes-paiements',
  PROFILE = 'mon-profil'
}
