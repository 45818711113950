<div *ngIf="patient" fxLayout="row wrap" fxLayoutAlign="start start" fxLayoutGap="16px">
  <div class="info small-padding">
    <div><label>{{'core.user.numero' | translate}}</label> : <span>{{patient.numero | displayValue}}</span></div>
    <div><label>{{'account.name' | translate}}</label> : <span>{{patient.nom | displayValue}}</span></div>
    <div><label>{{'account.firstname' | translate}}</label> : <span>{{patient.prenoms | displayValue}}</span></div>
    <div><label>{{'account.sexe' | translate}}</label> : <span>{{patient.sexe | genre}}</span></div>
    @if(editable){
    <div><label>{{'account.email' | translate}}</label> : <span>{{patient.email | displayValue}}</span></div>
    <div><label>{{'account.date-naissance' | translate}}</label> :
      <span>{{patient.date_naissance | displayDate}}</span>
    </div>
    <div><label>{{'account.lieu-naissance' | translate}}</label> :
      <span>{{patient.lieu_naissance | displayValue}}</span>
    </div>
    <div><label>{{'account.sanguin' | translate}}</label> : <span>{{patient.sanguin | displayValue}}</span></div>
    <div><label>{{'account.poids' | translate}}</label> : <span>{{patient.poids | displayValue}}</span></div>
    <div><label>{{'account.taille' | translate}}</label> : <span>{{patient.taille | displayValue}}</span></div>
    <div><label>{{'account.portable' | translate}}</label> : <span>{{patient.portable | displayValue}}</span></div>
    <div>
      <label>{{'account.telephone' | translate}}</label> : <span>{{patient.telephone | displayValue}}</span>
    </div>
    }
    <div><label>{{'account.city' | translate}}</label> : <span>{{patient.ville?.libelle | displayValue}}</span></div>
    <div><label>{{'account.commune' | translate}}</label> : <span>{{patient.commune?.libelle | displayValue}}</span>
    </div>
    <div *ngIf="editable"><label>{{'account.adresse' | translate}}</label> :
      <span>{{patient.adresse | displayValue}}</span>
    </div>
  </div>
</div>