import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  inject,
  Output,
} from '@angular/core';
import { NavigationStart } from '@angular/router';
import { filter } from 'rxjs';
import { MenuBarComponent } from '@shared/components/menu-bar/menu-bar.component';
import { ApplicationService } from '@shared/services/application.service';
import { NavigationService } from '@shared/services/navigation.service';

@Component({
  selector: 'rdv-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MenuBarComponent],
})
export class SideMenuComponent {
  private readonly appService = inject(ApplicationService);
  private readonly navigationService = inject(NavigationService);

  @Output() closeMenu: EventEmitter<void> = new EventEmitter<void>();

  constructor(private readonly elt: ElementRef) {
    this.appService.resizingWindow
      .asObservable()
      .subscribe(() => this.closeMenu.next());

    this.navigationService.currentRouter.events
      .pipe(filter((event) => event instanceof NavigationStart))
      .subscribe(() => this.closeMenu.next());
  }

  @HostListener('document:click', ['$event'])
  protected clickout(event: Event) {
    const target = event.target;
    if (
      !this.elt.nativeElement.contains(target) &&
      (target as HTMLElement)?.id !== 'icon-side-menu'
    ) {
      this.closeMenu.next();
    }
  }
}
