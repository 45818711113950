import { HttpClient, HttpContext } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { map, take } from "rxjs";
import { ApplicationService } from '../services/application.service';
import { DOWNLOAD_REQUEST_CONTEXT } from './../../core/enum/core.enum';

@Injectable({
  providedIn: 'root'
})
export class ApiBaseService {

  protected baseUrl: string;
  protected http: HttpClient;
  private QUERY: string = '?';
  private AND: string = '&';

  constructor(@Inject(HttpClient) http: HttpClient, @Inject(String) url: string) {
    this.baseUrl = url;
    this.http = http;
  }

  private getDelimiter(query: string): string {
    return query.includes(this.QUERY) ? this.AND : this.QUERY;
  }

  protected addQueryParam(query: string = '', param: string, value: string | number | boolean): string {
    const del: string = this.getDelimiter(query);
    return `${query}${del}${param}=${value}`;
  }

  /**
  * Telecharge un fichier
  * @param url
  * @param name
  * @returns
  */
  download(url: string) {
    return this.http.post(`${this.baseUrl}/${url}`, null, {
      context: new HttpContext().set(DOWNLOAD_REQUEST_CONTEXT, true),
      responseType: 'blob'
    }).pipe(take(1), map(blob => window.URL.createObjectURL(blob)))
      .subscribe(url => ApplicationService.openBlobFile(url));
  }
}
