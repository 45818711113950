import { ObjectUtils } from "@san/tools/utils";
import { cloneDeep } from 'lodash';
import { Commune } from '../models/entity/commune';
import { Prestation } from '../models/entity/prestation';
import { Ville } from '../models/entity/ville';
import { DEVISE } from "../models/interfaces/app-conf.interface";

export class ObjectUtility extends ObjectUtils {

  static formatPrice(price?: number): string {
    price = price ?? 0;
    return `${price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} ${DEVISE}`;
  }

  static formatCity(ville?: Ville, commune?: Commune): string {
    let city = '';
    if (ville) {
      city = ville.libelle;
    }
    if (commune) {
      city = `${city}/${commune?.libelle}`;
    }
    return city;
  }

  static formatInfos(infos: string[], separator: string = ' - '): string {
    return infos?.filter(inf => inf?.length)?.join(separator);
  }

  static formatPrestation(prestation?: Prestation): string {
    return `${prestation.libelle} - ${ObjectUtility.formatPrice(prestation.tarif)}`;
  }

  static updateList(data: any[], item: any, key: string) {
    if (!data) {
      data = [];
    }
    if (item && key?.length) {
      const index = data.findIndex(d => d[key] === item[key]);
      if (index >= 0) {
        data[index] = item;
      } else {
        data.push(item);
      }
    }
    return cloneDeep(data);
  }
}
