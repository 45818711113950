<div class="footer" fxLayout="row" fxLayoutAlign="space-between center">
  &copy; {{ conf.appName }} - {{ version }}
  <div fxLayout="row" fxLayoutAlign="end center">
    <san-icon
      (onclick)="openAboutDialog()"
      classes="tWhite"
      [title]="'menu.support' | translate"
      [name]="IconName.QUESTION"
    >
    </san-icon>
  </div>
</div>
