import { CommonModule } from '@angular/common';
import {
  afterNextRender,
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  inject,
  isDevMode,
} from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { NavigationStart, Router, RouterOutlet } from '@angular/router';
import { FooterComponent } from '@core/components/footer/footer.component';
import { HeaderComponent } from '@core/components/header/header.component';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { NgxLoadingBar } from '@ngx-loading-bar/core';
import { SanLoaderDirective } from '@san/tools/shared';
import { BaseComponent } from '@san/tools/utils';
import { ApplicationService } from '@shared/services/application.service';
import { LoaderService } from '@shared/services/loader.service';
import { TraductorService } from '@shared/services/traductor.service';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { debounceTime, fromEvent, map, Observable, takeUntil } from 'rxjs';
import seo from '../assets/json/seo.json';

@Component({
  selector: 'rdv-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    HeaderComponent,
    CommonModule,
    RouterOutlet,
    FooterComponent,
    SanLoaderDirective,
    NgxLoadingBar,
    NgxSkeletonLoaderModule,
    FlexLayoutModule,
  ],
})
export class AppComponent extends BaseComponent implements AfterViewInit {
  public readonly appService = inject(ApplicationService);
  public readonly loaderService = inject(LoaderService);
  private readonly router = inject(Router);
  private readonly traductorService = inject(TraductorService);
  private readonly meta = inject(Meta);

  routeLoading$: Observable<boolean>;

  constructor() {
    super();

    if (!isDevMode) {
      this.meta.addTags(seo.metas);
    }

    afterNextRender(() => {
      this.traductorService.changeLanguage('fr');
      this.routeLoading$ = this.router.events.pipe(
        map((events) => events instanceof NavigationStart),
        takeUntil(this.destroy)
      );
    });
  }

  ngAfterViewInit(): void {
    // Ecoute les changements de taille de fenêtre
    fromEvent(window, 'resize')
      .pipe(debounceTime(500), takeUntil(this.destroy))
      .subscribe((evt) => this.appService.resizingWindow.next(evt));
    this.detectChanges();
  }
}
