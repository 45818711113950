import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  inject,
} from '@angular/core';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { FormErrors, IconName } from '@san/tools/models';
import { SanButtonIconComponent } from '@san/tools/ui/button-icon';
import { BaseDialogComponent } from '@san/tools/ui/dialog';
import { SanInputComponent } from '@san/tools/ui/input';
import { SanModalComponent } from '@san/tools/ui/modal';
import { filter, take } from 'rxjs';
import { confirmValidator } from 'src/app/shared/utils/custom.validators';
import { CryptoService } from '@shared/services/crypto.service';
import { UserService } from '@shared/services/user.service';

@Component({
  selector: 'rdv-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    SanModalComponent,
    ReactiveFormsModule,
    FormsModule,
    SanButtonIconComponent,
    SanInputComponent,
    FlexLayoutModule,
  ],
})
export class ChangePasswordComponent
  extends BaseDialogComponent
  implements OnInit
{
  private readonly userService = inject(UserService);

  protected readonly IconName: typeof IconName = IconName;
  protected passwordForm: FormGroup;
  protected readonly FormErrors = FormErrors;
  protected user = this.userService.connected();

  ngOnInit(): void {
    this.passwordForm = new FormGroup(
      {
        mdp: new FormControl(null, Validators.required),
        password: new FormControl(null, Validators.required),
        confPassword: new FormControl(null, Validators.required),
      },
      { validators: confirmValidator('password', 'confPassword') }
    );
  }

  protected validate() {
    const { mdp, password } = this.passwordForm.getRawValue();
    this.userService
      .updatePassword(this.userService.connectedUserType(), {
        oldPassword: CryptoService.toBase64(mdp),
        password: CryptoService.toBase64(password),
        id: this.user.id,
      })
      .pipe(
        take(1),
        filter((resp) => resp.data === true)
      )
      .subscribe(() => {
        this.userService.notificationService.success(
          'core.user.message-change-password-success'
        );
        this.close({ content: true });
      });
  }
}
