import { Component, Injector, OnInit, inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IconName } from '@san/tools/models';
import { BaseDialogComponent } from '@san/tools/ui/dialog';
import { ReactiveService } from 'src/app/shared/services/reactive.service';
import { DiplomeService } from './../../../../api/diplome.service';
import { Utilisateur } from './../../../../models/entity/Utilisateur';
import { Diplome } from './../../../../models/entity/diplome';
import { UserService } from './../../../../services/user.service';

@Component({
  selector: 'rdv-medecin-diplome-dialog',
  templateUrl: './medecin-diplome-dialog.component.html',
  styleUrls: ['./medecin-diplome-dialog.component.scss']
})
export class MedecinDiplomeDialogComponent extends BaseDialogComponent implements OnInit {

  private reactiveService = inject(ReactiveService);
  private userService = inject(UserService);
  private diplomeService = inject(DiplomeService);

  diplome: Diplome;
  user: Utilisateur = this.userService.connectedPro;
  diplomeForm: FormGroup;
  IconName = IconName;

  constructor(protected override injector: Injector) {
    super(injector);
  }

  ngOnInit(): void {

    this.diplome = this.data?.content;
    this.diplomeForm = new FormGroup({
      libelle: new FormControl(this.diplome?.libelle, Validators.required),
      etablissement: new FormControl(this.diplome?.etablissement, Validators.required),
      annee: new FormControl(this.diplome?.annee, Validators.required)
    });
  }

  save() {
    const data = this.diplomeForm.getRawValue();
    const request = { ...data, medecin: this.user.medecin, id: this.diplome?.id };
    this.reactiveService.call(this.diplomeService.save(request), (res) => {
      this.diplome = { ...this.diplome, ...data, id: res.data?.id };
      this.close({ content: this.diplome });
    }, true);
  }
}
