import { Component, Input } from '@angular/core';
import { IconName } from '@san/tools/models';
import { Patient } from './../../../models/entity/patient';

@Component({
  selector: 'rdv-patient-info',
  templateUrl: './patient-info.component.html',
  styleUrls: ['./patient-info.component.scss']
})
export class PatientInfoComponent {

  @Input() patient: Patient;
  @Input() editable: boolean = false;
  IconName = IconName;
}
