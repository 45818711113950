<san-modal header="core.user.notification-settings-title" (closeModal)="close()">
  <div content>
    <form [formGroup]="notifForm">
      <san-slide-toggle idControl="user_notif_app" label="core.user.notif-app" labelPosition="after"
        formControlName="notif_app"></san-slide-toggle>
      <san-slide-toggle idControl="user_notif_sms" label="core.user.notif-sms" labelPosition="after"
        formControlName="notif_sms"></san-slide-toggle>
      <san-slide-toggle *ngIf="isEntityManager" idControl="user_notif_rdv" label="core.user.notif-rdv"
        labelPosition="after" formControlName="notif_rdv"></san-slide-toggle>
    </form>
  </div>
  <div fxLayout="row" fxLayoutAlign="end center" footer>
    <san-button-icon color="warn" [icon]="IconName.CLOSE_CIRCLE" idControl="cancel-notification-settings"
      label="shared.annuler" (onclick)="close()"></san-button-icon>
    <san-button-icon [icon]="IconName.CHECK_CIRCLE" idControl="validate-notification-settings"
      [disabled]="notifForm.invalid" label="shared.valider" (onclick)="validate()"></san-button-icon>
  </div>
</san-modal>
