import { CdkMenuModule } from '@angular/cdk/menu';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  signal,
} from '@angular/core';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { RouterLink } from '@angular/router';
import { TranslatePipe } from '@ngx-translate/core';
import { IconName, MenuItem } from '@san/tools/models';
import { SanIconComponent } from '@san/tools/ui/icon';
import { SanMenuComponent } from '@san/tools/ui/menu';
import { delay, filter, take } from 'rxjs';
import { ChangeEmailComponent } from '../change-email/change-email.component';
import { ChangePasswordComponent } from '../change-password/change-password.component';
import { NotificationSettingsComponent } from '../notification-settings/notification-settings.component';
import { SideMenuComponent } from '../side-menu/side-menu.component';
import { TopMenuComponent } from '../top-menu/top-menu.component';
import { CommonService } from '@shared/api/common.service';
import { AccountType } from '@shared/models/enum/user-shared.enum';
import { DialogService } from '@shared/services/dialog.service';
import { LoaderService } from '@shared/services/loader.service';
import { MenuService } from '@shared/services/menu.service';
import { NavigationService } from '@shared/services/navigation.service';
import { UserService } from '@shared/services/user.service';

@Component({
  selector: 'rdv-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    SanIconComponent,
    TopMenuComponent,
    TranslatePipe,
    FlexLayoutModule,
    CdkMenuModule,
    RouterLink,
    SanMenuComponent,
    SideMenuComponent,
  ],
})
export class HeaderComponent {
  public readonly commonService = inject(CommonService);
  public readonly userService = inject(UserService);
  private readonly dialogService = inject(DialogService);
  private readonly loaderService = inject(LoaderService);
  private readonly navigationService = inject(NavigationService);

  protected readonly menu: MenuItem[] = inject(MenuService).menu();
  protected readonly IconName: typeof IconName = IconName;
  protected readonly AccountType: typeof AccountType = AccountType;
  protected showMenu = signal(false);

  protected readonly profileMenu: MenuItem[] = [
    { label: 'menu.profil.changerMail', click: () => this.openEmailDialog() },
    {
      label: 'menu.profil.changerPass',
      click: () => this.openPasswordDialog(),
    },
    {
      label: 'menu.profil.parametres-notif',
      click: () => this.openConfigDialog(),
    },
    { label: 'menu.profil.deconnexion', click: () => this.logout() },
  ];

  protected openPasswordDialog() {
    this.dialogService.open(ChangePasswordComponent, { disableClose: true });
  }

  protected openEmailDialog() {
    this.dialogService
      .open(ChangeEmailComponent, { disableClose: true })
      .closed.pipe(filter((res) => res?.content === true))
      .subscribe(() => this.logout());
  }

  protected openConfigDialog() {
    this.dialogService.open(NotificationSettingsComponent);
  }

  protected logout() {
    this.loaderService.toggleMainLoader.set(true);
    this.commonService
      .logout()
      .pipe(delay(1000), take(1))
      .subscribe(() => {
        this.userService.logout();
        this.loaderService.toggleMainLoader.set(false);
        this.navigationService.goHome();
      });
  }

  protected toggleMenu(state?: boolean) {
    if (state === undefined || state === null) {
      state = !this.showMenu();
    }
    this.showMenu.set(state);
  }

  protected login(type: AccountType = AccountType.PRO) {
    this.navigationService.navigate(
      `/account/${type}/login`,
      NavigationService.getUserRouteExtra(type)
    );
  }
}
