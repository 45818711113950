import { Component, Injector, OnInit, inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FormErrors, IconName } from '@san/tools/models';
import { BaseDialogComponent } from '@san/tools/ui/dialog';
import { filter, take } from 'rxjs';
import { confirmValidator } from 'src/app/shared/utils/custom.validators';
import { CryptoService } from './../../../shared/services/crypto.service';
import { UserService } from './../../../shared/services/user.service';

@Component({
  selector: 'rdv-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent extends BaseDialogComponent implements OnInit {

  private userService = inject(UserService);

  IconName: typeof IconName = IconName;
  passwordForm: FormGroup;
  FormErrors = FormErrors;
  user = this.userService.connected;

  constructor(protected override injector: Injector) {
    super(injector);
  }

  ngOnInit(): void {
    this.passwordForm = new FormGroup({
      mdp: new FormControl(null, Validators.required),
      password: new FormControl(null, Validators.required),
      confPassword: new FormControl(null, Validators.required)
    }, { validators: confirmValidator('password', 'confPassword') });
  }

  validate() {
    const { mdp, password } = this.passwordForm.getRawValue();
    this.userService.updatePassword(this.userService.connectedUserType, {
      oldPassword: CryptoService.toBase64(mdp),
      password: CryptoService.toBase64(password),
      id: this.user.id
    }).pipe(take(1), filter(resp => resp.data === true))
      .subscribe(() => {
        this.userService.notificationService.success('core.user.message-change-password-success');
        this.close({ content: true });
      });
  }
}
