import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { RouterLink } from '@angular/router';
import { TranslatePipe } from '@ngx-translate/core';
import { IconName } from '@san/tools/models';
import { SanButtonIconComponent } from '@san/tools/ui/button-icon';
import { SanIconComponent } from '@san/tools/ui/icon';

@Component({
  selector: 'rdv-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    SanButtonIconComponent,
    TranslatePipe,
    SanIconComponent,
    RouterLink,
    FlexLayoutModule,
  ],
})
export class PageNotFoundComponent {
  protected readonly IconName: typeof IconName = IconName;
}
